import { styled } from 'src/styled/Theme';

const TabContainer = styled.div`
  background-color: #fff;
  padding: 0 9%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 4px 0px rgb(72 72 72 / 4%);
  font-family: 'Poppins', sans-serif;
  font-weight: 400;

  .ant-tabs-nav {
    margin-bottom: 0;

    .ant-tabs-tab {
      margin-right: 16px;
      color: ${(props) => props.theme.colors.dark[3]};
      font-size: ${(props) => (props.fontSize ? props.fontSize : props.theme.fontSizes[18])}px;
      padding: 21px 15px;
      &-active {
        color: ${(props) => props.theme.colors.dark[3]};
        font-weight: 500;
      }
      &-tabpane {
        position: relative;
      }
    }
    .ant-tabs-ink-bar {
      height: 3px;
      background: ${(props) => props.theme.colors.normal[3]};
    }
  }
  .tab-space {
    &-noti {
      margin-right: 10px;
    }
  }
`;
const TabCategory = styled.div`
  .ant-tabs {
    &-bar {
      border: 0;
    }
    &-card {
      .ant-tabs-card-bar {
        .ant-tabs-tab {
          padding-top: 0;
          padding-bottom: 0;
          padding-left: 0;
          border: 0;
          background: transparent;
        }
      }
    }
  }
`;

export default { TabContainer, TabCategory };
