import { AxiosResponse } from 'axios';
import { appAxios } from '@src/api/appAxios';
import handleError from './handleError';
export const TopApi = {
  getTopGiven: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get(`posts/v2/top/point-given`);
      return data.code === 200 && data.data.users ? data.data.users : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      // throw error;
    }
  },
  getTopReceivers: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get(`posts/v2/top/point-receivers`);
      return data.code === 200 && data.data.users ? data.data.users : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      // throw error;
    }
  },
  getTopHashTag: async (page = 1, limit = 20) => {
    try {
      const { data }: AxiosResponse = await appAxios.get(
        `posts/v2/top/hashtag?page=${page}&limit=${limit}`
      );
      return data.code === 200 && data.data.tags ? data.data.tags : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      // throw error;
    }
  },
  getUsers: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get('v1/users?limit=1000');
      return data || {};
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      // throw error;
    }
  }
};
