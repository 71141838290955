import { InewsState, IrequestData } from 'src/interface/INewsState';
export const actionTypes = {
  REQUEST_DATA_LIST_NEWS: 'REQUEST_DATA_LIST_NEWS',
  SAVE_PREVIOUS_PATHNAME: 'SAVE_PREVIOUS_PATHNAME',
  GET_DATA_LIST_NEWS: 'GET_DATA_LIST_NEWS',
  GET_DATA_LIST_NEWS_NEXT_PAGE: 'GET_DATA_LIST_NEWS_NEXT_PAGE',
  GET_DATA_LIST_NEWS_SUCCESS: 'GET_DATA_LIST_NEWS_SUCCESS',
  GET_DATA_LIST_NEWS_FALL: 'GET_DATA_LIST_NEWS_FALL',
  REMOVE_ITEM_BOOK_MARK: 'REMOVE_ITEM_BOOK_MARK'
};

export const requestListNews = (requestData: IrequestData) => ({
  type: actionTypes.REQUEST_DATA_LIST_NEWS,
  requestData
});

export const getDataListNews = (data: InewsState) => ({
  type: actionTypes.GET_DATA_LIST_NEWS,
  data
});
export const getDataNextListNews = (data: InewsState) => ({
  type: actionTypes.GET_DATA_LIST_NEWS_NEXT_PAGE,
  data
});
export const removeItemBookMark = (idPost: number) => ({
  type: actionTypes.REMOVE_ITEM_BOOK_MARK,
  idPost
});
