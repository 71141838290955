import styled from 'styled-components';

interface Props {
  src: string | null;
  visible: boolean | null;
}

interface PropVisible {
  visible: boolean | null;
}

const RightBanner = styled.div<Props>`
  width: 150px;
  height: 150px;
  position: fixed;
  right: -100px;
  bottom: 40px;
  z-index: 8888;
  background-image: url('${(props: Props) => props.src}');
  background-size: cover;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  user-select: none;
  -webkit-user-select: none;
  &:hover {
    right: 0;
    transition: 0.2s;
    -webkit-transition: 0.2s;
  }
`;

const LeftBanner = styled.div<PropVisible>`
  width: 220px;
  height: 100%;
  position: fixed;
  left: -70px;
  top: 50px;
  z-index: 1;
  background-color: 'yellow';
  background-size: cover;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  @media (max-width: 992px) {
    left: -80px;
  }
  visibility: ${(props: PropVisible) => (props.visible ? 'visible' : 'hidden')};
  transition: 0.2s;
  -webkit-transition: 0.2s;
  &:hover {
    left: 0;
    transition: 0.2s;
    -webkit-transition: 0.2s;
  }
`;

const TopDecoration = styled.div<Props>`
  height: 30px;
  width: 100%;
  position: fixed;
  left: 0;
  z-index: 9999;
  background-size: contain;
  background-image: url('${(props: Props) => props.src}');
  background-repeat: repeat;
  text-align: center;
  visibility: ${(props: Props) => (props.visible ? 'visible' : 'hidden')};
`;

const TopLogo = styled.div<PropVisible>`
  display: inline-block;
  height: 80px;
  width: 120px;
  background-size: cover;
  visibility: ${(props: PropVisible) => (props.visible ? 'visible' : 'hidden')};
`;

export { RightBanner, LeftBanner, TopDecoration, TopLogo };
