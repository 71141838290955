import { FormItemState } from 'src/interface/IFormState';

export const actionTypes = {
  HEATMAP_ADD: 'HEATMAP_ADD',
  HEATMAP_EDIT_FORM: 'HEATMAP_EDIT_FORM',
  SET_FORM_INDEX: 'SET_FORM_INDEX',
  HEATMAP_CHANGE: 'HEATMAP_CHANGE',
  REMOVE_HEATMAP: 'REMOVE_HEATMAP',
  REMOVE_HEATMAP_SUCCESS: 'REMOVE_HEATMAP_SUCCESS',
  RESET_FORM: 'RESET_FORM',
  COPY_HEATMAP: 'COPY_HEATMAP',
  COPY_HEATMAP_SUCCESS: 'COPY_HEATMAP_SUCCESS',
  POST_HEATMAP: 'POST_HEATMAP',
  POST_HEATMAP_SUCCESS: 'POST_HEATMAP_SUCCESS',
  DATE_CHANGE: 'DATE_CHANGE',
  EDIT_HISTORY_HEATMAP: 'EDIT_HISTORY_HEATMAP',
  DELETE_HISTORY_HEATMAP: 'DELETE_HISTORY_HEATMAP',

  GET_MOOD_REQUEST: 'GET_MOOD_REQUEST',
  GET_MOOD_SUCCESS: 'GET_MOOD_SUCCESS',

  SET_MODAL_REQUEST: 'SET_MODAL_REQUEST',
  SET_LOADING_POST_HEATMAP: 'SET_LOADING_POST_HEATMAP',
  SET_EDIT_FAIL: 'SET_EDIT_FAIL',
  SET_DATE_MOOD: 'SET_DATE_MOOD',
  SET_LOADING_EDIT_HEATMAP: 'SET_LOADING_EDIT_HEATMAP'
};

export const addHeatmap = (data: FormItemState) => ({
  type: actionTypes.HEATMAP_ADD,
  data
});

export const changeHeatmap = (data: FormItemState) => ({
  type: actionTypes.HEATMAP_CHANGE,
  data
});

export const editForm = (index: string) => ({
  type: actionTypes.HEATMAP_EDIT_FORM,
  index
});

export const setFormIndex = (idx: number) => ({
  type: actionTypes.SET_FORM_INDEX,
  idx
});

export const removeHeatmap = (index: string) => ({
  type: actionTypes.REMOVE_HEATMAP,
  index
});

export const removeHeatmapSuccess = (data: FormItemState[]) => ({
  type: actionTypes.REMOVE_HEATMAP_SUCCESS,
  data
});

export const resetForm = () => ({
  type: actionTypes.RESET_FORM
});

export const copyHeatmap = (data: any) => ({
  type: actionTypes.COPY_HEATMAP,
  data
});

export const copyHeatmapSuccess = (data: any) => ({
  type: actionTypes.COPY_HEATMAP_SUCCESS,
  data
});

export const postHeatmap = (data: any) => ({
  type: actionTypes.POST_HEATMAP,
  data
});

export const postHeatmapSuccess = () => ({
  type: actionTypes.POST_HEATMAP_SUCCESS
});

export const dateChange = (data: any) => ({
  type: actionTypes.DATE_CHANGE,
  data
});

export const editHistoryHeatmap = (data: any) => ({
  type: actionTypes.EDIT_HISTORY_HEATMAP,
  data
});

export const deleteHistoryHeatmap = (data: any) => ({
  type: actionTypes.DELETE_HISTORY_HEATMAP,
  data
});

export const getMoodWorking = (date: any) => ({
  type: actionTypes.GET_MOOD_REQUEST,
  payload: { date }
});

export const setOpenModalMood = (openModal: boolean) => ({
  type: actionTypes.SET_MODAL_REQUEST,
  payload: openModal
});

export const setLoadingPostHeatmap = (loadingPostHeatmap: boolean) => ({
  type: actionTypes.SET_LOADING_POST_HEATMAP,
  payload: loadingPostHeatmap
});

export const setEditFail = (editFail: boolean) => ({
  type: actionTypes.SET_EDIT_FAIL,
  payload: editFail
});

export const setDateMood = (date: any) => ({
  type: actionTypes.SET_DATE_MOOD,
  payload: date
});

export const setLoadingEditHeatmap = (loadingEditHeatmap: boolean) => ({
  type: actionTypes.SET_LOADING_EDIT_HEATMAP,
  payload: loadingEditHeatmap
});
