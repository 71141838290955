import { ILateOffDayState } from '@src/interface/ILateOffDay';

export const actionTypes = {
  GET_LIST_DATA_LATE_OFF_DAY_REQUEST: '@@GET_LIST_DATA_LATE_OFF_DAY_REQUEST',
  GET_LIST_DATA_LATE_OFF_DAY_SUCCESS: '@@GET_LIST_DATA_LATE_OFF_DAY_SUCCESS',
  GET_LIST_DATA_LATE_OFF_DAY_ERROR: '@@GET_LIST_DATA_LATE_OFF_DAY_ERROR'
};

export const getListDataLateOffDay = (data: Array<ILateOffDayState>) => ({
  type: actionTypes.GET_LIST_DATA_LATE_OFF_DAY_REQUEST,
  data
});
