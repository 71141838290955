import React, { useEffect } from 'react';
import appStore from '@src/app/appStore';
import AppLayout from '@src/components/Layout';
import 'antd/dist/antd.css';
import 'cus-draft-js-mention-plugin/lib/plugin.css';
import 'draft-js-emoji-plugin/lib/plugin.css';
import 'draft-js-focus-plugin/lib/plugin.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import '@src/activities/images/preview.css';
import '@src/activities/poll/poll.css';
import '@src/components/Fabric/font.css';
import '@src/components/StickerPicker/styles.css';
import '@src/index.css';
import { useRouter } from 'next/router';
import { init } from '@src/utils/sentry';
import { SessionProvider } from 'next-auth/react';
import { ignorePaths } from '@src/common/constants';
import { theme } from '@src/styled/Theme';
import { ThemeProvider } from 'styled-components';
import { NextSeo } from 'next-seo';
import { getPageTitle } from '@src/common/utils';

init();

const MyApp = ({ Component, pageProps: { session, ...pageProps } }) => {
  const router = useRouter();
  const includesLayout = !ignorePaths.includes(router.pathname);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (const registration of registrations) {
          registration.unregister();
        }
      });
      // clear cache of service worker
      caches.keys().then((keyList) => {
        return Promise.all(
          keyList.map((key) => {
            return caches.delete(key);
          })
        );
      });
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const hash = (window.location.hash || '#').substr(1);
      if (hash) {
        const element = document.querySelector('#scroll-' + hash);

        if (element) {
          setTimeout(() => {
            element.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest'
            });
          }, 100);
        }
      }
    }
  }, [router]);

  return (
    <SessionProvider session={session}>
      <ThemeProvider theme={theme}>
        <NextSeo title={getPageTitle()} description="OFFWORK - TEQNOLOGICAL ASIA CO,. LTD" />
        {includesLayout ? (
          <AppLayout>
            <Component {...pageProps} />
          </AppLayout>
        ) : (
          <div className="outside-container">
            <div className="layout-outside">
              <Component {...pageProps} />
            </div>
          </div>
        )}
      </ThemeProvider>
    </SessionProvider>
  );
};

export default appStore.withRedux(MyApp);
