import styled from 'styled-components';

interface Props {
  padding?: number;
  isHover?: boolean;
  radius?: number;
  color?: string;
  bgColor?: string;
  fontSize?: string;
  fontWeight?: string;
  lineHeight?: string;
  require?: boolean;
}

const WrapperTemplateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const BoxTemplate = styled.div<Props>`
  padding: ${(props) =>
    props.padding
      ? `${props.padding}px 24px`
      : props.paddingX && props.paddingY
      ? `${props.paddingY}px ${props.paddingX}px`
      : `16px 24px`};
  background-color: ${(props) => props.theme.colors.light[1]};
  border-radius: ${(props) => (props.radius ? `${props.radius}px` : `10px`)};
  width: 100%;
  &:hover {
    background-color: ${(props) =>
      props.isHover ? props.theme.colors.light[5] : props.theme.colors.light[1]};
  }
  &:hover > div:last-child {
    display: flex;
  }
`;

const WrapperText = styled.p`
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #1a1a1a;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 0;
  @media screen and (max-width: 1440px) {
    width: 105px;
  }
`;

const BoxBtnControl = styled.div`
  display: none;
  align-items: center;
  cursor: pointer;
`;

const ButtonControl = styled.div`
  height: 14px;
  width: 14px;
`;

const ButtonClose = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  padding: 0;
  right: 14px;
  top: 14.5px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const Input = styled.input`
  padding: 8px 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  border-radius: 4px;
  font-size: 14px;
  border: 1px solid #d3d3d3;
  background: #fdfdfd;
  outline: none;
  color: #1a1a1a;
  &::placeholder {
    color: #dadada;
    font-size: 14px;
  }
`;

const TextArea = styled.textarea`
  padding: 8px 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  border-radius: 4px;
  font-size: 14px;
  border: 1px solid #d3d3d3;
  background: #fdfdfd;
  height: 144px;
  resize: none;
  outline: none;
  color: #1a1a1a;
  &::placeholder {
    color: #dadada;
    font-size: 14px;
  }
`;

const Button = styled.button<Props>`
  padding: ${(props) => (props.padding ? `${props.padding}` : `10px 24px`)};
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.color ? `${props.color}` : `#1a1a1a`)};
  background-color: ${(props) => (props.bgColor ? `${props.bgColor}` : `#F1F1F1`)};
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  border-radius: 4px;
  border: none;
  cursor: pointer;
`;

const Text = styled.p<Props>`
  color: ${(props) => (props.color ? `${props.color}` : `#1A1A1A`)};
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : `14px`)};
  font-style: normal;
  font-weight: ${(props) => (props.fontWeight ? `${props.fontWeight}` : `400`)};
  line-height: ${(props) => (props.lineHeight ? `${props.lineHeight}` : `130%`)};
  margin-bottom: 0;
  &:after {
    content: ${(props) => (props.require ? `"*"` : ``)};
    color: #d1575c;
    margin-left: 4px;
  }
`;

export default {
  BoxTemplate,
  WrapperText,
  BoxBtnControl,
  WrapperTemplateContainer,
  ButtonControl,
  ButtonClose,
  Input,
  TextArea,
  Button,
  Text
};
