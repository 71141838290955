import { combineReducers } from 'redux';
import { reducer as modal } from 'redux-modal';
import { signinReducer } from '@src/components/signin/signinReducer';
import { eventReducer } from '@src/components/events/eventReducer';
import commonReducer from '@src/common/commonReducer';
import { headerReducer } from '@src/components/Header/headerReducer';
import { notificationReducer } from '@src/notifications/notificationReducer';
import formReducer from '@src/components/Heatmap/form/formReducer';
import { chartReducer } from '@src/components/Chart/chartReducer';
import { otherProfileReducer } from '@src/profile/OtherProfile/OtherProfileReducer';
import { historyReducer } from '@src/components/Heatmap/history/historyReducer';
import { chartProfileReducer } from '@src/profile/ProfileReducer';
import { forgotPasswordReducer } from '@src/forgotPassword/forgotPasswordReducer';
import { resetPasswordReducer } from '@src/resetPassword/resetPasswordReducer';
import { activitiesReducer } from '@src/activities/activitiesReducer';
import { announcementsReducer } from '@src/activities/announcements/announcementsReducer';
import { searchheaderReducer } from '@src/activities/header/searchheaderReducer';
import { hashtagRecommentReducer } from '@src/components/SlideHashTag/recommentHashtagReducer';
import { statusReducer } from '@src/activities/status/statusReducer';
import { giphyReducer } from 'src/components/Giphy/giphyReducer';
import { detailReducer } from '@src/activities/NewsEventDetail/DetailReducer';
import { imageCommentReducer } from '@src/components/ImageComment/imageCommentReducer';
import { commentStatusReducer } from '@src/components/CommentStatus/commentStatusReducer';
import { newsReducer } from '@src/activities/news/newsReducer';
import { createReducer } from '@src/activities/create/createReducer';
import { categoryTabReducer } from '@src/components/CategoryTab/categoryTabReducer';
import { thanksReducer } from '@src/activities/thanks/thanksReducer';
import { videoCommentReducer } from '@src/components/VideoComment/videoCommentReducer';
import { pollReducer } from '@src/activities/poll/pollReducer';
import { listUserLikeReducer } from '@src/components/CardLike/listUserLikeReducer';
import { rewardsReducer } from '@src/reward/rewardReducer';
import { lateOffDayReducer } from '@src/components/LateOffDay/lateOffDayReducer';
import { dashboardReducer } from '@src/dashboard/dashboardReducer';
import { chatGptReducer } from '@src/chatgpt/chatGptReducer';
import { favoriteProjectsReducer } from '@src/components/Heatmap/project/favoriteProjectsReducer';
import { templateReducer } from '@src/components/Heatmap/template/templateReducer';
import { dataHeatmapReducer } from '@src/components/Heatmap/dataHeatmapReducer';
import { editHeatmapReducer } from '@src/components/Modal/ModalEditHeatmap/editHeatmapReducer';

export const appReducer = combineReducers({
  commonState: commonReducer,
  signinState: signinReducer,
  headerState: headerReducer,
  eventState: eventReducer,
  notificationState: notificationReducer,
  formState: formReducer,
  heatmapState: chartReducer,
  historyState: historyReducer,
  chartProfileState: chartProfileReducer,
  otherProfileState: otherProfileReducer,
  forgotPasswordState: forgotPasswordReducer,
  resetPasswordState: resetPasswordReducer,
  activitiesState: activitiesReducer,
  announcementsState: announcementsReducer,
  searchheaderState: searchheaderReducer,
  hashtagRecommentState: hashtagRecommentReducer,
  statusState: statusReducer,
  detailState: detailReducer,
  imageCmtState: imageCommentReducer,
  giphyState: giphyReducer,
  commentStatusState: commentStatusReducer,
  newsState: newsReducer,
  createnewState: createReducer,
  categoryState: categoryTabReducer,
  thanksState: thanksReducer,
  videoCommentState: videoCommentReducer,
  pollState: pollReducer,
  listUserLikeState: listUserLikeReducer,
  rewardsState: rewardsReducer,
  lateOffDayState: lateOffDayReducer,
  newestPostsState: dashboardReducer,
  chatGptState: chatGptReducer,
  favoriteProjectsState: favoriteProjectsReducer,
  modal,
  templateState: templateReducer,
  dataHeatmapState: dataHeatmapReducer,
  dataHistoryHeatmapState: historyReducer,
  editHeatmapState: editHeatmapReducer
});
