import baseStyled, { ThemedStyledInterface } from 'styled-components';
export const theme = {
  colors: {
    dark: {
      1: '#16141A',
      2: '#060606',
      3: '#333333',
      4: '#606060',
      5: '#8B8B8B',
      6: '#B8B8B8',
      7: '#060606'
    },
    light: {
      1: '#FFFFFF',
      2: '#FAFAFA',
      3: '#F5F5F5',
      4: '#FEFEFE',
      5: '#EAEAEA'
    },
    bg: '#F2F4F4',
    normal: {
      1: '#EB5757',
      2: '#F2994A',
      3: '#0B7BFF',
      4: '#6FCF97',
      5: '#885AF8',
      6: 'rgba(47, 128, 237, 0.1)',
      7: 'rgba(141,186,239,0.20)'
    }
  },
  fonts: ['sans-serif', 'Nunito Sans'],
  fontWeight: {
    regular: 400,
    semibold: 600,
    bold: 700
  },
  fontSizes: {
    10: '0.625rem',
    '11.2': '0.7rem',
    12: '0.75rem',
    14: '0.875rem',
    16: '1rem',
    18: '1.125rem',
    20: '1.25rem',
    24: '1.5rem',
    28: '1.75rem',
    42: '2.625rem',
    68: '4.25rem'
  },
  shadow: {
    1: '0px 2px 18px rgba(0, 0, 0, 0.08)'
  }
};
export type Theme = typeof theme;
export const styled = baseStyled as ThemedStyledInterface<Theme>;
