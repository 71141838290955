/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import Layout from 'antd/lib/layout';
import message from 'antd/lib/message';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '@src/interface/IAppState';
import { CommonState } from 'src/interface/ICommonState';
import AppVersion from '@src/app/AppVersion';
import EventDecoration from '@src/components/events/EventDecoration';
import { IprofileState } from '@src/interface/IProfileState';
import { resetError, setFirstLoadingDone } from '@src/common/commonAction';
import Header from '@src/components/Header';
import ScrollMenu from '@src/components/ScrollMenu';
import withAuthenticate from '../WithAuthenticate/withAuthenticate';
import { useSession } from 'next-auth/react';
import { logoutKeycloak } from '@src/utils/keyCloak';
import LogoutModal from '../LogoutModal';
import storeLogout from '@src/components/LogoutModal/forceLogoutStore';
import { scrollIntoView } from 'seamless-scroll-polyfill';
import FeedbackIcon from '@src/components/Feedback/FeedbackIcon';

const { Content } = Layout;

const AppLayout: React.FC = ({ children }) => {
  const router = useRouter();
  const { data: session } = useSession();
  const dispatch = useDispatch();
  const [visibility, setVisibility] = useState(false);
  const [opacity, setOpacity] = useState(0);
  const commonState: CommonState = useSelector((state: IAppState) => state.commonState);
  const infoUser: IprofileState = useSelector((state: IAppState) => state.headerState);
  const { isError, isLoading, isSuccess, errorMessage, isFirstLoading } = commonState;
  const { isForceLogout } = storeLogout.getState();

  useEffect(() => {
    const key = 'updatable';
    isLoading && message.loading({ content: 'Loading...', key });
    isFirstLoading && isSuccess && message.success({ content: 'Success', key, duration: 1 });
    if (isError && !isForceLogout) {
      message.error({
        content: errorMessage || 'Something went wrong!!!',
        key,
        duration: 1
      });
      dispatch(resetError());
    }
    dispatch(setFirstLoadingDone());
  }, [isError, isLoading, isSuccess, dispatch, errorMessage]);

  useEffect(() => {
    if (!session) {
      void logoutKeycloak(session as null);
    }
  }, []);

  const isActivity = useMemo(() => {
    return router.pathname.includes('/activity');
  }, [router]);

  const isFeedback = useMemo(() => {
    return router.pathname.includes('/feedback');
  }, [router]);

  const handleScroll = (e) => {
    if (e.target.id !== 'id-body') {
      return;
    }
    const positionY = e.nativeEvent.target.scrollTop;
    positionY >= 300 && positionY <= 500
      ? setOpacity((positionY - 300) / 2)
      : positionY > 500
      ? setOpacity(100)
      : setOpacity(0);
    positionY > 300 &&
    router.pathname.includes('/activity') &&
    !router.pathname.includes('/[id]') &&
    !router.pathname.includes('/create') &&
    !router.pathname.includes('/edit')
      ? setVisibility(true)
      : setVisibility(false);
  };
  useEffect(() => {
    setTimeout(() => {
      scrollIntoView(document.querySelector('#top-layout') as HTMLElement, {
        block: 'end',
        inline: 'end'
      });
    }, 0);
  }, [router.pathname]);
  return (
    <>
      {/* @ts-ignore */}
      <Layout>
        <EventDecoration {...infoUser} />
        <Header />
        {/* @ts-ignore */}
        <Layout id="id-body" className="main-container" onScroll={handleScroll}>
          {/* @ts-ignore */}
          <Content
            style={{
              marginTop: isFeedback ? 0 : 64,
              padding: `${isFeedback ? '0' : '20px'} ${isActivity || isFeedback ? '0' : '9%'}`
            }}>
            <span id="top-layout" />
            {children}
            <FeedbackIcon isDisplay={isFeedback} />
          </Content>
          <ScrollMenu visibility={visibility} opacity={opacity} />
          <AppVersion />
        </Layout>
      </Layout>
      {isForceLogout && <LogoutModal isOpen={isForceLogout} />}
    </>
  );
};

export default withAuthenticate(AppLayout);
