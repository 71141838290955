import { actionTypes } from './rewardAction';
import { all, takeLatest, put, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { RewardsApi } from 'src/api/RewardsApi';

function* requestListReward() {
  try {
    const data: AxiosResponse = yield call(RewardsApi.getListReward);
    yield put({
      type: actionTypes.GET_LIST_REWARD_SUCCESS,
      payload: data
    });
  } catch (e) {
    console.log(e);
  }
}

function* requestDataRewardDetail() {
  try {
    const data: AxiosResponse = yield call(RewardsApi.getRewardDetail);
    if (data) {
      yield put({ type: actionTypes.GET_DATA_REWARD_DETAIL_SUCCESS, data });
    } else {
      yield put({ type: actionTypes.GET_DATA_REWARD_DETAIL_FAIL });
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_DATA_REWARD_DETAIL_FAIL });
  }
}

function* postRewardDetail(action: any) {
  const { id } = action;
  try {
    const data: AxiosResponse = yield call(RewardsApi.postRedeem, id);
    if (data) {
      yield put({ type: actionTypes.POST_REDEEM_REWARD_DETAIL_SUCCESS, payload: id });
    } else {
      yield put({ type: actionTypes.POST_REDEEM_REWARD_DETAIL_FALL });
    }
  } catch (error) {
    yield put({ type: actionTypes.POST_REDEEM_REWARD_DETAIL_FALL });
  }
}

function* watchRewardDetail() {
  yield takeLatest(actionTypes.REQUEST_DATA_REWARD_DETAIL, requestDataRewardDetail);
}

function* watchPostRedeem() {
  yield takeLatest(actionTypes.POST_REDEEM_REWARD_DETAIL, postRewardDetail);
}

function* watchRequestListReward() {
  yield takeLatest(actionTypes.GET_LIST_REWARD_REQUEST, requestListReward);
}

export function* rewardSaga() {
  yield all([watchRequestListReward(), watchRewardDetail(), watchPostRedeem()]);
}
