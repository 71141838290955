import { styled } from 'src/styled/Theme';

interface Props {
  percent: number;
}

interface PropsOption {
  selected?: boolean;
}

const VoteOptionContainerBar = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  position: relative;
  border-radius: 4px;
  align-items: center;
  border: 1px solid rgba(184, 184, 184, 0.2);
  background-color: rgba(234, 234, 234, 0.2);
`;

const AnswerBar = styled.div<Props>`
  width: ${(props) => props.percent}%;
  height: 38px;
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.normal[7]};
  vertical-align: middle;
  line-height: 38px;
  color: ${(props) => props.theme.colors.dark[3]};
  font-size: 16px;
  opacity: 1;
  position: relative;
`;

const MoreOptionBar = styled.div`
  width: 100%;
  height: 40px;
  margin-top: 8px;
  vertical-align: middle;
  text-align: center;
  border-radius: 4px;
  line-height: 40px;
  border: 1px dashed ${(props) => props.theme.colors.dark[7]};
  font-size: 16px;
  color: ${(props) => props.theme.colors.dark[3]};
  cursor: pointer;
`;

const Value = styled.span`
  position: absolute;
  left: 16px;
`;

const ActiveOptionBar = styled(VoteOptionContainerBar)<PropsOption>`
  height: 40px;
  width: 100%;
  display: flex;
  padding-left: 7px;
  padding-right: 12px;
  border-radius: 4px;
  margin-bottom: 8px;
  align-items: center;
  border: 1px solid ${(props) => (props.selected ? 'rgb(6 6 6 / 0.3)' : 'rgb(184 184 184 / 0.2)')};
  background: rgb(234 234 234 / 0.2);
  .ant-checkbox-checked::after {
    border: none;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    border-radius: 4px;
    border-color: #060606;
    background-color: #060606;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border: 1px solid #fff;
    border-top: 0;
    border-left: 0;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #060606;
  }
  .ant-radio-inner::after {
    background-color: #060606;
  }
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: #060606;
  }
  .ant-input-affix-wrapper {
    border: none;
    background-color: transparent;
  }
  .ant-input {
    border: none;
    background-color: transparent;
  }
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    box-shadow: none;
  }

  .votes-result-bar {
    left: 0;
    position: absolute;
  }
`;

export default {
  Value,
  AnswerBar,
  MoreOptionBar,
  ActiveOptionBar,
  VoteOptionContainerBar
};
