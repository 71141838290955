export const actionTypes = {
  IMPORT_IMG_COMMENT_UPLOAD: 'IMPORT_IMG_COMMENT_UPLOAD',
  IMPORT_EDIT_IMG_COMMENT_UPLOAD: 'IMPORT_EDIT_IMG_COMMENT_UPLOAD',
  SAVE_IMG_COMMENT_UPLOAD: 'SAVE_IMG_COMMENT_UPLOAD',
  DESTROY_IMG_COMMENT: 'DESTROY_IMG_COMMENT',
  DESTROY_IMG_COMMENT_EDIT: 'DESTROY_IMG_COMMENT_EDIT',

  CLOSE_IMG: 'CLOSE_IMG',
  CLOSE_IMG_EDIT: 'CLOSE_IMG_EDIT',
  DELETE_IMAGE: 'DELETE_IMAGE',
  IS_CHOOSING_IMAGE: 'IS_CHOOSING_IMAGE'
};

export const actionImgCommentUpLoad = (file: any) => ({
  type: actionTypes.IMPORT_IMG_COMMENT_UPLOAD,
  file
});

export const deleteImgComment = () => ({
  type: actionTypes.DESTROY_IMG_COMMENT
});

export const deleteImgCommentEdit = () => ({
  type: actionTypes.DESTROY_IMG_COMMENT_EDIT
});

export const closeImages = (imagesId: number) => ({
  type: actionTypes.CLOSE_IMG,
  imagesId
});

export const closeImagesEdit = (imagesId: number) => ({
  type: actionTypes.CLOSE_IMG_EDIT,
  imagesId
});

export const deleteImages = () => ({
  type: actionTypes.DELETE_IMAGE
});

export const isChoosingImageComment = () => ({
  type: actionTypes.IS_CHOOSING_IMAGE
});

export const saveImageEditComment = (image: any) => ({
  type: actionTypes.IMPORT_EDIT_IMG_COMMENT_UPLOAD,
  image
});
