import { actionTypes } from './ProfileAction';
import { IprofileChartState } from '@src/interface/IChartProfileState';

const initialState: IprofileChartState = {
  dataPoint: [],
  dataPost: [],
  dataGivePoint: {
    users: [],
    total_point: 0
  },
  dataReceivePoint: {
    users: [],
    total_point: 0
  },
  loadDataPoint: false,
  loadDataPost: false,
  loadDataGivePoint: false,
  loadDataReceivePoint: false
};

export function chartProfileReducer(state = initialState, action: any) {
  const { data } = action;
  switch (action.type) {
    case actionTypes.GET_TOTAL_POINTS_RECEIVE: {
      return {
        ...state,
        dataPoint: data,
        loadDataPoint: false
      };
    }
    case actionTypes.REQUEST_TOTAL_POINTS_RECEIVE: {
      return {
        ...state,
        loadDataPoint: true
      };
    }
    case actionTypes.GET_TOTAL_POSTS: {
      return {
        ...state,
        dataPost: data,
        loadDataPost: false
      };
    }
    case actionTypes.REQUEST_TOTAL_POSTS: {
      return {
        ...state,
        loadDataPost: true
      };
    }
    case actionTypes.GET_TOP_GIVE_POINT: {
      return {
        ...state,
        dataGivePoint: data,
        loadDataGivePoint: false
      };
    }
    case actionTypes.REQUEST_TOP_GIVE_POINT: {
      return {
        ...state,
        loadDataGivePoint: true
      };
    }
    case actionTypes.GET_TOP_RECEIVE_POINT: {
      return {
        ...state,
        dataReceivePoint: data,
        loadDataReceivePoint: false
      };
    }
    case actionTypes.REQUEST_TOP_RECEIVE_POINT: {
      return {
        ...state,
        loadDataReceivePoint: true
      };
    }
    default:
      return state;
  }
}
