import { setForceLogout } from '@src/components/LogoutModal/forceLogoutAction';
import store from '@src/components/LogoutModal/forceLogoutStore';
const handleError = (code: number): boolean => {
  if (code && code === 401) {
    try {
      store.dispatch(setForceLogout(true));
      const notiFlag = localStorage.getItem('isShowNotiLog');
      const newsLocal = localStorage.getItem('DRAFT_NEWS');
      const announcementLocal = localStorage.getItem('DRAFT_ANNOUNCEMENT');
      const heatMap = localStorage.getItem('HEATMAP');
      const statusLocal = localStorage.getItem('DRAFT_STATUS');
      const pollLocal = localStorage.getItem('pollDraft');
      localStorage.clear();
      if (notiFlag) localStorage.setItem('isShowNotiLog', notiFlag);
      if (newsLocal) localStorage.setItem('DRAFT_NEWS', newsLocal);
      if (announcementLocal) localStorage.setItem('DRAFT_ANNOUNCEMENT', announcementLocal);
      if (heatMap) localStorage.setItem('HEATMAP', heatMap);
      if (statusLocal) localStorage.setItem('DRAFT_STATUS', statusLocal);
      if (pollLocal) localStorage.setItem('pollDraft', pollLocal);
    } catch (error) {
      console.log(error);
    }
    return true;
  }
  return false;
};

export default handleError;
