import { styled } from 'src/styled/Theme';
const StatusPreview = styled.div`
  z-index: 2;
  top: -7px;
  left: -8px;
  border: none;
  display: flex;
  cursor: pointer;
  position: absolute;
  align-self: flex-end;
`;

export default { StatusPreview };
