const API_ROUTES = {
  //ChatGPT
  CONVERSATION: 'conversations',
  CONVERSATION_DETAIL: (id: string) => `conversations/${id}`,
  GPT_MESAGE: 'messages',
  GPT_FEEDBACK: (msg_id: string) => `messages/${msg_id}/feedback`,
  LIMIT_CONVERSATIONS: 'conversations/is_limited',
  MODELS: 'models'
};

export const MODELS_DEFAULT = 'au-azure-gpt-35-turbo-1106';

export default API_ROUTES;
