import { appAxios } from '@src/api/appAxios';
import handleError from './handleError';

export const DashboardAPI = {
  // eslint-disable-next-line  @typescript-eslint/ban-types
  getNewestPost: async (): Promise<any> => {
    try {
      const { data } = await appAxios.get('posts/v2.2/dashboard/newest');
      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      // throw error;
    }
  }
};
