const APP_ROUTES = {
  HOME: '/',
  SIGN_IN: '/signin',
  RESET_PASSWORD: '/resetpassword',
  FORGOT_PASSWORD: '/forgotpassword',
  HEATMAP: '/heatmap',
  NOT_FOUND: '/404',
  PORTAL: '/portal',

  //Reward
  REWARD: '/reward',
  REWARD_DETAIL: '/reward/[id]',

  //Profile
  PROFILE: '/profile',
  PROFILE_DETAIL: '/profile/[id]',

  //Activity
  ACTIVITY: '/activity',

  //Activity Announcements
  ACTIVITY_ANNOUNCEMENTS: '/activity/announcements',
  ACTIVITY_ANNOUNCEMENTS_DETAIL: '/activity/announcements/[id]',
  ACTIVITY_ANNOUNCEMENTS_CREATE: '/activity/announcements/create',
  ACTIVITY_ANNOUNCEMENTS_EDIT: '/activity/announcements/edit/[id]',

  //Activity News
  ACTIVITY_NEWS: '/activity/blog',
  ACTIVITY_NEWS_DETAIL: '/activity/blog/[id]',
  ACTIVITY_NEWS_CREATE: '/activity/blog/create',
  ACTIVITY_NEWS_EDIT: '/activity/blog/edit/[id]',

  //Activity Poll
  ACTIVITY_POLL: '/activity/poll/[id]',

  //Activity Status
  ACTIVITY_STATUS: '/activity/status',
  ACTIVITY_STATUS_DETAIL: '/activity/status/[id]',

  //Activity Point Reward
  ACTIVITY_POINT_REWARD: '/activity/point_reward',

  //Q&A
  QA: '/qa'
};

export default APP_ROUTES;
