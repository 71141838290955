import { styled } from '@src/styled/Theme';
interface Props {
  padding?: number;
  radius?: number;
  color?: string;
  bgColor?: string;
  fontSize?: string;
  fontWeight?: string;
  lineHeight?: string;
  require?: boolean;
  border?: string;
  disable?: boolean;
  active?: boolean;
  cursor?: string;
  hover?: boolean;
}
interface ColorProjectProps {
  color: string;
}
const Container = styled.div`
  width: 100%;
  height: 100%;
`;
const Header = styled.div`
  display: flex;
  gap: 4px;
  width: 100%;
`;
const DatePickerBox = styled.div`
  display: flex;
  width: calc(100% - 159px);
`;
const Button = styled.button<Props>`
  pointer-events: ${(props) => (props.disable ? `none` : `auto`)};
  padding: ${(props) => (props.padding ? `${props.padding}` : `10px 0`)};
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.disable ? '#000' : props.color ? `${props.color}` : `#1a1a1a`)};
  background-color: ${(props) =>
    props.disable ? '#EFEFEF' : props.bgColor ? `${props.bgColor}` : `#F1F1F1`};
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  border-radius: 4px;
  border: none;
  min-width: 76px;
  cursor: ${(props) => (props.disable ? `not-allowed` : `pointer`)};
  border: ${(props) => (props.border ? `${props.border}` : `none`)};
`;
const ContainerDefault = styled.div`
  display: flex;
  width: 100%;
  padding: 29px 25px 30.396px 25px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #fbfbfb;
  margin-top: 20px;
  flex-direction: column;
  gap: 14px;
`;

const Text = styled.p<Props>`
  color: ${(props) => (props.color ? `${props.color}` : `#1A1A1A`)};
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : `14px`)};
  font-style: normal;
  font-weight: ${(props) => (props.fontWeight ? `${props.fontWeight}` : `400`)};
  line-height: ${(props) => (props.lineHeight ? `${props.lineHeight}` : `130%`)};
  margin-bottom: 0;
  &:after {
    content: ${(props) => (props.require ? `"*"` : ``)};
    color: #d1575c;
    margin-left: 4px;
  }
`;

const WrapperDefault = styled.div`
  display: flex;
  padding: 16px 18px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #f6f6f6;
  width: 100%;
`;
const ContainerForm = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 34px - 20px);
  align-items: flex-start;
  gap: 10px;
  overflow-y: scroll;
  margin-top: 20px;
  flex-direction: column;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const ContainerItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  .save_tmp_tooltip {
    background-color: rgba(26, 26, 26, 0.73) !important;
    font-size: 12px;
    line-height: 12px;
    &.__react_component_tooltip {
      opacity: 1 !important;
    }
    :before {
      height: 0 !important;
    }
  }
`;
const HeaderItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;
const TitleBox = styled.div<Props>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 10px;
  border-radius: 4px;
  width: calc(100% - 30px);
  &:hover {
    background-color: ${(props) =>
      props.hover ? '#f7f8f8' : props.bgColor ? `${props.bgColor}` : `#FFFF`};
    cursor: ${(props) => (props.hover ? 'pointer' : props.cursor ? props.cursor : `default`)};
  }
`;
const ColorProject = styled.div<ColorProjectProps>`
  width: 8px;
  height: 14px;
  background-color: ${(props) => (props.color ? `${props.color}` : `#F1F1F1`)};
  border-radius: 2px;
`;

const BodyForm = styled.div`
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid #edefef;
  background: #f7f8f8;
  width: 100%;
`;

const BoxAddHour = styled.div`
  display: flex;
  padding: 4px;
  align-items: flex-start;
  gap: 4px;
  border-radius: 4px;
  border: 0.8px solid #cbe5c7;
  background: #e9f3e7;
  width: 100%;
`;

const BoxItemHour = styled.div`
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background: #fafcfa;
  width: calc((100% - 90px) / 8);
  color: #31662d;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  cursor: pointer;
  &:last-child {
    font-weight: 700;
    width: 60px;
  }
`;
const BoxInputAddHours = styled.div`
  width: 100%;
  height: 34px;
  background-color: #fdfdfd;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  display: flex;
  align-items: center;
  padding-right: 12px;
  gap: 10px;
  img {
    margin-top: 0;
  }
`;

const BoxTemplate = styled.div`
  padding: 4px;
  border-radius: 4px;
  border: 0.8px solid #d2d1ec;
  background: #eae6f2;
  width: 100%;
  height: 35px;
`;

const BoxTemplateContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  gap: 4px;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const TemplateItem = styled.div`
  border: ${(props) => (props.active ? '1px solid #575757' : 'none')};
  display: flex;
  padding: ${(props) => (props.active ? '5px' : '6px')};
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 3px;
  background: #fafcfa;
  cursor: pointer;
  p {
    width: 64px;
    text-overflow: ellipsis;
    color: #552b8a;
    text-align: left;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 0;
  }
`;
const BoxInputArea = styled.div`
  width: 100%;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  background: #fdfdfd;
  position: relative;
`;
const ImgClear = styled.img`
  position: absolute;
  z-index: 1;
  width: 16px;
  height: 16px;
  top: 8px;
  right: 8px;
`;
const BoxSaveTemplate = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: #fff;
  border-radius: 4px;
  padding-right: 6px;
  padding-bottom: 6px;
  cursor: pointer;
`;
export default {
  Container,
  Header,
  DatePickerBox,
  Button,
  ContainerDefault,
  Text,
  WrapperDefault,
  ContainerForm,
  ContainerItem,
  HeaderItem,
  TitleBox,
  ColorProject,
  BodyForm,
  BoxAddHour,
  BoxItemHour,
  BoxInputAddHours,
  BoxTemplate,
  TemplateItem,
  BoxInputArea,
  BoxSaveTemplate,
  ImgClear,
  BoxTemplateContainer
};
