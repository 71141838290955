import { AxiosResponse } from 'axios';
import { appAxios } from '@src/api/appAxios';
import handleError from './handleError';
export const NotificationApi = {
  getListNotification: async (params: { page: number; limit: number }) => {
    try {
      const { page, limit } = params;
      const { data }: AxiosResponse = await appAxios.get(`posts/v2/notifications`, {
        params: { page: page, limit: limit }
      });
      return data.code === 200 && data ? data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      // throw error;
    }
  },

  readNotification: async (id: string) => {
    try {
      const { data }: AxiosResponse = await appAxios.post(`posts/v2/notifications/${id}/read`);
      return data.code === 200 && data ? data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      // throw error;
    }
  },

  addDevice: async (params: any) => {
    try {
      const { data }: AxiosResponse = await appAxios.post(`posts/v2/users/devices`, params);
      return data.code === 200 && data ? data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      // throw error;
    }
  },
  countNotiTab: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get(`posts/v2/posts/new`);
      return data.code === 200 && data && data.data && data.data.count ? data.data.count : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      // throw error;
    }
  },

  readAllNotification: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.post(`posts/v2/notifications/read-all`);
      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      // throw error;
    }
  }
};
