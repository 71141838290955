import dayjs from 'dayjs';
import { IitemComment } from 'src/interface/IDetailState';

export const FindTimeFromNow = (timeComment: string): string => {
  let timeFrom = '';
  const findMinute = dayjs().diff(dayjs(timeComment), 'minute');
  if (findMinute <= 0) {
    const findSecond = dayjs().diff(dayjs(timeComment), 'second');
    findSecond === 0 || findSecond < 0
      ? (timeFrom = 'Just now')
      : (timeFrom = findSecond + ' seconds ago');
  } else if (findMinute > 0 && findMinute < 60) {
    timeFrom = findMinute + ' minutes ago';
  } else if (findMinute >= 60 && findMinute < 1440) {
    timeFrom = Math.floor(findMinute / 60) + ' hours ago';
  } else if (findMinute >= 1440 && findMinute < 10080) {
    timeFrom = Math.floor(findMinute / 1440) + ' days ago';
  } else if (findMinute >= 10080 && findMinute < 40320) {
    timeFrom = Math.floor(findMinute / 10080) + ' weeks ago';
  } else if (findMinute >= 40320 && findMinute < 483840) {
    timeFrom = Math.floor(findMinute / 40320) + ' months ago';
  } else {
    timeFrom = Math.floor(findMinute / 483840) + ' years ago';
  }
  return timeFrom;
};
export const FindTimeComment = (timeComment: string): string => {
  let timeFrom = '';
  const findDay = dayjs().diff(dayjs(timeComment), 'day');
  if (findDay >= 30) {
    timeFrom = dayjs(timeComment).format('MMMM DD, YYYY');
  } else if (findDay < 30 && findDay >= 7) {
    timeFrom = dayjs(timeComment).format('MMMM DD');
  } else if (findDay < 7 && findDay >= 1) {
    timeFrom = dayjs(timeComment).format('hh:mmA MMMM DD');
  } else {
    timeFrom = dayjs(timeComment).format('hh:mmA');
  }
  return timeFrom;
};
export const handleScrollListComment = (idElement: string, isSmooth = false): void => {
  const el: HTMLElement | null = document.getElementById(idElement);
  if (el) {
    isSmooth ? el.scrollIntoView({ behavior: 'smooth' }) : el.scrollIntoView();
  }
};
export const checkFullItemComment = (
  listCommentFull: Array<IitemComment>,
  limitOfPage: number
): boolean => {
  return listCommentFull.length < limitOfPage * 20;
};
export const checkBottomElement = (idElement: string): boolean => {
  const ele: HTMLElement | null = document.getElementById(idElement);
  let isBottomPos = false;

  isBottomPos = ele ? ele.scrollTop === ele.scrollHeight - ele.offsetHeight : isBottomPos;
  return isBottomPos;
};
