import { appAxios } from '@src/api/appAxios';
import { IeventState } from '../interface/IEventState';
import handleError from './handleError';

export const EventAPI = {
  getListDataEvent: async (): Promise<any> => {
    try {
      const data: IeventState = await appAxios.get('v1/banners');
      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      // throw error;
    }
  }
};
