export const actionTypes = {
  GET_NEWEST_POSTS_REQUEST: '@@GET_NEWEST_POSTS_REQUEST',
  GET_NEWEST_POSTS_SUCCESS: '@@GET_NEWEST_POSTS_SUCCESS',
  GET_NEWEST_POSTS_ERROR: '@@GET_NEWEST_POSTS_ERROR'
};

// eslint-disable-next-line  @typescript-eslint/ban-types
export const getNewestPosts = (data: Array<{}>) => ({
  type: actionTypes.GET_NEWEST_POSTS_REQUEST,
  data
});
