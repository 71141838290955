import { AxiosResponse } from 'axios';
import { appAxios } from 'src/api/appAxios';
import handleError from './handleError';

export const RewardsApi = {
  getListReward: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get('points/v1/rewards');
      return data && data.code === 200 ? data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      // throw error;
    }
  },
  getRewardDetail: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get(`/points/v1/rewards`);
      return data.code === 200 && data.data ? data.data.rewards : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      // throw error;
    }
  },
  postRedeem: async (id: number) => {
    try {
      const { data }: AxiosResponse = await appAxios.post(`/points/v1/rewards/${id}/redeem`);
      return data.code === 200 ? true : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      // throw error;
    }
  }
};
