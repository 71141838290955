import { actionTypes } from './editHeatmapAction';

const initialState = {
  dateEdit: '',
  itemsEdit: [],
  dateLocal: ''
};

export function editHeatmapReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.EDIT_DATA_HEATMAP: {
      return {
        ...state,
        dateEdit: action.dateEdit,
        itemsEdit: action.itemsEdit
      };
    }
    case actionTypes.SET_DATE_LOCAL: {
      return {
        ...state,
        dateLocal: action.dateLocal
      };
    }
    default:
      return state;
  }
}
