import { IforgotPasswordState } from '@src/interface/IForgotPasswordState';
import { actionTypes } from './forgotPasswordAction';

const initialState: IforgotPasswordState = {
  message: '',
  isLoading: true
};

export const forgotPasswordReducer = (state: IforgotPasswordState = initialState, action) => {
  switch (action.type) {
    case actionTypes.FORGOT_PASSWORD_REQUEST:
      return { ...state, message: '', isLoading: true };
    case actionTypes.FORGOT_PASSWORD_SUCCESS:
      return { ...state, message: action.message, isLoading: false };
    case actionTypes.FORGOT_PASSWORD_FAILURE:
    case actionTypes.CLEAR_PREVIOUS_EMAIL:
      return { ...state, message: '', isLoading: false };
    default:
      return state;
  }
};
