import { all, call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  actionTypes,
  getTopGivePoint,
  getTopReceivePoint,
  getTotalPointsReceive,
  getTotalPost
} from './ProfileAction';
import { ProfileApi } from '@src/api/ProfileApi';
import { setError } from '@src/common/commonAction';

function* requestPointsReceiveSaga(action: any) {
  const { startDate, endDate } = action;
  try {
    try {
      const data: AxiosResponse = yield call(ProfileApi.getPointReceive, startDate, endDate);
      if (data) {
        yield put(getTotalPointsReceive(data));
      }
    } catch (error) {
      yield put(setError());
    }
  } catch (error) {
    console.log(error);
  }
}

function* requestTotalPostSaga(action: any) {
  const { startDate, endDate } = action;
  try {
    try {
      const data: AxiosResponse = yield call(ProfileApi.getTotalPost, startDate, endDate);
      if (data) {
        yield put(getTotalPost(data));
      }
    } catch (error) {
      yield put(setError());
    }
  } catch (error) {
    console.log(error);
  }
}

function* requestTopGivePoint(action: any) {
  const { startDate, endDate } = action;
  try {
    try {
      const data: AxiosResponse = yield call(ProfileApi.getTopGivePoint, startDate, endDate);
      if (data) {
        yield put(getTopGivePoint(data));
      }
    } catch (error) {
      yield put(setError());
    }
  } catch (error) {
    console.log(error);
  }
}

function* requestTopReceivePoint(action: any) {
  const { startDate, endDate } = action;
  try {
    try {
      const data: AxiosResponse = yield call(ProfileApi.getTopReceivePoint, startDate, endDate);
      if (data) {
        yield put(getTopReceivePoint(data));
      }
    } catch (error) {
      yield put(setError());
    }
  } catch (error) {
    console.log(error);
  }
}

function* followProfile(action: any): any {
  try {
    const data: AxiosResponse = yield call(
      ProfileApi.followProfile,
      Number(action.id),
      action.follow
    );
    if (data) {
      yield put({
        type: actionTypes.FOLLOW_PROFILE_SUCCESS
      });
    }
  } catch (e) {
    console.log(e);
  }
}

function* watchRequestTotalPosts() {
  yield takeLatest(actionTypes.REQUEST_TOTAL_POSTS, requestTotalPostSaga);
}

function* watchRequestTotalPoints() {
  yield takeLatest(actionTypes.REQUEST_TOTAL_POINTS_RECEIVE, requestPointsReceiveSaga);
}

function* watchRequestTopGivePoint() {
  yield takeLatest(actionTypes.REQUEST_TOP_GIVE_POINT, requestTopGivePoint);
}

function* watchRequestTopReceivePoint() {
  yield takeLatest(actionTypes.REQUEST_TOP_RECEIVE_POINT, requestTopReceivePoint);
}

function* watchFollowProfile() {
  yield takeLatest(actionTypes.FOLLOW_PROFILE, followProfile);
}

export function* chartProfileSaga() {
  yield all([
    watchRequestTotalPoints(),
    watchRequestTotalPosts(),
    watchRequestTopGivePoint(),
    watchRequestTopReceivePoint(),
    watchFollowProfile()
  ]);
}
