import { styled } from '@src/styled/Theme';
import { keyframes } from 'styled-components';

interface Props {
  color?: string;
  regular?: boolean;
  fz?: number;
  give?: boolean;
  fw?: number;
  mt?: number;
  mr?: number;
  mb?: number;
  ml?: number;
  textAlign?: string;
  click?: boolean;
  hover?: boolean;
  isEdit?: boolean;
  hidden?: boolean;
  user?: boolean;
  isActive?: boolean;
  animationDelay?: number;
  isApproved?: number;
}
const MainTitle = styled.h2<Props>`
  margin: 0;
  font-family: ${({ fm }) => (fm ? fm : `inherit`)};
  font-size: ${(props) => (props.fz ? `${props.fz / 16}rem` : props.theme.fontSizes[24])};
  color: ${(props) => props.theme.colors.dark[3]};
  font-weight: ${(props) => (props.fw ? props.fw : props.theme.fontWeight.bold)};
  text-align: ${({ textAlign }) => (textAlign ? `${textAlign}` : `inherit`)};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '')};
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '')};
`;
const SubTitle = styled.h4<Props>`
  margin: 0;
  font-size: ${(props) => (props.fz ? `${props.fz / 16}rem` : props.theme.fontSizes[16])};
  color: ${(props) => props.theme.colors.dark[3]};
  font-weight: ${(props) => props.theme.fontWeight.semibold};
  :hover {
    text-decoration: ${({ hover }) => (hover ? `underline` : `none`)};
    cursor: ${({ hover }) => (hover ? `pointer` : ``)};
    text-decoration-thickness: ${({ hover }) => (hover ? `1px` : ``)};
  }
`;
const EnterSubTitle = styled.p<Props>`
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  color: rgba(51, 51, 51, 0.3);
`;
const Normal = styled.p<Props>`
  margin: 0;
  font-size: ${(props) => (props.fz ? `${props.fz / 16}rem` : props.theme.fontSizes[14])};
  color: ${(props) => (props.color ? props.color : props.theme.colors.dark[3])};
  cursor: ${({ click }) => (click ? `pointer` : `default`)};
  text-align: ${({ textAlign }) => (textAlign ? `${textAlign}` : `inherit`)};
`;
const ProjectTitle = styled.div<Props>`
  padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : '24px')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : props.theme.fontSizes[16])};
  color: ${(props) => (props.fontColor ? props.fontColor : props.theme.colors.dark[3])};
  font-weight: ${(props) =>
    props.regular ? props.theme.fontWeight.regular : props.theme.fontWeight.semibold};
  position: relative;
  line-height: 18px;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 2px;
    height: ${(props) => (props.height ? props.height : '14px')};
    width: ${(props) => (props.width ? props.width : '8px')};
    border-radius: 2px;
    background-color: ${(props) => (props.color ? props.color : props.theme.colors.normal[3])};
  }
`;
const ActionText = styled.p<Props>`
  margin: 0;
  font-size: ${(props) => (props.fz ? `${props.fz / 16}rem` : props.theme.fontSizes[16])};
  color: ${(props) => props.theme.colors.normal[3]};
  font-weight: ${(props) => props.theme.fontWeight.semibold};
  cursor: pointer;
`;
const ContentNew = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  font-size: ${(props) => props.theme.fontSizes[16]};
  color: ${(props) => props.theme.colors.dark[3]};
`;
const TinyText = styled.p<Props>`
  margin: 0;
  cursor: ${({ click }) => (click ? `pointer` : `default`)};
  font-size: ${(props) => (props.fz ? `${props.fz / 16}rem` : props.theme.fontSizes[12])};
  color: ${(props) => (props.color ? props.color : props.theme.colors.dark[6])};
  text-align: ${({ textAlign }) => (textAlign ? `${textAlign}` : `inherit`)};
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '')};
  font-weight: ${(props) => (props.fw ? props.fw : 'initial')};
  :hover {
    text-decoration: ${({ hover }) => (hover ? `underline` : `none`)};
  }
  ${({ ml }) => (ml ? `margin-left: ${ml}px` : '')};
`;
const PointText = styled.div<Props>`
  font-weight: ${(props) => props.theme.fontWeight.bold};
  font-size: ${(props) => props.theme.fontSizes[42]};
  color: ${(props) => (props.give ? props.theme.colors.normal[4] : props.theme.colors.normal[2])};
`;

const TextSpacing = styled.div<Props>`
  font-weight: ${({ fw }) => (fw ? fw : '')};
  margin-left: ${({ ml }) => (ml ? `${ml}px` : '')};
  margin-right: ${({ mr }) => (mr ? `${mr}px` : '')};
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '')};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '')};
  text-transform: capitalize;
  color: #060606;
`;
const ContentComment = styled.div<Props>`
  word-break: break-word;
  padding: 9px 16px 8px 16px;
  margin-right: 20px;
  background-color: ${({ isEdit }) => (isEdit ? `#FFFFFF` : '#F5F5F5')};
  border-radius: 6px;
  text-align: left;
  border: 1px solid #f5f5f5;
  position: relative;
`;

const ThanksContent = styled.div<Props>`
  word-break: break-word;
  padding: 9px 16px 8px 16px;
  margin-right: 20px;
  background-color: #f5f5f5;
  border-radius: 6px;
  text-align: left;
  position: relative;
  color: ${(props) => props.theme.colors.dark[3]};

  .thanks-title {
    font-size: 15px;
  }

  .thanks-content {
    color: ${(props) => props.theme.colors.dark[3]};
    font-size: 15px;
    line-height: 20px;
    margin-top: 9px;
    margin-bottom: 5px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .thanks-point {
    background-color: #6fcf97;
    font-size: 12px;
    min-width: 32px;
    height: 20px;
    display: table-cell;
    line-height: 20px;
    vertical-align: middle;
    color: #fff;
    border-radius: 3px;
    margin-left: 12px;
    text-align: center;
  }

  strong {
    font-weight: 600;
    color: ${(props) => props.theme.colors.dark[2]};
  }
`;

const ThanksTime = styled.div<Props>`
  font-size: 12px;
  line-height: 16px;
  margin-top: 8px;
  color: ${({ isActive }) => (isActive ? `#0B7BFF` : `#606060`)};
  cursor: ${({ click }) => (click ? `pointer` : `default`)};

  :hover {
    text-decoration: ${({ click }) => (click ? `underline` : `none`)};
  }
`;
const InputComment = styled.textarea`
  border-radius: 6px;
  border: 1px solid #efefef;
  width: 100%;
  height: auto;
  padding: 12px;
  display: block;
  overflow: hidden;
  resize: none;
  box-sizing: border-box;
`;
const EditComment = styled.textarea`
  width: 100%;
  resize: none;
  border: none;
  outline: none;
  background-color: #ffffff;
`;
const TextEditDropdown = styled.div`
  padding: 4px 11px;
  color: #060606;
  font-size: 18px;
`;
const TextHashtag = styled.span<Props>`
  color: ${({ color }) => (color ? `${color}` : '#8B8B8B')};
  font-size: ${({ fz }) => (fz ? `${fz}px` : '15px')};
  margin: 0px 2px 0px 2px;
`;
const TextWillGet = styled.div`
  position: relative;
  padding-right: 33px;
  font-size: ${(props) => props.theme.fontSizes[68]};
  color: ${(props) => props.theme.colors.normal[4]};
  display: inline-block;
  line-height: 0.9;
  &:after {
    content: 'points';
    position: absolute;
    right: 0;
    top: 0;
    font-size: ${(props) => props.theme.fontSizes[10]};
    color: ${(props) => props.theme.colors.normal[4]};
  }
`;
const TextLoadMore = styled.div<Props>`
  display: ${({ hidden }) => (hidden ? `none` : 'block')};
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #606060;
  margin: 0px 0px 10px 47px;
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const TextFeeling = styled.span<Props>`
  color: ${(props) => props.theme.colors.dark[5]};
  font-size: 16px;
`;

const InforUser = styled.div<Props>`
  color: ${({ user }) => (user ? `#FFFFFF` : '#B8B8B8')};
  font-size: ${({ user }) => (user ? `24px` : '16px')};
`;

const TinyViewer = styled.p<Props>`
  margin: 0;
  cursor: ${({ click }) => (click ? `pointer` : `default`)};
  font-size: ${(props) => (props.fz ? `${props.fz / 16}rem` : props.theme.fontSizes[12])};
  color: ${(props) => props.theme.colors.dark[6]};
  text-align: ${({ textAlign }) => (textAlign ? `${textAlign}` : `inherit`)};
  :hover {
    text-decoration: ${({ hover }) => (hover ? `underline` : `none`)};
    color: #000;
    font-weight: 500;
  }
`;

const ellipsis = keyframes`
  0%   {top: 0px;}
  50%  {top: -4px;}
  100% {top: 0px;}
`;

const DotAnimation = styled.span<Props>`
  position: relative;
  margin: 1px;
  animation-name: ${ellipsis};
  animation-duration: 0.66s;
  animation-iteration-count: infinite;
  animation-delay: ${({ animationDelay }) => animationDelay}s;
`;
const StatusSubTitle = styled.p<Props>`
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  color: ${({ isApproved }) => (isApproved ? '#87C289' : 'rgba(51, 51, 51, 0.3)')};
`;
const FavoriteProjectCardTitle = styled.div`
  border-width: 1px 1px 0px 1px;
  border-style: solid;
  border-color: #e3e3e3;
  border-radius: 4px 4px 0 0;
  padding: 6px 10px 7px 10px;
  background: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  cursor: default;
  box-shadow: 0px 0.5px #e3e3e3;
  min-width: 150px;
  p {
    margin: 0;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #222222;
    width: max-content;
  }
`;

const WorkingProjectsTitle = styled.p`
  margin: 0;
  font-size: 13px;
  line-height: 18px;
  color: #7b7b7b;
  font-weight: 400;
  margin-bottom: 7px;
`;

const AddWorkingProjectsTitle = styled.div`
  width: fit-content;
  display: flex;
  gap: 4px;
  align-items: center;
  padding-top: 10px;
  cursor: pointer;
  user-select: none;
  p {
    margin: 0;
    color: #1a1a1a;
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    position: relative;
    :before {
      content: '';
      position: absolute;
      bottom: 0.5px;
      background: #1a1a1a;
      height: 1px;
      width: 100%;
    }
  }
`;

const FavoriteProjectsModalTitle = styled.p`
  margin: 0 0 16px 0;
  font-size: 16px;
  line-height: 20.8px;
  font-weight: bold;
  color: #1a1a1a;
`;

const Description = styled.div`
  padding-left: 24px;
  color: #8c8c8c;
  font-size: 13px;
  line-height: 17px;
`;
const Date = styled.div`
  margin: '0';
  font-size: 12px;
  color: #646464;
  line-height: 18px;
  padding-bottom: 4px;
`;
const EmptyText = styled.div`
  color: #959595;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  margin-bottom: 0px;
`;
const FlexText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export default {
  MainTitle,
  Normal,
  ProjectTitle,
  ActionText,
  ContentNew,
  TinyText,
  PointText,
  SubTitle,
  TextSpacing,
  ContentComment,
  ThanksContent,
  ThanksTime,
  InputComment,
  TextEditDropdown,
  TextWillGet,
  TextHashtag,
  EditComment,
  TextLoadMore,
  TextFeeling,
  InforUser,
  TinyViewer,
  DotAnimation,
  EnterSubTitle,
  StatusSubTitle,
  FavoriteProjectCardTitle,
  WorkingProjectsTitle,
  AddWorkingProjectsTitle,
  FavoriteProjectsModalTitle,
  Description,
  Date,
  EmptyText,
  FlexText
};
