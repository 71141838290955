import { all, takeLatest, put, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { actionTypes } from '@src/components/Heatmap/project/favoriteProjectsAction';
import { heatmapApi } from '@src/api/heatmapAPIService';
import { setError } from '@src/common/commonAction';

function* requestListFavoriteProjectsSaga() {
  try {
    const data: AxiosResponse = yield call(heatmapApi.getListFavoriteProjects);
    yield put({ type: actionTypes.REQUEST_LIST_FAVORITE_PROJECTS_SUCCESS, data: data || [] });
  } catch (error) {
    const message = error?.data?.message || '';
    yield put(setError(message));
    yield put({ type: actionTypes.REQUEST_LIST_FAVORITE_PROJECTS_FAILED });
  }
}

function* requestUpdateListFavoriteProjectsSaga() {
  try {
    const data: AxiosResponse = yield call(heatmapApi.getListFavoriteProjects);
    yield put({
      type: actionTypes.REQUEST_UPDATE_LIST_FAVORITE_PROJECTS_SUCCESS,
      data: data || []
    });
  } catch (error) {
    yield put({ type: actionTypes.REQUEST_UPDATE_LIST_FAVORITE_PROJECTS_FAILED });
  }
}

function* watchRequestListFavoriteProjects() {
  yield takeLatest(actionTypes.REQUEST_LIST_FAVORITE_PROJECTS, requestListFavoriteProjectsSaga);
}

function* watchRequestUpdateListFavoriteProjects() {
  yield takeLatest(
    actionTypes.REQUEST_UPDATE_LIST_FAVORITE_PROJECTS,
    requestUpdateListFavoriteProjectsSaga
  );
}

export function* favoriteProjectsSaga() {
  yield all([watchRequestListFavoriteProjects(), watchRequestUpdateListFavoriteProjects()]);
}
