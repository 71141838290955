import { put, takeLatest, all, call, select } from 'redux-saga/effects';
import Message from 'antd/lib/message';

import { FormItemState } from 'src/interface/IFormState';
import { handleRecent } from 'src/common/utils';
import { heatmapApi } from '@src/api/heatmapAPIService';
import { formSelector } from '../heatmapSelector';
import { setSuccess, setError } from 'src/common/commonAction';
import {
  actionTypes,
  removeHeatmapSuccess,
  copyHeatmapSuccess,
  postHeatmapSuccess,
  getMoodWorking,
  dateChange,
  setLoadingPostHeatmap,
  setEditFail,
  setLoadingEditHeatmap
} from './formAction';
import { moodWorkingAPI } from '@src/api/MoodWorkingApi';
import moment from 'moment';
import dayjs from 'dayjs';
import { loadDrafts, saveHeatmap } from '@src/utils/function';

function* removeHeatmap(action: any) {
  try {
    const forms = yield select(formSelector);
    let newForms = forms.filter((i: FormItemState) => i.id !== action.index);
    if (forms.length > 1 && action.index === 'default') {
      newForms = newForms.map((form: FormItemState, index: number) => {
        if (index === newForms.length - 1) {
          form.id = 'default';
          form.order = 999;
          form.isVisible = true;
          return form;
        }
        return form;
      });
    }
    yield put(removeHeatmapSuccess(newForms));
  } catch (error) {
    yield put(setError());
  }
}

function setValue(form: FormItemState, data: any) {
  form.project = data.id;
  form.hour = data.hour;
  form.activity = data.activity;
  form.isPassed = true;
  form.isCopy = true;
  return form;
}

function* copyHeatmapSaga(action: any) {
  try {
    const { data } = action;
    const forms = yield select(formSelector);
    const formsFilter = forms.filter((f: FormItemState) => f.project === data.id);
    if (formsFilter.length > 0) {
      const newForms = forms.map((form: FormItemState) => {
        if (form.project === data.id) {
          return setValue(form, data);
        }
        return form;
      });
      yield put(copyHeatmapSuccess(newForms));
    } else {
      const newForms = forms.map((form: FormItemState) => {
        if (form.id === 'default') {
          return setValue(form, data);
        }
        return form;
      });
      yield put(copyHeatmapSuccess(newForms));
    }
  } catch (error) {
    yield put(setError());
  }
}

function* editHistoryHeatmapSaga(data: any) {
  try {
    const { message }: { data: []; message: string } = yield call(heatmapApi.putHeatmap, data.data);
    if (message === 'OK') {
      const draft = loadDrafts('HEATMAP');
      delete draft[dayjs(data.data.from_date).format('DD-MM-YYYY')];
      localStorage.setItem('HEATMAP', JSON.stringify(draft));
      yield put(getMoodWorking(String(moment(data.data.to_date).format('YYYY-MM-DD'))));
      yield put(postHeatmapSuccess());
      yield put(setSuccess());
      Message.success('Edit success');
    } else {
      Message.error(message);
    }
  } catch (error) {
    Message.error(error.data.message);
    yield put(setEditFail(true));
  }
  yield put(setLoadingEditHeatmap(false));
}

function* deleteHistoryHeatmapSaga(action: any) {
  try {
    const { data } = action;
    const params = { project_id: data.project_id };
    const { message }: { data: []; message: string } = yield call(heatmapApi.deleteHeatmap, params);
    if (message === 'OK') {
      yield put(postHeatmapSuccess());
      yield put(setSuccess());
    } else {
      Message.error(message);
    }
  } catch (error) {
    Message.error(error.data.message);
  }
}

function* postHeatmapSaga(data: any) {
  try {
    const { message }: { data: []; message: string } = yield call(
      heatmapApi.postHeatmap,
      data.data
    );
    if (message === 'OK') {
      const ids = data.data.items.map((form: FormItemState) => form.project);
      handleRecent(ids);
      saveHeatmap({ id: 'lasted', date: '', items: data.data.items });
      const draft = loadDrafts('HEATMAP');
      delete draft['new'];
      localStorage.setItem('HEATMAP', JSON.stringify(draft));
      yield put(getMoodWorking(String(moment(data.data.date).format('YYYY-MM-DD'))));
      yield put(postHeatmapSuccess());
      yield put(dateChange(dayjs()));
      yield put(setSuccess());
      Message.success('Post success');
    } else {
      yield put(setError(message));
    }
    yield put(setLoadingPostHeatmap(false));
  } catch (error) {
    yield put(setError(error.data.message));
    yield put(setLoadingPostHeatmap(false));
  }
}

function* requestMoodWorking(action: any): any {
  const { payload } = action;
  try {
    const data = yield call(moodWorkingAPI.getMoodWorking, payload.date);
    if (data) {
      yield put({
        type: actionTypes.GET_MOOD_SUCCESS,
        payload: data.data
      });
    } else {
      Message.error('Get working day fail');
    }
  } catch (e) {
    console.log(e);
  }
}

function* removeHeatmapWatcher() {
  yield takeLatest(actionTypes.REMOVE_HEATMAP, removeHeatmap);
}

function* copyHeatmapWatcher() {
  yield takeLatest(actionTypes.COPY_HEATMAP, copyHeatmapSaga);
}

function* postHeatmapWatcher() {
  yield takeLatest(actionTypes.POST_HEATMAP, postHeatmapSaga);
}

function* editHistoryHeatmapWatcher() {
  yield takeLatest(actionTypes.EDIT_HISTORY_HEATMAP, editHistoryHeatmapSaga);
}

function* deleteHistoryHeatmapWatcher() {
  yield takeLatest(actionTypes.DELETE_HISTORY_HEATMAP, deleteHistoryHeatmapSaga);
}

function* watchRequestMoodWorking() {
  yield takeLatest(actionTypes.GET_MOOD_REQUEST, requestMoodWorking);
}

export default function* formSaga() {
  yield all([
    removeHeatmapWatcher(),
    copyHeatmapWatcher(),
    postHeatmapWatcher(),
    editHistoryHeatmapWatcher(),
    deleteHistoryHeatmapWatcher(),
    watchRequestMoodWorking()
  ]);
}
