import React from 'react';
import Link from 'next/link';
import {
  ScrollMenuContainer,
  ScrollMenuItem,
  ScrollMenuList,
  ScrollMenuWrapper
} from '@src/components/ScrollMenu/styled';
import { activitiPages } from '@src/common/constants';
import { useRouter } from 'next/router';

interface Props {
  visibility?: boolean;
  opacity?: number;
}

const ScrollMenu: React.FC<Props> = (props) => {
  const router = useRouter();

  return (
    <ScrollMenuContainer visibility={props.visibility} opacity={props.opacity}>
      <ScrollMenuWrapper>
        <ScrollMenuList>
          {activitiPages.map((item) => (
            <ScrollMenuItem key={item.name} isActive={router.asPath === item.path}>
              <Link href={item.path} legacyBehavior>
                {item.name}
              </Link>
            </ScrollMenuItem>
          ))}
        </ScrollMenuList>
      </ScrollMenuWrapper>
    </ScrollMenuContainer>
  );
};

export default ScrollMenu;
