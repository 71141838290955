import Modal from 'antd/lib/modal';
import { FC, useEffect, useState } from 'react';
import { signIn, signOut } from 'next-auth/react';

interface Props {
  isOpen: boolean;
}

const LogoutModal: FC<Props> = ({ isOpen }) => {
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setOpenModal(isOpen);
  }, [isOpen]);

  const reSignInHandler = async () => {
    const pathName = window.location.pathname;
    const data = await signOut({ redirect: false });

    if (data.url) {
      const notiFlag = localStorage.getItem('isShowNotiLog');
      const newsLocal = localStorage.getItem('DRAFT_NEWS');
      const announcementLocal = localStorage.getItem('DRAFT_ANNOUNCEMENT');
      const heatMap = localStorage.getItem('HEATMAP');
      const statusLocal = localStorage.getItem('DRAFT_STATUS');
      const pollLocal = localStorage.getItem('pollDraft');
      window.localStorage.clear();
      if (notiFlag) localStorage.setItem('isShowNotiLog', notiFlag);
      if (newsLocal) localStorage.setItem('DRAFT_NEWS', newsLocal);
      if (announcementLocal) localStorage.setItem('DRAFT_ANNOUNCEMENT', announcementLocal);
      if (heatMap) localStorage.setItem('HEATMAP', heatMap);
      if (statusLocal) localStorage.setItem('DRAFT_STATUS', statusLocal);
      if (pollLocal) localStorage.setItem('pollDraft', pollLocal);
      sessionStorage.setItem('first_time_check', 'true');
      const prevParams = {
        pathname: window.location.pathname,
        search: window.location.search
      };
      sessionStorage.setItem('prevParams', JSON.stringify(prevParams));
      localStorage.setItem('pathName', pathName);
      const params = localStorage.getItem('pathName');
      await signIn(
        'keycloak',
        {},
        {
          prompt: 'none',
          redirect_uri: encodeURI(
            `${window.location.origin}/signin?pathname=${
              params ? params : window.location.pathname
            }${window.location.search}`
          )
        }
      );
    }
  };

  return (
    <Modal
      visible={openModal}
      centered
      maskClosable={false}
      closable={false}
      footer={null}
      width={448}
      className="logout-modal">
      <p className="title">You are signed out</p>
      <p className="description">Your session has expired. Please sign in</p>
      <div className="signin">
        <button className="button-signin" onClick={() => reSignInHandler()}>
          Sign in
        </button>
      </div>
    </Modal>
  );
};

export default LogoutModal;
