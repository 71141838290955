import { Draf, DrafNewws } from '@src/interface/ICategoryTab';
import { IDataHeatMapDraft } from '@src/interface/IDataHeatmap';
import { IDataStatusLocal } from '@src/interface/IStatusState';

export const loadDrafts = (keyLocalStorage: string) => {
  const drafts = localStorage.getItem(`${keyLocalStorage}`);
  if (!drafts) return {};

  const parsedDrafts = JSON.parse(drafts);
  const oneWeekAgo = new Date().getTime() - 7 * 24 * 60 * 60 * 1000;

  const validDrafts = Object.keys(parsedDrafts).reduce((acc, key) => {
    if (!parsedDrafts[key].timestamp || parsedDrafts[key].timestamp > oneWeekAgo) {
      acc[key] = parsedDrafts[key];
    }
    return acc;
  }, {});

  localStorage.setItem(`${keyLocalStorage}`, JSON.stringify(validDrafts));
  return validDrafts;
};

export const saveDraftNews = ({
  id,
  title,
  content,
  category,
  organizer,
  location,
  dataFromTo
}: DrafNewws) => {
  const drafts = loadDrafts('DRAFT_NEWS');
  const timestamp = id === 'new' ? undefined : new Date().getTime();
  drafts[id] = { title, content, category, organizer, location, dataFromTo, timestamp };
  localStorage.setItem('DRAFT_NEWS', JSON.stringify(drafts));
};

export const saveDraftAnnouncement = ({ id, title, content }: Draf) => {
  const drafts = loadDrafts('DRAFT_ANNOUNCEMENT');
  const timestamp = id === 'new' ? undefined : new Date().getTime();
  drafts[id] = { title, content, timestamp };
  localStorage.setItem('DRAFT_ANNOUNCEMENT', JSON.stringify(drafts));
};

export const saveHeatmap = ({ id, date, items }: IDataHeatMapDraft) => {
  const drafts = loadDrafts('HEATMAP');
  const timestamp = id === 'new' || id === 'lasted' ? undefined : new Date().getTime();
  drafts[id] = { date, items, timestamp };
  localStorage.setItem('HEATMAP', JSON.stringify(drafts));
};

export const areHtmlEqual = (html1, html2) => {
  const parser = new DOMParser();
  const doc1 = parser.parseFromString(html1, 'text/html');
  const doc2 = parser.parseFromString(html2, 'text/html');
  return doc1.body.isEqualNode(doc2.body);
};

export const saveDraftStatus = ({ id, feeling, content, gif }: IDataStatusLocal) => {
  const drafts = loadDrafts('DRAFT_STATUS');
  const timestamp = id === 'new' ? undefined : new Date().getTime();
  drafts[id] = { feeling, content, gif, timestamp };
  localStorage.setItem('DRAFT_STATUS', JSON.stringify(drafts));
};
