import { css } from 'styled-components';
import { styled } from '@src/styled/Theme';
import { imgs } from '@src/static';

interface Props {
  alert?: boolean;
  url?: string;
  h?: number;
}

const Contain = styled.div`
  max-width: 500px;
  width: 100%;
  margin: auto;
`;

const Input = styled.input.attrs({
  placeholderTextColor: '#B8B8B8'
})<Props>`
  &:focus {
    border-color: ${(props) => props.theme.colors.dark[3]};
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  }
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  height: ${(props) => (props.h ? `${props.h / 16}rem` : '40px')};
  background-color: ${(props) => props.theme.colors.light[1]};
  border-color: ${(props) =>
    props.alert ? props.theme.colors.normal[1] : props.theme.colors.light[5]};
  font-size: ${(props) => props.theme.fontSizes[16]};
`;

const Select = styled.select<Props>`
  &:focus {
    border-color: ${(props) => props.theme.colors.dark[3]};
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  }
  height: 40px;
  width: 100%;
  padding-left: 10px;
  padding-right: 30px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  appearance: none;
  background-image: url('${imgs.SelectBg}');
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: right 15px center;
  background-color: ${(props) => props.theme.colors.light[1]};
  border-color: ${(props) =>
    props.alert ? props.theme.colors.normal[1] : props.theme.colors.light[5]};
  font-size: ${(props) => props.theme.fontSizes[16]};
  option {
    &:disabled {
      color: ${(props) => props.theme.colors.dark[6]};
    }
  }
`;
const Search = styled(Input)`
  padding-right: 60px;
  &:focus {
    border-color: ${(props) => props.theme.colors.dark[3]};
    box-shadow: none;
  }
`;
const BtnSearch = styled.div`
  cursor: pointer;
  user-select: none;
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  width: 60px;
  background-image: url('${imgs.SearchBg}');
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: center;
`;
const SearchContainer = styled.div`
  position: relative;
`;

const InputStatus = styled.textarea`
  border: none;
  outline: none;
  width: 100%;
  resize: none;
  margin-top: 20px;
`;
const CommentInput = styled.input`
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 6px;
  width: 100%;
  outline: none;
  height: 36px;
  margin-left: 12px;
  padding: 6px 15px;
`;
const baseTitleArea = css`
  padding: 10px;
  border-radius: 4px;
  white-space: pre-wrap;
  min-height: 100px;
  width: 100%;
`;
const TitleArea = styled.div`
  width: 100%;
  position: relative;
  font-size: ${(props) => props.theme.fontSizes[24]};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  div {
    ${baseTitleArea}
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
  }
  textarea {
    ${baseTitleArea}
    z-index: 2;
    border-width: 1px;
    border-style: solid;
    resize: none;
    border-color: ${(props) => props.theme.colors.light[5]};
    &:focus {
      outline: none;
    }
  }
`;
const SigninContainer = styled.div`
  max-width: 378px;
  margin-bottom: 90px;
  .form-item {
    &-input {
      margin-bottom: 16px;
      &-un-space {
        margin-bottom: 0;
      }
    }
    &-tails {
      margin-top: 48px;
    }
  }
  .ant-form-item-children-icon {
    right: -30px !important;
  }
`;

const ImageUploadButton = styled.input`
  opacity: 0;
  z-index: 2;
  position: absolute;
  left: 0;
  width: 32px;
`;

export const EditInformationUser = styled.div`
  .ant-form-item-label > label {
    padding-top: 5px;
    padding-right: 60px;
    font-weight: 600;
    font-size: 16px !important;
  }

  .input-form-user {
    padding: 8px 13px 8px 13px;
    min-width: 480px;
  }

  .ant-radio-inner::after {
    background-color: #060606;
  }
  .ant-radio-group {
    padding-top: 10px;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #060606;
  }
  .ant-radio-checked::hover {
    border-color: #060606;
  }
  .ant-radio-inner::hover {
    border-color: #060606 !important;
    border: none !important;
  }
  .item-gender {
    margin-top: -10px;
  }
  .ant-form-item-label > label::after {
    content: '' !important;
  }
  .ant-form-item-required::after {
    content: '*' !important;
    position: relative;
    top: -0.5px;
    right: 0px;
    margin: 0 8px 0 2px;
    color: #ff4d4f;
    font-size: 14px;
  }
`;

const SearchInput = styled.span`
  .ant-input-affix-wrapper {
    background-color: #f5f5f5;
    border: none;

    .ant-input-lg {
      font-size: 12px;
    }
  }
  .ant-input-suffix {
    position: absolute;
    left: -10px;
    top: 12px;
  }
  .ant-input {
    padding-left: 25px !important;
    background-color: inherit;
  }
  .ant-select-clear {
    font-size: 16px;
    opacity: 1;
  }
  width: 100%;
`;

const FormLabelText = styled.span`
  font-size: 16px;
  color: #8b8b8b;
`;

const BtnSignIn = styled.div`
  margin: auto;
  cursor: pointer;
  padding: 0;
  border-radius: 5px;
  background-color: #575757;
  font-size: size(13);
  font-weight: 700;
  color: #fff;
  border: none;
  max-width: 250px;
  width: 100%;
  transition: all ease 0.15s;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  display: flex;
  align-items: center;
  gap: size(48);

  img {
    width: 48px;
    height: 48px;
    padding: 8px;
    background-color: #fff;
    border: 1px solid #575757 !important;
    border-radius: 5px !important;
    object-fit: contain;
  }
  p {
    margin: 0 30px;
  }
  &:hover {
    background-color: #1a1a1a;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-1px);
  }
  &:disabled {
    opacity: 0.6;
    background-color: #575757;
    box-shadow: none;
    transform: translateY(0);
    cursor: not-allowed;
  }
  &:active {
    box-shadow: none;
    transform: translateY(0);
  }
`;
export default {
  Contain,
  Input,
  Select,
  Search,
  BtnSearch,
  SearchContainer,
  TitleArea,
  InputStatus,
  CommentInput,
  SigninContainer,
  ImageUploadButton,
  SearchInput,
  FormLabelText,
  BtnSignIn
};
