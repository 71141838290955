import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { watchSetForceLogout } from '@src/components/LogoutModal/forceLogoutSaga';
import forceLogoutReducer from '@src/components/LogoutModal/forceLogoutReducer';

const sagaMiddleware = createSagaMiddleware();

const storeLogout = createStore(forceLogoutReducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(watchSetForceLogout);

export default storeLogout;
