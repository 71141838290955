import { appAxios } from '@src/api/appAxios';
import { AxiosResponse } from 'axios';
import handleError from './handleError';
export const ThanksApi = {
  getListThanks: async (page: number, limit: number) => {
    try {
      const urlWithParams = new URL('/points/v2/transactions/', process.env.NEXT_PUBLIC_BASE_URL);

      urlWithParams.searchParams.append('page', page.toString());
      urlWithParams.searchParams.append('limit', limit.toString());
      urlWithParams.searchParams.append('receiver_type', 'User');
      urlWithParams.searchParams.append('sender_type', 'User');

      const { data }: AxiosResponse = await appAxios.get(urlWithParams.href);

      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);

      throw error;
    }
  }
};
