/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { appAxios } from '@src/api/appAxios';
import { AxiosResponse } from 'axios';
import handleError from './handleError';
export const detailAPI = {
  getDataInfor: async (typeDetail: string, idPost: number) => {
    try {
      const { data }: AxiosResponse = await appAxios.get(`posts/v2/${typeDetail}/` + idPost);
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
    }
  },
  getListComment: async (idPost: number, pageNumber: number) => {
    const params = {
      commentable_id: idPost,
      commentable_type: 'Post',
      page: pageNumber,
      limit: 20, //number limit of page
      sort_by: 'created_at',
      order_by: 'desc'
    };
    try {
      const { data }: AxiosResponse = await appAxios.get(`posts/v2/comments`, { params });
      if (data && data.code === 200) {
        const listCommentPosts: Array<any> = data.data.comments;
        return listCommentPosts.reverse();
      } else return false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
    }
  },
  postCommentTextPost: async (idPost: number, text: string, urlGif: string, idImg: number[]) => {
    const dataComment = {
      commentable_id: Number(idPost),
      content: text,
      giphy: urlGif,
      image_ids: idImg,
      file_ids: [],
      hashtags: [],
      mentions: []
      // "point": {
      //   "value": 1
      // }
    };
    try {
      const { data }: AxiosResponse = await appAxios.post(`posts/v2/comments`, dataComment);
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
    }
  },
  putEditComment: async (idCmt: number, dataComment: any) => {
    try {
      const { data }: AxiosResponse = await appAxios.put(`posts/v2/comments/${idCmt}`, dataComment);
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
    }
  },
  likeComment: async (idCmt: number, like: boolean) => {
    try {
      const { data }: AxiosResponse = like
        ? await appAxios.post(`posts/v2/comments/${idCmt}/like`)
        : await appAxios.delete(`posts/v2/comments/${idCmt}/like`);
      return data && data.code === 200 ? data.data || {} : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
    }
  },
  postReadPosts: async (idPost: number) => {
    try {
      const { data }: AxiosResponse = await appAxios.post(`posts/v2/posts/${idPost}/read`);
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
    }
  },
  postReactPosts: async (setLiked: boolean, idPost: number) => {
    const isLike = setLiked ? 'like' : 'dislike';
    try {
      const { data }: AxiosResponse = await appAxios.post(`posts/v2/posts/${idPost}/` + isLike);
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
    }
  },
  postBookmarkPosts: async (setBookmarkPost: boolean, idPost: number) => {
    const isBookmark = setBookmarkPost ? 'bookmark' : 'unbookmark';
    try {
      const { data }: AxiosResponse = await appAxios.post(`posts/v2/posts/${idPost}/` + isBookmark);
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
    }
  },
  getListInteractive: async (idPosts: number, typeInteractive: string) => {
    try {
      const { data }: AxiosResponse = await appAxios.get(
        `posts/v2/posts/${idPosts}/${typeInteractive}/list`
      );
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
    }
  },
  getListTransaction: async (idPosts: number) => {
    const params = {
      post_id: idPosts
    };
    try {
      const { data }: AxiosResponse = await appAxios.get(`points/v1/transactions`, { params });
      return data && data.code === 200 ? data.data.transactions : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
    }
  }
};
