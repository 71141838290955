export const actionTypes = {
  GET_LIST_REWARD_REQUEST: '@@GET_LIST_REWARD_REQUEST',
  GET_LIST_REWARD_SUCCESS: '@@GET_LIST_REWARD_SUCCESS',
  GET_LIST_REWARD_ERROR: '@@GET_LIST_REWARD_ERROR',

  REQUEST_DATA_REWARD_DETAIL: 'REQUEST_DATA_REWARD_DETAIL',
  GET_DATA_REWARD_DETAIL_BY_ID: 'GET_DATA_REWARD_DETAIL_BY_ID',
  GET_DATA_REWARD_DETAIL_SUCCESS: 'GET_DATA_REWARD_DETAIL_SUCCESS',
  GET_DATA_REWARD_DETAIL_FAIL: 'GET_DATA_REWARD_DETAIL_FAIL',
  POST_REDEEM_REWARD_DETAIL: 'POST_REDEEM_REWARD_DETAIL',
  POST_REDEEM_REWARD_DETAIL_SUCCESS: 'POST_REDEEM_REWARD_DETAIL_SUCCESS',
  POST_REDEEM_REWARD_DETAIL_FALL: 'POST_REDEEM_REWARD_DETAIL_FALL'
};

export const requestListReward = () => ({
  type: actionTypes.GET_LIST_REWARD_REQUEST
});
