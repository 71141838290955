import { AxiosResponse } from 'axios';
import { appAxios } from '@src/api/appAxios';
import handleError from './handleError';
export const HashTagApi = {
  getListHashTag: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get(`posts/v2/tags/news`);
      return data.code === 200 && data.data ? data.data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      // throw error;
    }
  }
};
