import type { FC } from 'react';
import IconHoneybeeFeedback from '@src/static/images/icon_honeybee_tele.svg';
import { FeedbackText, FeedbackTooltip } from '@src/styled/Component/Feedback';
import IconTriangle from '@src/static/images/icon_triangle.svg';
import Link from 'next/link';
interface Props {
  isDisplay: boolean;
}
const FeedbackIcon: FC<Props> = ({ isDisplay }) => {
  return (
    <FeedbackTooltip id="feedbackTooltip" display={isDisplay}>
      <Link href="/feedback" legacyBehavior>
        <img src={IconHoneybeeFeedback} alt="honey-feedback" />
      </Link>
      <div className="feedback">
        <FeedbackText>
          <img src={IconTriangle} alt="arrow-left" />
          Give us your feedback
        </FeedbackText>
      </div>
    </FeedbackTooltip>
  );
};
export default FeedbackIcon;
