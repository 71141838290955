import styled from 'styled-components';

interface Props {
  active?: boolean;
}

const Menu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  user-select: none;
  & li:first-child {
    margin-left: 20px;
  }
`;

const Item = styled.div<Props>`
  margin: 0 5px;
  color: white;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  padding: 0 10px;
  border-radius: 5px;
  ${(props: Props) => (props.active ? `background-color: white; color: #1A1A1A;` : ``)}
  &:hover {
    background-color: white;
    color: #1a1a1a;
  }
`;

const PageLinks = styled.a`
  margin: 0 5px;
  color: white;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  padding: 0 10px;
  border-radius: 5px;
  &:hover {
    background-color: white;
    color: #1a1a1a;
  }
`;

const WrapContentMenu = styled.div`
  display: flex;
  justify-content: space-between;
  & #left-menu {
    display: flex;
    align-items: center;
  }
  & #right-menu {
    display: flex;
    align-items: flex-end;
    color: white;
    fontsize: 14px;
    fontweight: 400;
  }
`;

export default { Menu, Item, PageLinks, WrapContentMenu };
