import React from 'react';
import {
  BubbleMessage,
  CartoonCharacter,
  CongratulationMessage,
  MessageContainer,
  MessageContent,
  Typing
} from '../styled/Bot';
import { imgs } from '@src/static';

interface Props {
  userName: string | null;
  wishes: string | null;
  bubbleMessageImg: string;
  characterCartoonImg: string;
  link: string;
  isTyping: boolean;
  messageImg: string;
}

const Message: React.FC<Props> = (props) => {
  return (
    <>
      <MessageContainer>
        <CongratulationMessage className={'message'} src={props.bubbleMessageImg}>
          {props.link ? (
            <MessageContent href={props.link} target={'_blank'}>
              {props.messageImg ? (
                <BubbleMessage src={props.messageImg} alt="" />
              ) : props.wishes ? (
                <>
                  {props.wishes} <br />
                  {props.userName} !
                </>
              ) : (
                <div className={'empty'}>Hi !</div>
              )}
            </MessageContent>
          ) : (
            <MessageContent>
              {props.messageImg ? (
                <BubbleMessage src={props.messageImg} alt="" />
              ) : props.wishes ? (
                <>
                  {props.wishes} <br />
                  {props.userName} !
                </>
              ) : (
                <div className={'empty'}>Hi !</div>
              )}
            </MessageContent>
          )}
        </CongratulationMessage>
        <Typing className={'typing'} src={imgs.TypingImage} alt="" hidden={!props.wishes} />
        <CartoonCharacter src={props.characterCartoonImg} />
      </MessageContainer>
    </>
  );
};

export default Message;
