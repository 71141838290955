import { actionTypes } from './historyAction';
import { HistoryState } from 'src/interface/IHistoryState';

const initialState: HistoryState = {
  historyList: [],
  isPending: false,
  isClick: false
};

export const historyReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.GET_HISTORY:
      return { ...state, loading: true };
    case actionTypes.GET_HISTORY_PENDING:
      return { ...state, authen: action.result, loading: false };
    case actionTypes.GET_HISTORY_SUCCESS:
      return { ...state, authen: 'false', loading: false };
    case actionTypes.CLICK_SEE_ALL:
      return { ...state, isClick: true };
    case actionTypes.CLICK_HIDDEN_ITEM:
      return { ...state, isClick: false };
    case actionTypes.HISTORY_HEATMAP:
      return { ...state, historyList: action.data, isPending: true };
    case actionTypes.HISTORY_HEATMAP_SUCCESS:
      return { ...state, historyList: action.data, isPending: false };
    default:
      return state;
  }
};
