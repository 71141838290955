import { actionTypes } from './templateAction';
import { IListTemplate } from '@src/interface/ITemplate';

const initialState: IListTemplate = {
  data: [],
  isLoading: false,
  isLoadingUpdate: false
};

export function templateReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_LIST_TEMPLATE_REQUEST: {
      return { ...state, isLoading: true };
    }
    case actionTypes.GET_LIST_TEMPLATE_SUCCESS: {
      return { ...state, data: action.payload, isLoading: false };
    }
    case actionTypes.GET_LIST_TEMPLATE_FAIL: {
      return {
        ...state,
        isLoading: false
      };
    }

    case actionTypes.CREATE_TEMPLATE_REQUEST: {
      return { ...state, isLoading: true, isLoadingUpdate: true };
    }
    case actionTypes.CREATE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        data: [...state.data, action.payload],
        isLoading: false,
        isLoadingUpdate: false
      };
    }
    case actionTypes.CREATE_TEMPLATE_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoadingUpdate: false
      };
    }

    case actionTypes.DELETE_TEMPLATE_SUCCESS: {
      const newArr = state.data.filter((item) => item.id !== action.id);
      return { ...state, data: newArr, isLoading: false };
    }

    default:
      return state;
  }
}
