import dayjs from 'dayjs';
import { actionTypes } from './formAction';
import { FormState, FormItemState } from 'src/interface/IFormState';
import moment from 'moment';

const initFormState: FormState = {
  form: [
    {
      id: 'default',
      isPassed: false,
      isVisible: true,
      project: '',
      activity: '',
      hour: null,
      order: 999,
      isCopy: false
    }
  ],
  formIndex: 1,
  date: dayjs(),
  lastedPost: null,
  dataMood: [],
  openMoodModal: false,
  loadingPostHeatmap: false,
  loadingEditHeatmap: false,
  editFail: false,
  moodDate: moment()
};

const initValue = () => {
  return {
    id: 'default',
    isPassed: false,
    isVisible: true,
    project: undefined,
    activity: '',
    hour: null,
    order: 999
  };
};

const formReducer = (state: FormState = initFormState, action: any) => {
  switch (action.type) {
    case actionTypes.HEATMAP_ADD: {
      const old = state.form.map((item) => {
        if (item.id === 'default') {
          return initValue();
        }
        return item;
      });
      return {
        ...state,
        form: [...old, action.data]
      };
    }
    case actionTypes.SET_FORM_INDEX:
      return {
        ...state,
        formIndex: action.idx
      };
    case actionTypes.HEATMAP_EDIT_FORM: {
      const { index } = action;
      return {
        ...state,
        form: state.form.map((item: FormItemState) => {
          if (item.id !== index) return item;
          return {
            ...item,
            isVisible: !item.isVisible
          };
        })
      };
    }

    case actionTypes.HEATMAP_CHANGE: {
      const { data } = action;
      return {
        ...state,
        form: state.form.map((item) => {
          if (item.id === data.id) return data;
          return item;
        })
      };
    }
    case actionTypes.DATE_CHANGE: {
      return {
        ...state,
        date: action.data
      };
    }
    case actionTypes.REMOVE_HEATMAP_SUCCESS: {
      return {
        ...state,
        form: action.data
      };
    }
    case actionTypes.RESET_FORM: {
      const reset = state.form.map((item) => {
        if (item.id === 'default') {
          return initValue();
        }
        return item;
      });
      return {
        ...state,
        date: dayjs(),
        form: [...reset]
      };
    }
    case actionTypes.COPY_HEATMAP_SUCCESS: {
      return {
        ...state,
        form: action.data
      };
    }
    case actionTypes.POST_HEATMAP_SUCCESS: {
      return {
        ...state,
        date: dayjs(),
        form: [initValue()],
        lastedPost: new Date()
      };
    }
    case actionTypes.EDIT_HISTORY_HEATMAP: {
      const { data } = action;
      return {
        ...state,
        form: state.form.map((item: FormItemState) => {
          if (item.id !== data.id) return item;
          return {
            ...item,
            isVisible: !item.isVisible,
            activity: item.activity,
            hours: item.hours,
            date: item.date
          };
        })
      };
    }
    case actionTypes.GET_MOOD_REQUEST: {
      return { ...state, openMoodModal: false };
    }
    case actionTypes.GET_MOOD_SUCCESS: {
      return { ...state, dataMood: action.payload, openMoodModal: !action.payload };
    }

    case actionTypes.SET_MODAL_REQUEST: {
      return { ...state, openMoodModal: action.payload };
    }
    case actionTypes.SET_LOADING_POST_HEATMAP: {
      return { ...state, loadingPostHeatmap: action.payload };
    }
    case actionTypes.SET_EDIT_FAIL: {
      return { ...state, editFail: action.payload };
    }
    case actionTypes.SET_DATE_MOOD: {
      return { ...state, moodDate: action.payload };
    }
    case actionTypes.SET_LOADING_EDIT_HEATMAP: {
      return { ...state, loadingEditHeatmap: action.payload };
    }
    default:
      return state;
  }
};

export default formReducer;
