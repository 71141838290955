/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { Menu as M } from '@src/styled/Component';
import Layout from 'antd/lib/layout';
import { LogoIcon } from '@src/components/Icons';

const RightSideHeaderClient = dynamic(
  () => import('@src/components/Header/RightSideHeaderClient'),
  { ssr: false }
);

const menu = [
  {
    key: 1,
    name: 'Dashboard',
    link: '/'
  },
  {
    key: 2,
    name: 'Heatmap',
    link: '/heatmap'
  },
  {
    key: 3,
    name: 'Activities',
    link: '/activity/announcements'
  },
  {
    key: 4,
    name: 'Profile',
    link: '/profile'
  },
  {
    key: 5,
    name: 'Q&A',
    link: '/qa'
  }
];

const pageLinks = [
  {
    key: 1,
    name: 'TEQID',
    link: 'https://id.teqn.asia'
  },
  {
    key: 2,
    name: 'Rules',
    link: 'https://rules.i.teqn.asia/'
  },
  {
    key: 3,
    name: 'Handbook',
    link: 'https://handbook.i.teqn.asia/'
  }
];

const Header: React.FC = () => {
  const router = useRouter();
  const { pathname } = router;

  const renderMenu = () => {
    return menu.map((item) => {
      return (
        <M.Item
          key={item.key}
          active={pathname === item.link || (pathname.includes('/activity') && item.key === 3)}
          onClick={() => router.push(item.link)}>
          {item.name}
        </M.Item>
      );
    });
  };

  const renderPageLinks = () => {
    return pageLinks.map((item) => {
      return (
        <M.PageLinks key={item.key} href={item.link} target="_blank">
          {item.name}
        </M.PageLinks>
      );
    });
  };

  return (
    // @ts-ignore
    <Layout.Header
      style={{
        backgroundColor: '#1A1A1A',
        padding: '16px 9%',
        lineHeight: '32px'
      }}>
      <M.WrapContentMenu>
        <div id={'left-menu'}>
          <Link href="/" style={{ display: 'flex', marginRight: '30px' }}>
            <LogoIcon />
          </Link>
          <M.Menu>
            {renderMenu()}
            {renderPageLinks()}
          </M.Menu>
        </div>
        <div id={'right-menu'}>
          {/* @ts-ignore */}
          <RightSideHeaderClient />
        </div>
      </M.WrapContentMenu>
    </Layout.Header>
  );
};

export default Header;
