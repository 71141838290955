export const SELECT_VIDEO_COMMENT = 'SELECT_VIDEO_COMMENT';
export const DELETE_VIDEO_COMMENT = 'DELETE_VIDEO_COMMENT';

export const selectVideoComment = (file: any, id: number) => ({
  type: SELECT_VIDEO_COMMENT,
  file: file,
  id: id
});

export const deleteVideoComment = () => ({
  type: DELETE_VIDEO_COMMENT
});
