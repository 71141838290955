import { IBreadcrumb } from 'src/interface/IBreadcrumb';

export const actionTypes = {
  SET_LOADING: 'SET_LOADING',
  SET_SUCCESS: 'SET_SUCCESS',
  SET_ERROR: 'SET_ERROR',
  RESET_ERROR: 'RESET_ERROR',
  SET_BREADCRUMB: 'SET_BREADCRUMB',
  SET_NO_LOADING: 'SET_NO_LOADING',
  SET_FIRST_LOADING_DONE: 'SET_FIRST_LOADING_DONE'
};

export const setLoading = () => ({
  type: actionTypes.SET_LOADING
});

export const setNoLoading = () => ({
  type: actionTypes.SET_NO_LOADING
});

export const setSuccess = () => ({
  type: actionTypes.SET_SUCCESS
});
export const setError = (message?: string) => ({
  type: actionTypes.SET_ERROR,
  message
});
export const resetError = () => ({
  type: actionTypes.RESET_ERROR
});
export const setBreadcrumb = (breadcrumb: Array<IBreadcrumb>) => ({
  type: actionTypes.SET_BREADCRUMB,
  breadcrumb
});
export const setFirstLoadingDone = () => ({
  type: actionTypes.SET_FIRST_LOADING_DONE
});
