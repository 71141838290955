import { AxiosResponse } from 'axios';
import { all, takeLatest, put, call } from 'redux-saga/effects';

import { actionTypes } from '@src/activities/activitiesAction';
import { TopApi } from '@src/api/TopApi';
import { NotificationApi } from '@src/api/notificationsApi';
function* requestDataTopGiven() {
  try {
    const data: AxiosResponse = yield call(TopApi.getTopGiven);
    if (data) {
      yield put({ type: actionTypes.GET_DATA_POINT_GIVEN_SUCCESS, data });
    } else {
      yield put({ type: actionTypes.GET_DATA_POINT_GIVEN_FALL });
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_DATA_POINT_GIVEN_FALL });
  }
}

function* watchRequestTopGiven() {
  yield takeLatest(actionTypes.REQUEST_DATA_POINT_GIVEN, requestDataTopGiven);
}
function* requestDataTopReceiver() {
  try {
    const data: AxiosResponse = yield call(TopApi.getTopReceivers);
    if (data) {
      yield put({ type: actionTypes.GET_DATA_POINT_RECEIVER_SUCCESS, data });
    } else {
      yield put({ type: actionTypes.GET_DATA_POINT_RECEIVER_FALL });
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_DATA_POINT_RECEIVER_FALL });
  }
}

function* requestUsers(): any {
  try {
    const data: AxiosResponse = yield call(TopApi.getUsers);
    if (data) {
      yield put({
        type: actionTypes.GET_USERS_SUCCESS,
        payload: data
      });
    }
  } catch (e) {
    console.log(e);
  }
}

function* watchRequestTopReceiver() {
  yield takeLatest(actionTypes.REQUEST_DATA_POINT_RECEIVER, requestDataTopReceiver);
}
function* requestDataTopHashTag() {
  try {
    const data: AxiosResponse = yield call(TopApi.getTopHashTag);
    if (data) {
      yield put({ type: actionTypes.GET_DATA_POINT_HASHTAG_SUCCESS, data });
    } else {
      yield put({ type: actionTypes.GET_DATA_POINT_HASHTAG_FALL });
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_DATA_POINT_HASHTAG_FALL });
  }
}
function* requestCountNotiTab() {
  try {
    const count: AxiosResponse = yield call(NotificationApi.countNotiTab);
    if (count) {
      yield put({ type: actionTypes.GET_COUNT_NOTI_TAB_SUCCESS, count });
    } else {
      yield put({ type: actionTypes.GET_COUNT_NOTI_TAB_FALL });
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_COUNT_NOTI_TAB_FALL });
  }
}

function* watchRequestCountNoti() {
  yield takeLatest(actionTypes.REQUEST_COUNT_NOTI_TAB, requestCountNotiTab);
}
function* watchRequestTopHashTag() {
  yield takeLatest(actionTypes.REQUEST_DATA_POINT_HASHTAG, requestDataTopHashTag);
}

function* watchRequestUsers() {
  yield takeLatest(actionTypes.GET_USERS, requestUsers);
}

export function* activitiesSaga() {
  yield all([
    watchRequestTopGiven(),
    watchRequestTopReceiver(),
    watchRequestTopHashTag(),
    watchRequestCountNoti(),
    watchRequestUsers()
  ]);
}
