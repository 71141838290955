import { all } from 'redux-saga/effects';
import { headerSaga } from '@src/components/Header/headerSaga';
import { eventSaga } from '@src/components/events/eventSaga';
import { chartSaga } from '@src/components/Chart/chartSaga';
import { signinSaga } from '@src/components/signin/signinSaga';
import formSaga from '@src/components/Heatmap/form/formSaga';
import { notificationSaga } from '@src/notifications/notificationSaga';
import { chartProfileSaga } from '@src/profile/ProfileSaga';
import { otherProfileSaga } from '@src/profile/OtherProfile/OtherProfileSaga';
import { forgotPasswordSaga } from 'src/forgotPassword/forgotPasswordSaga';
import { resetPasswordSaga } from 'src/resetPassword/resetPasswordSaga';
import { activitiesSaga } from '@src/activities/activitiesSaga';
import { announcementsSaga } from '@src/activities/announcements/announcementsSaga';
import { searchheaderSaga } from '@src/activities/header/searchheaderSaga';
import { newsSaga } from '@src/activities/news/newsSaga';
import { detailSaga } from '@src/activities/NewsEventDetail/DetailSaga';
import { pollSaga } from '@src/activities/poll/pollSaga';
import { statusSaga } from '@src/activities/status/statusSaga';
import { interactiveSaga } from '@src/components/CardLike/listUserLikeSaga';
import { commentStatusSaga } from '@src/components/CommentStatus/commentStatusSaga';
import { hashtagRecommentSaga } from '@src/components/SlideHashTag/recommentHashtagSaga';
import { giphySaga } from '@src/components/Giphy/giphySaga';
import { imageCommentSaga } from '@src/components/ImageComment/imageCommentSaga';
import { categoryTabSaga } from 'src/components/CategoryTab/categoryTabSaga';
import { createSaga } from 'src/activities/create/createSaga';
import { thanksSaga } from 'src/activities/thanks/thanksSaga';
import { rewardSaga } from 'src/reward/rewardSaga';
import { lateOffDaySaga } from '@src/components/LateOffDay/lateOffDaySaga';
import { dashboardSaga } from '@src/dashboard/dashboardSaga';
import { chatGptSaga } from '@src/chatgpt/chatGptSaga';
import { favoriteProjectsSaga } from '@src/components/Heatmap/project/favoriteProjectsSaga';
import { templateSaga } from '@src/components/Heatmap/template/templateSaga';
import historySaga from '@src/components/Heatmap/history/historySaga';
export function* appSaga() {
  yield all([
    headerSaga(),
    signinSaga(),
    eventSaga(),
    chartSaga(),
    formSaga(),
    notificationSaga(),
    chartProfileSaga(),
    otherProfileSaga(),
    forgotPasswordSaga(),
    resetPasswordSaga(),
    activitiesSaga(),
    announcementsSaga(),
    searchheaderSaga(),
    newsSaga(),
    detailSaga(),
    pollSaga(),
    statusSaga(),
    interactiveSaga(),
    commentStatusSaga(),
    hashtagRecommentSaga(),
    giphySaga(),
    imageCommentSaga(),
    categoryTabSaga(),
    createSaga(),
    rewardSaga(),
    thanksSaga(),
    lateOffDaySaga(),
    dashboardSaga(),
    chatGptSaga(),
    favoriteProjectsSaga(),
    templateSaga(),
    historySaga()
  ]);
}
