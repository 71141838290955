import styled from 'styled-components';

interface IVideoStatus {
  playIcon?: string;
}

interface IBackgroundProps {
  src?: string;
  alt?: string;
}

const VideoStatus = styled.div<IVideoStatus>`
  position: relative;
  text-align: center;
  overflow: hidden;
  margin-top: 16px;

  .video-js {
    max-width: 100% !important;
    background-color: transparent !important;

    .vjs-poster {
      background-color: transparent;
    }

    .vjs-big-play-button {
      border: none !important;
      background-color: transparent !important;
      width: 64px !important;
      height: 64px !important;

      .vjs-icon-placeholder:before {
        content: '';
        background-image: url('${({ playIcon }) => playIcon}');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 55% calc(50% - 0px);
        border: none !important;
        box-shadow: none !important;
      }
    }
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    filter: blur(15px);
    object-fit: cover;
    z-index: 0;
  }
`;

const VideoBgThumbnail = styled.img<IBackgroundProps>`
  src: ${({ src }) => (src ? src : '')};
  alt: ${({ alt }) => (alt ? alt : '')};
  background-color: ${({ src }) => (src ? '' : 'rgba(0, 0, 0, 0.6)')};
`;

const EmbedVideoContainer = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  iframe {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
    position: absolute;
  }
`;

export default {
  VideoStatus,
  VideoBgThumbnail,
  EmbedVideoContainer
};
