export const actionTypes = {
  IMPORT_IMG_UPLOAD: 'IMPORT_IMG_UPLOAD',
  SAVE_IMG_UPLOAD: 'SAVE_IMG_UPLOAD',
  IMPORT_DATA_CREATE: 'IMPORT_DATA_CREATE',
  PREVIEW_DATA_CREATE: 'PREVIEW_DATA_CREATE',
  PREVIEW_DATA_CLOSE: 'PREVIEW_DATA_CLOSE',
  SEND_DATA_CREATE_ANNOUNCEMENT: 'SEND_DATA_CREATE_ANNOUNCEMENT',
  SEND_DATA_CREATE_ANNOUNCEMENT_SUCCESS: 'SEND_DATA_CREATE_ANNOUNCEMENT_SUCCESS',
  SEND_DATA_CREATE_ANNOUNCEMENT_FALL: 'SEND_DATA_CREATE_ANNOUNCEMENT_FALL',
  SEND_DATA_CREATE_NEW: 'SEND_DATA_CREATE_NEW',
  SEND_DATA_CREATE_NEW_SUCCESS: 'SEND_DATA_CREATE_NEW_SUCCESS',
  SEND_DATA_CREATE_NEW_FALL: 'SEND_DATA_CREATE_NEW_FALL',
  SEND_DATA_UPDATE_NEWS: 'SEND_DATA_UPDATE_NEWS',
  SEND_DATA_UPDATE_NEWS_SUCCESS: 'SEND_DATA_UPDATE_NEWS_SUCCESS',
  SEND_DATA_UPDATE_NEWS_FAILED: 'SEND_DATA_UPDATE_NEWS_FAILED',
  SEND_DATA_UPDATE_ANNOUNCEMENT: 'SEND_DATA_UPDATE_ANNOUNCEMENT',
  SEND_DATA_UPDATE_ANNOUNCEMENT_SUCCESS: 'SEND_DATA_UPDATE_ANNOUNCEMENT_SUCCESS',
  SEND_DATA_UPDATE_ANNOUNCEMENT_FAILED: 'SEND_DATA_UPDATE_ANNOUNCEMENT_FAILED'
};

export const actionImgUpLoad = (file: any) => ({
  type: actionTypes.IMPORT_IMG_UPLOAD,
  file
});
export const actionSendDataCreateNewReady = (requestData: { [key: string]: any }) => ({
  type: actionTypes.IMPORT_IMG_UPLOAD,
  requestData
});
export const actionImportDataReview = (requestData: { [key: string]: any }) => ({
  type: actionTypes.IMPORT_DATA_CREATE,
  requestData
});
export const actionSendDataCreateAnnouncement = (
  requestData: { [key: string]: any },
  fileImg: []
) => ({
  type: actionTypes.SEND_DATA_CREATE_ANNOUNCEMENT,
  requestData,
  fileImg
});
export const actionSendDataCreateNew = (requestData: { [key: string]: any }, fileImg: []) => ({
  type: actionTypes.SEND_DATA_CREATE_NEW,
  requestData,
  fileImg
});
export const actionSendDataToUpdateNews = (
  id: number,
  image_id: number,
  requestData: { [key: string]: any },
  fileImg: []
) => ({
  type: actionTypes.SEND_DATA_UPDATE_NEWS,
  id,
  image_id,
  requestData,
  fileImg
});

export const actionSendDataToUpdateAnnouncement = (
  id: number,
  image_id: number,
  requestData: { [key: string]: any },
  fileImg: []
) => ({
  type: actionTypes.SEND_DATA_CREATE_ANNOUNCEMENT,
  id,
  image_id,
  requestData,
  fileImg
});
