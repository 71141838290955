import { IKeycloakSession } from '@src/interface/IKeyCloak';
import axios, { AxiosError } from 'axios';
import { JWT } from 'next-auth/jwt';
import { signOut } from 'next-auth/react';
import jwt_decode from 'jwt-decode';

export const kcIssuer = `${process.env.NEXT_PUBLIC_KEYCLOAK_SERVER_URL}/realms/${process.env.NEXT_PUBLIC_KEYCLOAK_REALMS}`;
export const kcClientId = process.env.NEXT_PUBLIC_KEYCLOAK_CLIENT_ID as string;
export const kcClientSecret = (process.env.NEXT_PUBLIC_KEYCLOAK_CLIENT_SECRET ||
  'anything') as string;
export const LOGOUT_URL = `${kcIssuer}/protocol/openid-connect/logout?client_id=${kcClientId}`;

export const logoutKeycloak = async (session: IKeycloakSession | null): Promise<void> => {
  if (session && session.id_token) {
    sessionStorage.setItem('first_time_check', 'true');
    await signOut({ redirect: false });
    window.location.href = `${LOGOUT_URL}&id_token_hint=${
      session.id_token
    }&post_logout_redirect_uri=${encodeURIComponent(`${window.location.origin}/signin`)}`;
  } else {
    await signOut({ callbackUrl: '/signin' });
  }
};

export const refreshAccessToken = async (token: JWT): Promise<JWT> => {
  const bodyParams = new URLSearchParams({
    client_id: process.env.NEXT_PUBLIC_KEYCLOAK_CLIENT_ID || '',
    client_secret: process.env.NEXTAUTH_SECRET || '',
    grant_type: 'refresh_token',
    refresh_token: token.refresh_token as string
  });

  const resp = await fetch(`${kcIssuer}/protocol/openid-connect/token`, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: bodyParams,
    method: 'POST'
  });
  const refreshToken = await resp.json();
  if (!resp.ok) {
    throw refreshToken;
  }

  const { data } = await axios.post(`${process.env.NEXT_PUBLIC_BASE_URL}v1/sessions`, {
    headers: {
      'Content-Type': 'application/json'
    },
    session: { keycloak_token: refreshToken.access_token }
  });
  token.user_id = data.id;

  return {
    ...token,
    access_token: refreshToken.access_token,
    decoded: jwt_decode(refreshToken.access_token),
    id_token: refreshToken.id_token,
    expires_at: Math.floor(Date.now() / 1000) + Number(refreshToken.expires_in),
    refresh_token: refreshToken.refresh_token
  };
};

export const externalAccountErrorHandler = async (jwt: JWT) => {
  const { provider, id_token } = jwt;
  if (provider === 'keycloak') {
    try {
      const params = new URLSearchParams();
      params.append('id_token_hint', id_token as string);
      await axios.get(`${kcIssuer}/protocol/openid-connect/logout?${params.toString()}`);
    } catch (e: any) {
      console.error('Unable to perform post-logout handshake', (e as AxiosError)?.code || e);
    }
  }
};
