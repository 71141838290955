import { actionTypes } from './searchheaderAction';
const initialState = {
  data: [],
  isLoading: true
};

export function searchheaderReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.REQUEST_DATA_LIST_HASHTAG: {
      return {
        ...state
      };
    }
    case actionTypes.GET_DATA_LIST_HASHTAG_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isLoading: false
      };
    }
    case actionTypes.GET_DATA_LIST_HASHTAG_FALL: {
      return {
        ...state,
        data: {},
        isLoading: true
      };
    }
    default:
      return state;
  }
}
