import { IdataReview } from '@src/interface/IStatusState';

export interface IThanksState {
  data: IDataThanks[];
  isLoadingListThanks: boolean;
  page: number;
  limit: number;
  count: number;
}

export interface IdataUser {
  id: number;
  avatar_url: string;
  username: string;
  name: string;
  team: string;
}

export interface IdataReceiver extends IdataUser {
  point: number;
}

export interface IDataThanks {
  id: number;
  sender: IdataReceiver;
  receivers: IdataReceiver[];
  object_type: TransactionObjectType;
  object_id: number;
  url_object_type: string;
  url_object_id: number;
  object_content: string;
  object_review: IdataReview;
  created_at: string;
  updated_at: string;
}

export interface IThanksResponse {
  transactions: IDataThanks[];
  count: number;
  page: number;
  limit: number;
}

export enum TransactionObjectType {
  Status = 'Status',
  Post = 'Post',
  Comment = 'Comment',
  Event = 'Event',
  Reward = 'Reward'
}
