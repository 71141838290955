import { actionTypes } from './giphyAction';
import { actionTypes as actionTypesEdit } from 'src/activities/status/statusAction';

const initialState = {
  urlGif: null,
  isClick: false,
  idGif: -998,
  idGifComment: -999,
  isSmall: false,
  urlGifComment: null
};
export function giphyReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.CLICK_GIPHY: {
      return {
        ...state
      };
    }
    case actionTypes.SAVE_GIPHY: {
      return {
        ...state,
        urlGif: [{ url: action.urlGif }],
        isClick: true,
        idGif: action.id
      };
    }
    case actionTypes.SAVE_GIPHY_EDIT: {
      return {
        ...state,
        urlGifEdit: [{ url: action.urlGifEdit }],
        isClick: true
      };
    }
    case actionTypes.CLICK_GIPHY_CLOSE: {
      return {
        ...state,
        isClick: false,
        urlGif: '',
        idGif: null
      };
    }
    case actionTypes.CLICK_GIPHY_COMMENT_CLOSE: {
      return {
        ...state,
        isClick: false,
        idGifComment: null,
        urlGifComment: null
      };
    }
    case actionTypes.CLICK_GIPHY_EDIT_CLOSE: {
      return {
        ...state,
        urlGifEdit: null
      };
    }
    case actionTypes.IS_SET_POPUP_NOT_PARENT: {
      return {
        ...state,
        isSmall: true,
        urlGif: false
      };
    }
    case actionTypes.SAVE_GIFPHY_COMMENT: {
      return {
        ...state,
        urlGifComment: action.urlGif,
        isClick: true,
        idGifComment: action.id
      };
    }
    case actionTypesEdit.GET_STATUS_BY_ID_SUCCESS: {
      return {
        ...state,
        urlGifEdit: action.payload.giphy && [{ url: action.payload.giphy }]
      };
    }
    case actionTypesEdit.CLOSE_MODAL: {
      return { ...state, urlGifEdit: null };
    }
    case actionTypes.IMPORT_EDIT_GIFT_COMMENT: {
      return {
        ...state,
        urlGifComment: action.urlGif,
        idGifComment: action.id
      };
    }
    default:
      return state;
  }
}
