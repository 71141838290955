export const actionTypes = {
  REQUEST_OTHER_PROFILE: 'REQUEST_OTHER_PROFILE',
  GET_OTHER_PROFILE: 'GET_OTHER_PROFILE',
  GET_OTHER_PROFILE_FAIL: 'GET_OTHER_PROFILE_FAIL',
  REQUEST_OTHER_TOTAL_POINTS_RECEIVE: 'REQUEST_OTHER_TOTAL_POINTS_RECEIVE',
  GET_OTHER_TOTAL_POINTS_RECEIVE: 'GET_OTHER_TOTAL_POINTS_RECEIVE',
  REQUEST_OTHER_TOTAL_POSTS: 'REQUEST_OTHER_TOTAL_POSTS',
  GET_OTHER_TOTAL_POSTS: 'GET_OTHER_TOTAL_POSTS',
  REQUEST_OTHER_TOP_GIVE_POINT: 'REQUEST_OTHER_TOP_GIVE_POINT',
  GET_OTHER_TOP_GIVE_POINT: 'GET_OTHER_TOP_GIVE_POINT',
  REQUEST_OTHER_TOP_RECEIVE_POINT: 'REQUEST_OTHER_TOP_RECEIVE_POINT',
  GET_OTHER_TOP_RECEIVE_POINT: 'GET_OTHER_TOP_RECEIVE_POINT'
};

export const requestOtherProfile = (id: string) => ({
  type: actionTypes.REQUEST_OTHER_PROFILE,
  id
});
export const getOtherProfile = (data: any) => ({
  type: actionTypes.GET_OTHER_PROFILE,
  data
});

export const requestOtherTotalPointsReceive = (
  startDate: string,
  endDate: string,
  userId: string
) => ({
  type: actionTypes.REQUEST_OTHER_TOTAL_POINTS_RECEIVE,
  startDate,
  endDate,
  userId
});
export const getOtherTotalPointsReceive = (data: any) => ({
  type: actionTypes.GET_OTHER_TOTAL_POINTS_RECEIVE,
  data
});

export const requestOtherTotalPosts = (startDate: string, endDate: string, userId: string) => ({
  type: actionTypes.REQUEST_OTHER_TOTAL_POSTS,
  startDate,
  endDate,
  userId
});
export const getOtherTotalPost = (data: any) => ({
  type: actionTypes.GET_OTHER_TOTAL_POSTS,
  data
});

export const requestOtherTopGivePoint = (startDate: string, endDate: string, userId: string) => ({
  type: actionTypes.REQUEST_OTHER_TOP_GIVE_POINT,
  startDate,
  endDate,
  userId
});
export const getOtherTopGivePoint = (data: any) => ({
  type: actionTypes.GET_OTHER_TOP_GIVE_POINT,
  data
});

export const requestOtherTopReceivePoint = (
  startDate: string,
  endDate: string,
  userId: string
) => ({
  type: actionTypes.REQUEST_OTHER_TOP_RECEIVE_POINT,
  startDate,
  endDate,
  userId
});
export const getOtherTopReceivePoint = (data: any) => ({
  type: actionTypes.GET_OTHER_TOP_RECEIVE_POINT,
  data
});
