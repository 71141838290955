import styled from 'styled-components';
interface Props {
  display?: string;
  screen?: number;
}
const Wrapper = styled.div`
  display: flex;
  position: relative;
  height: fit-content;
  align-self: center;
`;
const FeedbackTooltip = styled.div<Props>`
  display: ${({ display }) => (display ? `none` : 'flex')} !important;
  z-index: 100;
  position: absolute;
  bottom: 13px;
  left: 13px;
  display: flex;
  gap: 8px;
  .feedback {
    display: none;
  }
  img {
    :hover {
      cursor: pointer;
    }
  }
  img:hover + .feedback {
    display: block;
  }
`;
const FeedbackText = styled.p`
  position: relative;
  font-size: 13px;
  line-height: 18px;
  color: #222222;
  font-weight: 400;
  margin-bottom: 10px;
  margin-top: 22px;
  padding: 6px 10px;
  background-color: #d7d7d7;
  border-radius: 3px;
  img {
    position: absolute;
    left: -5px;
  }
`;
const FeedbackContainer = styled.div<Props>`
  overflow-x: hidden;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url('/feedback_bee.webp');
  background-size: 55% 80%;
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  padding-top: 64px;
  aspect-ratio: 795/772;
  display: flex;
  gap: ${({ screen }) => (screen > 1660 ? `150px` : '80px')};
  .top-star {
    position: absolute;
    right: -87px;
    top: -128px;
  }
  .bottom-star {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 30;
  }
  .intro-bee {
    margin-left: -30px;
    margin-top: -50px;
    align-self: center;
  }
  img {
    object-fit: contain;
    max-width: ${({ screen }) => (screen > 1380 ? `588.93px` : '380px')};
  }
  background-color: white;
`;
const FeedbackForm = styled.div`
  z-index: 20;
  background-color: white;
  position: relative;
  border-style: solid;
  border-width: 10px;
  border-color: #fef2d1;
  border-radius: 10px;
  padding: 30px 70px;
  height: fit-content;
  align-self: center;
  .feedbackTitle {
    text-align: center;
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;
    color: #1a1a1a;
    margin-bottom: 32px;
  }
  .icon-post-fb {
    display: flex;
    margin: auto;
  }
  .thankyou-title {
    text-align: center;
    font-weight: 700;
    color: #1a1a1a;
    font-size: 28px;
    line-height: 36.5px;
    margin-top: 12px;
    margin-bottom: 8px;
  }
  .thankyou-note {
    color: #1a1a1a;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    margin-bottom: 32px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .thankyou-button {
    background-color: #1a1a1a;
    color: #ffffff;
    width: fit-content;
    padding: 10px 24px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    text-align: center;
    cursor: pointer;
    margin: auto;
  }
`;
const FeedbackRatingContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 32px;
  gap: 4px;
  .box-rate-star {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 343px;
    gap: 25.75px;
    img {
      width: 48px;
      height: 48px;
    }
  }
`;
const LabelFeedback = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  .input-feedback {
    border-radius: 4px;
    border: 1px solid #d3d3d3;
    background: #fdfdfd;
    resize: none;
    padding: 8px 12px;
    outline: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    color: #1a1a1a;
    width: 100%;
    max-width: 513px;
    height: 174px !important;
    &::placeholder {
      color: #dadada;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }
    &.input-loading {
      background-color: #f1f1f1;
    }
  }
  .label-feedback {
    position: relative;
    color: #1a1a1a;
    line-height: 18px;
    margin-bottom: 8px;
    font-size: 14px;
    &::before {
      content: '*';
      position: absolute;
      top: 0;
      right: -8px;
      color: #d1575c;
    }
  }
`;
const WrapperFeedback = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 206px;
  width: 513px;
  .upload-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    .box-btn-upload {
      display: flex;
      gap: 12px;
      width: 100%;
      align-items: flex-end;
      margin-top: 16px;
    }
  }
`;
const PreviewBox = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
  .validate-error-file {
    color: #d1575c;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    margin-top: 8px;
  }
  .box-preview-file {
    width: 513px;
    overflow-x: scroll;
    display: flex;
    gap: 8px;
    margin-top: 16px;
  }
`;
const PreviewFile = styled.div`
  position: relative;
  width: 70px;
  height: 70px;
  object-fit: cover;
  flex: 0 0 auto;
  &::-webkit-scrollbar {
    width: 8px;
  }
  video,
  img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    &.error-file {
      border: 2px solid #d1575c;
      border-radius: 4px;
    }
  }

  .icon-delete-img {
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 10;
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
`;
const UploadBtnFeedback = styled.label`
  width: 117px;
  height: 34px;
  position: relative;
  cursor: pointer;
  display: flex;
  padding: 5px 12px;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  background: #1a1a1a;
  justify-content: center;
  &.loading-btn {
    background: #1a1a1a80;
  }
  img {
    width: 18px;
    height: 18px;
  }
  p {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    align-self: center;
    margin: 0;
  }
`;
const FileRequireText = styled.p`
  color: #a8a8a8;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin: 0;
`;
const FeedbackSendButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  .btn-send-feedback {
    border-radius: 4px;
    background: #1a1a1a;
    display: flex;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: none;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    cursor: pointer;
    &.loading-send-btn {
      background: #1a1a1a80;
    }
  }
  .btn-cancel {
    background: #f1f1f1;
    color: #1a1a1a;
    &.loading-btn {
      opacity: 0.5;
    }
  }
`;
export {
  Wrapper,
  FeedbackTooltip,
  FeedbackText,
  FeedbackContainer,
  FeedbackForm,
  FeedbackRatingContainer,
  LabelFeedback,
  WrapperFeedback,
  PreviewBox,
  PreviewFile,
  UploadBtnFeedback,
  FileRequireText,
  FeedbackSendButton
};
