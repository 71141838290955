import { actionTypes } from './dataHeatmapAction';

const initialState = {
  date: '',
  items: [],
  activeTemplate: []
};

export function dataHeatmapReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.UPDATE_DATA_HEATMAP: {
      return {
        ...state,
        date: action.date,
        items: action.items
      };
    }
    case actionTypes.ACTIVE_TEMPLATE: {
      return {
        ...state,
        activeTemplate: action.activeTemplate
      };
    }
    default:
      return state;
  }
}
