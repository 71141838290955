import { IformSignin } from '@src/interface/ILoginState';

export const actionTypes = {
  POST_AUTH: 'POST_AUTH',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAIL: 'LOGIN_FAIL',
  LOGOUT: 'LOGOUT'
};
export const postFormSignIn = (formData: IformSignin) => ({
  type: actionTypes.POST_AUTH,
  formData
});

export const getLogout = (uid: string) => ({
  type: actionTypes.LOGOUT,
  uid
});
