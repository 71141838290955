import { ITemplate } from '@src/interface/ITemplate';

export const actionTypes = {
  GET_LIST_TEMPLATE_REQUEST: 'GET_LIST_TEMPLATE_REQUEST',
  GET_LIST_TEMPLATE_SUCCESS: 'GET_LIST_TEMPLATE_SUCCESS',
  GET_LIST_TEMPLATE_FAIL: 'GET_LIST_TEMPLATE_FAIL',

  CREATE_TEMPLATE_REQUEST: 'CREATE_TEMPLATE_REQUEST',
  CREATE_TEMPLATE_SUCCESS: 'CREATE_TEMPLATE_SUCCESS',
  CREATE_TEMPLATE_FAIL: 'CREATE_TEMPLATE_FAIL',

  DELETE_TEMPLATE_REQUEST: 'DELETE_TEMPLATE_REQUEST',
  DELETE_TEMPLATE_SUCCESS: 'DELETE_TEMPLATE_SUCCESS'
};

export const getListTemplates = () => ({
  type: actionTypes.GET_LIST_TEMPLATE_REQUEST
});

export const createTemplate = (data: ITemplate) => ({
  type: actionTypes.CREATE_TEMPLATE_REQUEST,
  data
});

export const deleteTemplate = (id: number) => ({
  type: actionTypes.DELETE_TEMPLATE_REQUEST,
  payload: { id }
});
