import { actionTypes } from './recommentHashtagAction';

const initialState = {
  data: [],
  isLoading: false
};

export function hashtagRecommentReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_HASHTAG_RECOMMENT_REQUEST: {
      return { ...state, isLoading: true };
    }
    case actionTypes.GET_HASHTAG_RECOMMENT_SUCESS: {
      return { ...state, isLoading: false, data: action.payload };
    }
    case actionTypes.GET_HASHTAG_RECOMMENT_ERROR: {
      return { ...state, isLoading: false, data: [] };
    }
    default:
      return state;
  }
}
