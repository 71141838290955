export const getImg = (imgFileName: string): string => {
  return require(`./images/${imgFileName}`);
};

const imgs = {
  WillGet: getImg('bg-willget.png'),
  LineCenter: getImg('linecenter.png'),
  SelectBg: getImg('select-bg.png'),
  SearchBg: getImg('search-bg.png'),
  SlideBg: getImg('slide-bg1.png'),
  SlideBg2: getImg('hotnewbg.png'),
  Logo: getImg('lg-logo.png'),
  BubbleMessage: getImg('bubble-message.png'),
  TypingImage: getImg('typing.gif'),
  ForgotPasswordGB: getImg('forgot_password_bg.png'),
  IClock: getImg('i_clock.png'),
  ResetPasswordBg: getImg('reset_password_bg.png'),
  ProfileSmall: getImg('bg_profile_small.png'),
  ProfileLarger: getImg('bg_profile_large.png'),
  PlaceHolderImage: getImg('place-holder.png'),
  DemoContent: getImg('democontent.png'),
  ImgIcon: getImg('icon_img.png'),
  ImgIconNone: getImg('icon_image_none.png'),
  DropDownImg: getImg('bg-dropimg.png'),
  IconGoogle: getImg('icon_google.png'),
  IconSSO: getImg('icon_sso.png'),
  ImgGPT: getImg('avt-gpt.png'),
  LogoGPT_V3: getImg('logo_gpt_v3.png'),
  LogoGPT_16K: getImg('logo_gpt_16k.png'),
  LogoGPT_V4: getImg('logo_gpt_v4.png'),
  ModelsSelect: getImg('models_select.png'),
  RemoveProject: getImg('remove_project.png'),
  PostHeatmap: getImg('post_heatmap.png'),
  Plus: getImg('plus.png'),
  SearchProject: getImg('search-project.png'),
  Check: getImg('check.png'),
  CloseModal: getImg('close_modal.png'),
  EmptyDataBee: getImg('empty_data_bee.png'),
  EmptyProjects: getImg('empty_projects.png'),
  HoneyBee: getImg('honeybee.png'),
  Calender: getImg('calender.png'),
  DefaultPostHeatmap: getImg('default_post_heatmap.svg'),
  DefaultAddProject: getImg('default_add_pj.svg')
};

const icons = {
  logoSrc: getImg('logo.png'),
  Icon0Active: getImg('icon0.png'),
  Icon0: getImg('icon0-active.png'),
  Icon2: getImg('icon2.png'),
  Icon2Active: getImg('icon2-active.png'),
  Icon3: getImg('icon3.png'),
  Icon3Active: getImg('icon3-active.png'),
  Icon5: getImg('icon5.png'),
  Icon7: getImg('icon7.png'),
  Icon7Active: getImg('icon7Active.png'),
  Icon5Active: getImg('icon5-active.png'),
  Bell: getImg('bell.png'),
  Bell2: getImg('bell2.png'),
  IconAlert: getImg('icon-erorr.png'),
  IconForPoint: getImg('icon-point.png'),
  Iinfor: getImg('icon_profile.png'),
  Iacc: getImg('icon_key.png'),
  Ipost: getImg('icon_write.png'),
  Ipoint: getImg('icon_points.png'),
  Ilogout: getImg('icon_logout.png'),
  CheckedNew: getImg('icon-checked-new.png'),
  IconPlus: getImg('icon-plus.png'),
  IconNextWhite: getImg('icon-next-white.png'),
  IconNextBlack: getImg('icon-next-black.png'),
  IconPreviousWhite: getImg('icon-previous-white.png'),
  IconPreviousBlack: getImg('icon-previous-black.png'),
  IconChecked: getImg('icon_checked.png'),
  IconCamera: getImg('icon_camera.png'),
  IconEdit: getImg('i_edit.png'),
  IconOpenRight: getImg('openright.png'),
  IconArrowRight: getImg('arrowright.png'),
  WillGet: getImg('bg-willget.png'),
  LineCenter: getImg('linecenter.png'),
  SelectBg: getImg('select-bg.png'),
  SearchBg: getImg('search-bg.png'),
  SlideBg: getImg('slide-bg1.png'),
  SlideBg2: getImg('hotnewbg.png'),
  Logo: getImg('lg-logo.png'),
  BubbleMessage: getImg('bubble-message.png'),
  TypingImage: getImg('typing.gif'),
  PlaceHolderImage: getImg('place-holder.png'),
  DemoContent: getImg('democontent.png'),
  ImgIcon: getImg('icon_img.png'),
  ImgIconNone: getImg('icon_image_none.png'),
  HeartFull: getImg('icon-heart-full.png'),
  IconMessage: getImg('icon-message.png'),
  IconSee: getImg('icon-eye.png'),
  IconBookMark: getImg('icon-bookmark.png'),
  IconSeeBookmark: getImg('icon-bookmark.png'),
  IconUnHeart: getImg('icon-unlike.png'),
  IconFilter: getImg('icon-filter.png'),
  GifIcon: getImg('icon_gif.png'),
  GifIconNone: getImg('icon_gif2.png'),
  GifIconStatus: getImg('icon-gif-status.png'),
  IconHeart: getImg('icon_heart.png'),
  CloseImg: getImg('icon_close_img.png'),
  IEdit: getImg('i_edit_2.png'),
  IconClock: getImg('icon_clock.png'),
  IconLocation: getImg('icon_location.png'),
  IconOrganizer: getImg('icon_organizer.png'),
  IconBookmarkSave: getImg('icon-save-bookmark.png'),
  IconPlusPoint: getImg('icon_plus_point.png'),
  IconDone: getImg('icon_done_green.png'),
  IClose: getImg('close.png'),
  DropDownImg: getImg('bg-dropimg.png'),
  Hash: getImg('hash.svg'),
  AtSign: getImg('at-sign.svg'),
  IPlusIcon: getImg('plusIcon.svg'),
  IFeelingIcon: getImg('feelingIcon.svg'),
  IPollIcon: getImg('poll-icon.svg'),
  IIconStatusBackground: getImg('status-background-icon.png'),
  ICloseButtonLight: getImg('icon-close-light.png'),
  ICloseButton: getImg('icon-close.png'),
  IIconCopy: getImg('icon_copy.png'),
  IIconVideoSelect: getImg('icon_video_select.png'),
  IIconPlay: getImg('icon_play.png'),
  IconCopy: getImg('i_copy.png'),
  IconTrash: getImg('i_trash.png'),
  IconMore: getImg('icon-more.png'),
  Top1st: getImg('top/top1st.png'),
  Top2nd: getImg('top/top2nd.png'),
  Top3rd: getImg('top/top3rd.png'),
  PaperPlane: getImg('paper-plane.svg'),
  ImgGPT: getImg('avt-gpt.png'),
  IPlusIconWhite: getImg('plusIconWhite.svg'),
  Send: getImg('send.svg'),
  AddTemplate: getImg('icon-add-template.svg'),
  IconClose: getImg('icon-close-modal.png'),
  IconEditTem: getImg('icon-edit.svg'),
  IconTrashTem: getImg('icon-trash.svg'),
  IconChevronLeft: getImg('chevron-left.svg'),
  IconChevronRight: getImg('chevron-right.svg'),
  IconLoading: getImg('spin.svg'),
  IconChevronDown: getImg('chevron-down.svg'),
  IconTemplateHeatMap: getImg('sticker-square.svg'),
  IconFileDownload: getImg('file-download.svg'),
  IconCloseMood: getImg('close-mood-modal.svg'),
  IconBeeVerySad: getImg('honey_bee_very_sad.svg'),
  IconBeeSad: getImg('honey_bee_sad.svg'),
  IconBeeNormal: getImg('honey_bee_normal.svg'),
  IconBeeHappy: getImg('honey_bee_happy.svg'),
  IconBeeVeryHappy: getImg('honey_bee_very_happy.svg'),
  IconBeeVerySadActive: getImg('honey_bee_very_sad_active.svg'),
  IconBeeSadActive: getImg('honey_bee_sad_active.svg'),
  IconBeeNormalActive: getImg('honey_bee_normal_active.svg'),
  IconBeeHappyActive: getImg('honey_bee_happy_active.svg'),
  IconBeeVeryHappyActive: getImg('honey_bee_very_happy_active.svg'),
  IconDeleteHeatmap: getImg('delete_heatmap.svg'),
  IconCopyHeatmap: getImg('copy_heatmap.svg'),
  IconEditHeatmap: getImg('edit_heatmap.svg'),
  IconTimelineDot: getImg('timeline-dot.svg'),
  IconDocument: getImg('document.svg'),
  IconOffworkLog: getImg('offwork-log.svg')
};

export { icons, imgs };
