import { SET_FORCE_LOGOUT } from '@src/components/LogoutModal/forceLogoutAction';

const initialState = {
  isForceLogout: false
};

const forceLogoutReducer = (state: { isForceLogout: boolean } = initialState, action: any) => {
  switch (action.type) {
    case SET_FORCE_LOGOUT:
      return {
        ...state,
        isForceLogout: action.payload.isForceLogout
      };
    default:
      return state;
  }
};

export default forceLogoutReducer;
