import { AxiosResponse } from 'axios';
import { appAxios } from '@src/api/appAxios';
import handleError from './handleError';
import { IMoodWorking } from '@src/interface/IMoodWorkingState';

export const moodWorkingAPI = {
  getMoodWorking: async (params) => {
    try {
      const { data }: AxiosResponse = await appAxios.get(`/posts/v2/working-moods?date=${params}`);
      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      throw error.response;
    }
  },
  postMoodWorking: async (params: IMoodWorking) => {
    try {
      const { data }: AxiosResponse = await appAxios.post(`/posts/v2/working-moods`, params);
      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      throw error.response;
    }
  }
};
