import styled from 'styled-components';

interface Props {
  padding?: number;
  isHover?: boolean;
  radius?: number;
  color?: string;
  bgColor?: string;
  fontSize?: string;
  fontWeight?: string;
  lineHeight?: string;
  require?: boolean;
  disable?: boolean;
}
const Text = styled.p<Props>`
  color: ${(props) => (props.color ? `${props.color}` : `#1A1A1A`)};
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : `14px`)};
  font-style: normal;
  font-weight: ${(props) => (props.fontWeight ? `${props.fontWeight}` : `400`)};
  line-height: ${(props) => (props.lineHeight ? `${props.lineHeight}` : `130%`)};
  margin-bottom: 0;
  &:after {
    content: ${(props) => (props.require ? `"*"` : ``)};
    color: #d1575c;
    margin-left: 4px;
  }
`;
const ButtonClose = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  padding: 0;
  right: 14px;
  top: 14.5px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const Button = styled.button<Props>`
  pointer-events: ${(props) => (props.disable ? `none` : `auto`)};
  padding: ${(props) => (props.padding ? `${props.padding}` : `10px 24px`)};
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.disable ? '#000' : props.color ? `${props.color}` : `#1a1a1a`)};
  background-color: ${(props) =>
    props.disable ? '#EFEFEF' : props.bgColor ? `${props.bgColor}` : `#F1F1F1`};
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  border-radius: 4px;
  border: none;
  cursor: ${(props) => (props.disable ? `not-allowed` : `pointer`)};
`;

const WrapperBodyModal = styled.div`
  display: flex;
  padding: 0px 24px 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;
const DatePickerBox = styled.div`
  display: flex;
  width: 407px;
`;

const WrapperFormHeatmap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-height: 430px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;
export default {
  Text,
  ButtonClose,
  WrapperBodyModal,
  DatePickerBox,
  WrapperFormHeatmap,
  Button
};
