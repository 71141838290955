export const actionTypes = {
  GET_HISTORY: 'GET_HISTORY',
  GET_HISTORY_PENDING: 'GET_HISTORY_PENDING',
  GET_HISTORY_SUCCESS: 'GET_HISTORY_SUCCESS',
  CLICK_SEE_ALL: 'CLICK_SEE_ALL',
  CLICK_HIDDEN_ITEM: 'CLICK_HIDDEN_ITEM',
  HISTORY_HEATMAP: 'HISTORY_HEATMAP',
  HISTORY_HEATMAP_SUCCESS: 'HISTORY_HEATMAP_SUCCESS'
};

export const getAllItems = () => ({
  type: actionTypes.CLICK_SEE_ALL
});

export const getHistoryHeatmap = (data) => ({
  type: actionTypes.HISTORY_HEATMAP,
  data
});

export const getHistorySuccess = (data: any) => ({
  type: actionTypes.HISTORY_HEATMAP_SUCCESS,
  data
});
