/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios';
import { appAxios } from '@src/api/appAxios';
import handleError from './handleError';
import get from 'lodash/get';

const POST_RECORD_URL = 'heatmap/v2/records';
const FAVORITE_PROJECTS_URL = 'heatmap/v2/favourite-projects';

export const heatmapApi = {
  postHeatmap: async (params: {
    date: string;
    items: {
      content: string;
      hours: number | null;
      project_id: string | number;
    }[];
  }) => {
    try {
      const { data }: AxiosResponse = await appAxios.post(POST_RECORD_URL, params);
      return data;
    } catch (error) {
      // const err: AxiosError = error;
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      throw error.response;
    }
  },
  putHeatmap: async (params) => {
    try {
      const { data }: AxiosResponse = await appAxios.put(POST_RECORD_URL, params);
      return data;
    } catch (error) {
      // const err: AxiosError = error;
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      throw error.response;
    }
  },
  deleteHeatmap: async (params: { project_id: number }) => {
    try {
      const { data }: AxiosResponse = await appAxios.delete(
        POST_RECORD_URL + '/' + params.project_id
      );
      return data;
    } catch (error) {
      // const err: AxiosError = error;
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      throw error.response;
    }
  },
  getListFavoriteProjects: async (): Promise<any> => {
    try {
      const { data }: AxiosResponse = await appAxios.get(FAVORITE_PROJECTS_URL);
      return get(data, 'data.projects', []);
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      throw error.response;
      // console.log(error);
    }
  },
  updateListFavoriteProjects: async (params: { projects: number[] }) => {
    try {
      const { data }: AxiosResponse = await appAxios.post(FAVORITE_PROJECTS_URL, params);
      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      throw error.response;
      // console.log(error);
    }
  }
};
