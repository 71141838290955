import { AxiosResponse, AxiosRequestConfig } from 'axios';
import { appAxios } from '@src/api/appAxios';
import { setError } from '../common/commonAction';
import handleError from './handleError';

export const CreateApi = {
  sendImg: async (fileImg: any) => {
    try {
      const formData = new FormData();
      formData.append('type', '0');
      fileImg && fileImg.map((item: any) => formData.append('file', item));
      const { data }: AxiosResponse = await appAxios.post(`posts/v2/files`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      return { isError: true, message: error?.response?.data?.message };
    }
  },
  sendVideo: async (file: any, config: AxiosRequestConfig) => {
    try {
      const formData = new FormData();
      formData.append('type', '3');
      formData.append('file', file);
      const { data }: AxiosResponse = await appAxios.post(`posts/v2/files`, formData, config);
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      // throw error;
    }
  },
  createAnnouncement: async (dataSend: { [key: string]: any }) => {
    try {
      const { data }: AxiosResponse = await appAxios.post(`posts/v2/announcements`, dataSend);
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      // throw error;
    }
  },
  createNew: async (dataSend: { [key: string]: any }) => {
    try {
      const { data }: AxiosResponse = await appAxios.post(`posts/v2/news`, dataSend);
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      // throw error;
    }
  },
  updateNews: async (id: number, dataSend: { [key: string]: any }) => {
    try {
      const { data }: AxiosResponse = await appAxios.put(`posts/v2/news/${id}`, dataSend);
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      setError();
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
    }
  },
  updateAnnouncement: async (id: number, dataSend: { [key: string]: any }) => {
    try {
      const { data }: AxiosResponse = await appAxios.put(`posts/v2/announcements/${id}`, dataSend);
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      setError();
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
    }
  },
  mentions: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get('posts/v2/users/mentions?limit=1000');
      return data && data.data && data.data.mentions ? data.data.mentions : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      // throw error;
    }
  },
  eventCoefficientPoints: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get('v1/configs');
      if (data && data.app_configs) {
        let result = {};
        data.app_configs.forEach((value: any) => {
          if (value && value.key === 'event_coefficient_points') {
            result = value.value;
          }
        });
        return +result;
      }
      return false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      // throw error;
    }
  },
  deleteFile: async (id: number) => {
    try {
      const { data }: AxiosResponse = await appAxios.delete(`posts/v2/files/${id}`, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      // throw error;
    }
  }
};
