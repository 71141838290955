import { AxiosResponse } from 'axios';
import get from 'lodash/get';
import * as queryString from 'query-string';
import { appAxios } from '@src/api/appAxios';
import handleError from './handleError';

const GET_RECORDS_URL = 'heatmap/v2/records';
const GET_PROJECTS_URL = 'heatmap/v2/projects';

export const chartApi = {
  getListProject: async (): Promise<any> => {
    try {
      const { data }: AxiosResponse = await appAxios.get(GET_PROJECTS_URL);
      return get(data, 'data.projects', []);
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      // console.log(error);
    }
  },
  getDataHeatmap: async (params: { from_date: string; to_date: string }): Promise<any> => {
    const url = `${GET_RECORDS_URL}?${queryString.stringify(params)}`;
    try {
      const { data }: AxiosResponse = await appAxios.get(url);
      return get(data, 'data.records', []);
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      // console.log(error);
    }
  }
};
