import { styled } from 'src/styled/Theme';
import { css } from 'styled-components';
import { icons, imgs } from '@src/static';

interface Props {
  pt?: number;
  pb?: number;
  pl?: number;
  pr?: number;
  w?: number;
  h?: number;
  bottom?: number;
  linecenter?: boolean;
  br?: number;
  bg?: string;
  p?: string;
  mr?: number;
  ml?: number;
  mt?: number;
  mb?: number;
  jtc?: string;
  width?: string;
  row?: string;
  isEdit?: boolean;
  colorhover?: string;
  fz?: number;
}

interface CardNewProps {
  notpointer?: boolean;
  hoverable?: boolean;
  csbt?: boolean;
  height?: string;
  transformable?: boolean;
  unselect?: boolean;
}

interface WillGetCardProps {
  isShow: boolean;
}

const Card = styled.div<Props>`
  position: relative;
  border-radius: 10px;
  height: ${(props) => (props.h ? `${props.h / 16}rem` : 'auto')};
  width: ${(props) => (props.w ? `${props.w / 16}rem` : 'auto')};
  padding-left: ${(props) => (props.pl ? `${props.pl / 16}rem` : '1.5rem')};
  padding-right: ${(props) => (props.pr ? `${props.pr / 16}rem` : '1.5rem')};
  padding-top: ${(props) => (props.pt ? `${props.pt / 16}rem` : '1.25rem')};
  padding-bottom: ${(props) => (props.pb ? `${props.pb / 16}rem` : '1.25rem')};
  background-color: ${(props) => props.theme.colors.light[1]};
  ${({ linecenter }) =>
    linecenter &&
    css`
      background-image: url('${imgs.LineCenter}');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 1px 70%;
    `};
`;
const CardNew = styled.div<CardNewProps>`
  height: ${(props: CardNewProps) => (props.height ? props.height : '100%')};
  border-radius: 10px;
  padding: 22px 24px 5px 24px;
  position: relative;
  background-color: ${(props) => props.theme.colors.light[1]};
  transition: 0.5s ease all;

  ${(props) =>
    props.hoverable
      ? `:hover {
      box-shadow: -10px 10px 31px 0px rgba(184,184,184,0.6);
      ${props.transformable ? `transform: translateY(-4px);` : null}
    }`
      : ''}

  ${(props) =>
    props.unselect
      ? `
        user-select: none;`
      : ''}
      
  .contain-checked {
    position: relative;
  }
  .scroll-over-x {
    overflow-x: auto;
  }
  .max-line-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  cursor: ${(props) => (props.notpointer ? 'default' : 'pointer')};
  ${({ csbt }) =>
    csbt &&
    css`
      padding-bottom: 45px;
    `}
  .is-bottom-card {
    width: calc(100% - 48px);
    position: absolute;
    bottom: 24px;
  }
  .on-top-face {
    z-index: 10;
  }
`;
const CheckedCardNew = styled.div`
  position: absolute;
  bottom: 0;
  right: 24px;
  height: 24px;
  width: 24px;
  background-image: url('${icons.CheckedNew}');
  background-position: center;
  background-size: 24px;
  background-repeat: no-repeat;
`;
const BottomCard = styled.div<Props>`
  position: absolute;
  text-align: center;
  width: ${(props) => (props.w ? `${props.w / 16}rem` : '100%')};
  bottom: ${(props) => (props.bottom ? `${props.bottom / 16}rem` : 0)};
`;
const FilterBox = styled.div`
  padding: 14px;
  box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  background-color: ${(props) => props.theme.colors.light[1]};
  label {
    cursor: pointer;
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
`;

const CardStatus = styled.div<Props>`
  /* cursor: pointer; */
  height: auto;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.light[1]};
  // border: 1px solid ${(props) => props.theme.colors.light[5]};
  padding: 15px 24px 16px 18px;
`;

const BoderBottomCard = styled.div<Props>`
  border-bottom: 1px solid #f5f5f5;
`;

const SpacingCard = styled.div<Props>`
  padding: 16px 0 16px 0;
  display: flex;
  justify-content: space-between;
`;

const StatusCard = styled.div<Props>`
  padding: 6px 14px 6px 16px;
  border-radius: ${({ br }) => (br ? `${br}px` : '')};
  background: ${({ bg }) => (bg ? bg : '')};
  margin-right: 4px;
  display: flex;
`;

const ContainerStatusCard = styled.div<Props>`
  display: flex;
  width: ${(props) => (props.w ? `${props.w / 16}rem` : '100%')};
`;

const SpacingStatus = styled.div<Props>`
  padding: ${({ p }) => p};
  display: flex;
  flex-direction: column;
`;

const CommentCard = styled.span<Props>`
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.dark[3]};
  margin: 15px 0 24px 0;
`;
const StatusContentCard = styled.div`
  width: 100%;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.dark[3]};
  margin: 15px 0 24px 0;
`;
const HagtagNameCard = styled.span<Props>`
  color: ${(props) => props.theme.colors.normal[3]};
  font-weight: 600;
`;
const HagtagCard = styled.span<Props>`
  color: ${(props) => props.theme.colors.dark[3]};
  font-weight: 600;
`;

const WillGetCard = styled.div<WillGetCardProps>`
  overflow: hidden;
  transition: all 0.5s linear;
  border-style: solid;
  border-radius: 10px;
  background-image: url('${imgs.WillGet}');
  background-repeat: no-repeat;
  background-size: 165px 110px;
  background-position: bottom right;
  height: ${(props) => (props.isShow ? 'auto' : '0px')};
  background-color: ${(props) => props.theme.colors.light[2]};
  border-color: ${(props) => props.theme.colors.light[5]};
  border-width: ${(props) => (props.isShow ? '1px' : '0px')};
  padding: ${(props) => (props.isShow ? '15px 15px 15px 37px' : '0px 15px 0px 37px')};
`;

const StatusButtonCard = styled.div`
  display: flex;
  padding: 4px 12px 4px 0;
  &:hover {
    background-color: #f5f5f5;
    border-radius: 4px;
  }
  cursor: pointer;
  margin-right: 5px;
`;

const RewardDetailCard = styled.div`
  width: 100%;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.light[1]};
  position: relative;
  height: calc(100vh - 108px);
  padding: 24px 100px 100px 24px;
  .contain {
    &-bottom-step {
      position: absolute;
      bottom: 0;
      width: 100%;
      margin-left: -24px;
      display: flex;
      justify-content: space-between;
    }
    &-button-close {
      position: absolute;
      top: 24px;
      right: 24px;
    }
    &-left-content {
      max-width: 90%;
      border-top: 1px solid ${(props) => props.theme.colors.light[5]};
      border-bottom: 1px solid ${(props) => props.theme.colors.light[5]};
      padding: 16px 0;
    }
    &-description {
      max-height: calc(100vh - 500px);
      height: 100%;
      overflow-y: scroll;
      margin-right: -30px;
      padding-right: 30px;
    }
  }
`;
const CardQuality = styled.div`
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 3px;
  background: #ffffff;
  padding: 2px 9px 2px 8px;
  font-size: 14px;
  color: #060606;
`;
const CardLikeLayout = styled.div`
  position: absolute;
  z-index: 20;
  background-color: #39393a;
  border-radius: 6px;
  width: 165px;
  transition: width 2s, height 4s;
`;
const CardMenu = styled.div`
  padding: 2px 4px;
`;

const TagCusTom = styled.div<Props>`
  .ant-tag {
    background: ${(props) => props.theme.colors.light[3]};
    border-radius: 20px;
    padding: 5px 9px;
    line-height: 22px;
    font-size: ${(props) => (props.fz ? `${props.fz / 16}rem` : props.theme.fontSizes[16])};
    cursor: pointer;
    color: ${(props) => props.theme.colors.dark[2]};
    &::hover {
      background: ${(props) => props.theme.colors.light[5]};
    }
  }
`;

const CardArrowRight = styled.div`
  position: absolute;
  left: -10px;
  top: 0;
  background: linear-gradient(270deg, #ffffff 54.04%, rgba(255, 255, 255, 0) 100%);
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30px;
`;

const CardArrowLeft = styled.div`
  position: absolute;
  width: 30px;
  height: 50px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: -23px;
  background: linear-gradient(100deg, #ffffff 54.04%, rgba(255, 255, 255, 0) 100%);
`;

const CardRelative = styled.div`
  position: relative;
  height: 50px;
`;

const CardSwitch = styled.div`
  .ant-switch-checked {
    background-color: ${(props) => props.theme.colors.dark[2]};
  }
`;

const CardSelect = styled.div`
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
  .ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-color: ${(props) => props.theme.colors.dark[2]};
  }

  .ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    -webkit-box-shadow: 0 0 0 2px rgb(82 82 82 / 20%);
    box-shadow: 0 0 0 2px rgb(82 82 82 / 20%);
  }
`;

const CardCustom = styled.div<Props>`
  border-radius: ${({ br }) => (br ? `${br}px` : '')};
  height: ${({ h }) => (h ? `${h}px` : '100%')};
  padding: ${({ p }) => (p ? p : 0)};
  border: 1px solid ${(props) => props.theme.colors.light[5]};
`;

const CardCustomSpace = styled.div`
  .ant-space {
    width: 100%;
  }
  .ant-space-item {
    width: 100%;
  }
`;

const CardPopup = styled.div<Props>`
  height: auto;
  width: 420px;
  border-radius: 6px;
  padding: 20px 24px;
  position: fixed;
  right: 56px;
  bottom: 50px;
  background-color: ${(props) => props.theme.colors.light[1]};
  box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.15);
  z-index: 10;

  .notify-popup-close {
    position: absolute;
    right: 16px;
    top: 16px;
    font-size: 11px;
    cursor: pointer;
  }
`;
const CardNative = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  z-index: 2;
  border: none;
  cursor: pointer;
  width: 100%;
  justify-content: flex-end;
`;

const CardNormal = styled.div`
  padding-bottom: 16px;
  word-break: break-word !important;
`;

const BorderOutline = styled(Card)<Props>`
  width: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid #eaeaea;
  margin-left: ${(props) => (props.ml ? `${props.ml / 16}rem` : '0')};
  margin-right: ${(props) => (props.mr ? `${props.mr / 16}rem` : '0')};
  margin-top: ${(props) => (props.mt ? `${props.mt / 16}rem` : '0')};
  margin-bottom: ${(props) => (props.mb ? `${props.mb / 16}rem` : '0')};
`;

const FavoriteProjectCard = styled.div<{ color: string }>`
  display: block;
  width: fit-content;
  &:hover {
    .remove_button {
      visibility: visible;
    }
  }
  .project_tooltip {
    background-color: ${(props) => (props.color ? props.color : '#1a1a1a')} !important;
    font-size: 12px;
    line-height: 12px;
    &.__react_component_tooltip {
      opacity: 1 !important;
    }
    :before {
      height: 0 !important;
    }
  }
`;

const FavoriteProjectItemCard = styled.div`
  border-radius: 4px;
  padding: 4px 6px;
  background: #f1f1f1;
  display: flex;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
  cursor: default;
  p {
    margin: 0;
    line-height: 18.2px;
    height: 18px;
    font-size: 14px;
    font-weight: 400;
    color: #1a1a1a;
    width: max-content;
  }
`;

const ProjectOptionCard = styled.div`
  display: flex;
  gap: 12px;
  p {
    margin: 0;
    line-height: 18.2px;
    height: 18px;
    font-size: 14px;
    font-weight: 400;
    color: #1a1a1a;
    width: max-content;
  }
`;

export default {
  Card,
  BottomCard,
  CardNew,
  CheckedCardNew,
  FilterBox,
  BoderBottomCard,
  CardStatus,
  SpacingCard,
  StatusCard,
  ContainerStatusCard,
  SpacingStatus,
  CommentCard,
  HagtagNameCard,
  HagtagCard,
  WillGetCard,
  StatusButtonCard,
  StatusContentCard,
  RewardDetailCard,
  CardQuality,
  CardLikeLayout,
  CardMenu,
  TagCusTom,
  CardArrowRight,
  CardRelative,
  CardArrowLeft,
  CardSwitch,
  CardSelect,
  CardCustom,
  CardCustomSpace,
  CardPopup,
  CardNative,
  CardNormal,
  BorderOutline,
  FavoriteProjectCard,
  FavoriteProjectItemCard,
  ProjectOptionCard
};
