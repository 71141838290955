export const actionTypes = {
  GET_LIST_USER_LIKE_REQUEST: 'GET_LIST_USER_LIKE_REQUEST',
  GET_LIST_USER_LIKE_SUCCESS: 'GET_LIST_USER_LIKE_SUCCESS',
  GET_LIST_USER_LIKE_ERROR: 'GET_LIST_USER_LIKE_ERROR'
};

export const requestGetListUserLike = (id: number) => ({
  type: actionTypes.GET_LIST_USER_LIKE_REQUEST,
  id
});
