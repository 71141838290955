import { styled } from '@src/styled/Theme';
const SidebarContainer = styled.div`
  .ant-layout-sider {
    background-color: ${(props) => props.theme.colors.dark[1]};
    margin: 16px 0 24px 8px;
    height: calc(100vh - 40px);
    border-radius: 12px;
    user-select: none;
    &-children {
      overflow-y: auto;
    }
    .ant-menu {
      background-color: ${(props) => props.theme.colors.dark[1]};
      height: 100%;
      border-radius: 12px;
      margin-top: 26px;
      width: 64px;
      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 24px;
        margin-bottom: 24px;
        height: 64px;
        padding-left: 0 !important;
        padding-right: 0 !important;
        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
          border-radius: 12px;
        }
        &-selected {
          background-color: rgba(255, 255, 255, 0.2) !important;
          border-radius: 12px;
        }
      }
    }
  }
  .ant-layout-sider-children {
    padding-top: 22px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export default { SidebarContainer };
