import { all, takeLatest, put, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { actionTypes } from './templateAction';
import { templateHeatMapAPI } from '@src/api/TemplateHeatMap';
import Message from 'antd/lib/message';

function* requestTemplateHeatMap(): any {
  try {
    const data: AxiosResponse = yield call(templateHeatMapAPI.getListTemplates);
    if (data) {
      yield put({
        type: actionTypes.GET_LIST_TEMPLATE_SUCCESS,
        payload: data.data
      });
    } else {
      yield put({
        type: actionTypes.GET_LIST_TEMPLATE_FAIL
      });
    }
  } catch (e) {
    console.log(e);
  }
}

function* requestCreateTemplateHeatMap(action: any): any {
  const { data } = action;
  try {
    const res = yield call(templateHeatMapAPI.createTemplate, data);
    if (res) {
      yield put({
        type: actionTypes.CREATE_TEMPLATE_SUCCESS,
        payload: res
      });
      const element = document.getElementById(`template-${res.id}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        const checkElementExistence = () => {
          const updatedElement = document.getElementById(`template-${res.id}`);
          if (updatedElement) {
            updatedElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          } else {
            setTimeout(checkElementExistence, 500);
          }
        };
        checkElementExistence();
      }
      Message.success('Create template success');
    } else {
      Message.error('Create template fail');
    }
  } catch (e) {
    console.log(e);
  }
}

function* requestDeleteTemplate(action: any) {
  const { payload } = action;
  try {
    const data: AxiosResponse<string> = yield call(templateHeatMapAPI.deleteTemplate, payload.id);
    if (data) {
      yield put({
        type: actionTypes.DELETE_TEMPLATE_SUCCESS,
        id: payload.id
      });
      Message.success('Delete template success');
    } else {
      Message.error('Delete template failed');
    }
  } catch (e) {
    console.log(e);
  }
}

function* watchRequestTemplate() {
  yield takeLatest(actionTypes.GET_LIST_TEMPLATE_REQUEST, requestTemplateHeatMap);
}

function* watchRequestCreateTemplate() {
  yield takeLatest(actionTypes.CREATE_TEMPLATE_REQUEST, requestCreateTemplateHeatMap);
}

function* watchRequestDeleteTemplate() {
  yield takeLatest(actionTypes.DELETE_TEMPLATE_REQUEST, requestDeleteTemplate);
}

export function* templateSaga() {
  yield all([watchRequestTemplate(), watchRequestCreateTemplate(), watchRequestDeleteTemplate()]);
}
