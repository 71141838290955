import { AxiosResponse } from 'axios';
import { appAxios } from '@src/api/appAxios';
import { IrequestData } from 'src/interface/INewsState';
import { BOOK_MARK } from 'src/common/constants';
import handleError from './handleError';

export const NewsApi = {
  getListNews: async (requestData: IrequestData) => {
    try {
      const { page, keyword } = requestData;
      const paramPage = page ? 'page=' + page + '&limit=6' : '';
      const paramKeywork = keyword
        ? keyword === BOOK_MARK
          ? `${BOOK_MARK}=true`
          : 'keyword=' + keyword
        : '';
      const { data }: AxiosResponse = await appAxios.get(
        `posts/v2/news?${paramPage + paramKeywork}`
      );
      return data && data.code === 200 && data.data.news ? data.data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      // throw error;
    }
  }
};
