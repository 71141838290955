import { appAxios } from '@src/api/appAxios';
import { ILateOffDayState } from '@src/interface/ILateOffDay';
import dayjs from 'dayjs';
import handleError from './handleError';

export const LateOffDayAPI = {
  getLateOffDay: async (): Promise<any> => {
    try {
      const today = dayjs().format('YYYY-MM-DD');
      const params = `time=${today}&fields=edited,editing&scope=all`;

      const data: ILateOffDayState = await appAxios.get(`v1/offworks?${params}`);
      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      // throw error;
    }
  }
};
