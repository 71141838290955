import { IdataProfile } from '../../interface/IProfileState';
import { IdetailBasic } from '../../interface/IDetailState';

export const actionTypes = {
  REQUEST_ORGANIZER_MENTION: 'REQUEST_ORGANIZER_MENTION',
  GET_ORGANIZER_MENTION_SUCCESS: 'GET_ORGANIZER_MENTION_SUCCESS',
  GET_ORGANIZER_MENTION_FALL: 'GET_ORGANIZER_MENTION_FALL',
  PUSH_ACTIVE_TAB: 'PUSH_ACTIVE_TAB',
  SAVE_ACTIVE_TAB: 'SAVE_ACTIVE_TAB',
  PUSH_DATE_FROM_TO: 'PUSH_DATE_FROM_TO',
  SAVE_DATE_FROM_TO: 'SAVE_DATE_FROM_TO',
  PUSH_LIST_MENTION: 'PUSH_LIST_MENTION',
  SAVE_LIST_MENTION: 'SAVE_LIST_MENTION',
  SAVE_LOCATION: '@@SAVE_LOCATION',
  SET_EDITING_DATA: 'SET_EDITING_DATA',
  CLEAR_DATA: 'CLEAR_DATA'
};
export const actionOrganizerMention = (): { type: string } => ({
  type: actionTypes.REQUEST_ORGANIZER_MENTION
});
export const actionPushActiveTab = (activeTab: number): { type: string; activeTab: number } => ({
  type: actionTypes.PUSH_ACTIVE_TAB,
  activeTab
});
export const actionPushListMention = (
  organizer: number[],
  selectedOrganizersList: IdataProfile[]
): { type: string; organizer: number[]; selectedOrganizersList: IdataProfile[] } => ({
  type: actionTypes.PUSH_LIST_MENTION,
  organizer,
  selectedOrganizersList
});
export const actionPushDateFromTo = (dataFromTo: {
  [key: string]: any;
}): { type: string; dataFromTo: { [key: string]: any } } => ({
  type: actionTypes.PUSH_LIST_MENTION,
  dataFromTo
});
export const setLocation = (keyword: string): { type: string; keyword: string } => ({
  type: actionTypes.SAVE_LOCATION,
  keyword
});
export const actionSetEditingData = (
  editingData: IdetailBasic
): { type: string; editingData: IdetailBasic } => ({
  type: actionTypes.SET_EDITING_DATA,
  editingData
});
export const clearData = (): { type: string } => ({
  type: actionTypes.CLEAR_DATA
});
