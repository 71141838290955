import React from 'react';

const LogoIcon = () => {
  return (
    <svg width="66" height="19" viewBox="0 0 66 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M32.0458 16.561H34.8841V6.15401V3.6311H27V6.15401H32.0458V16.561Z" fill="white" />
      <path
        d="M48.0253 13.933H42.559V11.3049H46.9741V8.67692H42.559V6.25913H47.7099V3.6311H39.7207V16.561H48.0253V13.933Z"
        fill="white"
      />
      <path
        d="M64.213 14.5633C65.2642 13.3019 65.8949 11.8302 65.8949 10.0431C65.8949 6.15363 62.7413 3 58.8518 3C54.5418 3 51.0729 6.9946 52.0189 11.5148C52.5445 14.1428 54.647 16.2453 57.275 16.7709C57.8006 16.876 58.3262 16.876 58.8518 16.876H66V14.3531H64.213V14.5633ZM62.9515 11.5148C62.531 12.7763 61.4798 13.8275 60.2184 14.248C57.275 15.194 54.5418 12.9865 54.5418 10.1482C54.5418 7.73045 56.434 5.83827 58.8518 5.83827C61.6901 5.83827 63.8976 8.57142 62.9515 11.5148Z"
        fill="white"
      />
      <path
        d="M18.8129 8.00244V16.172C18.8129 17.2924 17.926 18.1793 16.8056 18.1793H2.14708C1.30679 18.1793 0.559858 17.6191 0.27976 16.8722C0.186394 16.6388 0.139709 16.4054 0.139709 16.172V8.00244H18.8129Z"
        fill="white"
      />
      <path
        d="M18.8129 8.00244V16.172C18.8129 17.2924 17.926 18.1793 16.8056 18.1793H2.14708C1.30679 18.1793 0.559858 17.6191 0.27976 16.8722C0.186394 16.6388 0.139709 16.4054 0.139709 16.172V8.00244H18.8129Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M6.62915 3.38084V3.1941V2.26044C6.62915 1.5602 7.18935 1 7.88959 1H11.0174C11.7176 1 12.2778 1.5602 12.2778 2.26044V3.1941V3.38084"
        stroke="white"
        strokeMiterlimit="10"
      />
      <path
        d="M19 4.5482V8.28284C19 8.46957 19 8.6563 18.9533 8.84304C18.9066 9.02977 18.8599 9.2165 18.8133 9.35655C18.3931 10.4303 17.3194 11.1772 16.059 11.1772H2.89435C2.4742 11.1772 2.05405 11.0838 1.68059 10.8971C0.980343 10.5703 0.466828 10.0101 0.186729 9.30987C0.140046 9.12313 0.0933654 8.98309 0.0466823 8.79635C0.0466823 8.70299 0 8.60962 0 8.51626C0 8.42289 0 8.32952 0 8.23616V4.50151C0 4.31478 0.0466816 4.12805 0.0933647 3.988C0.0933647 3.94131 0.140047 3.89463 0.140047 3.84795C0.18673 3.75458 0.280096 3.61453 0.373462 3.52117C0.606878 3.28775 0.980341 3.14771 1.35381 3.14771H17.6929C18.3931 3.19439 19 3.80127 19 4.5482Z"
        fill="#FCFCFC"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="9.48346"
          y1="23.1269"
          x2="9.48346"
          y2="-1.20719"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="0.1007" stopColor="#F2F2F2" stopOpacity="0.1007" />
          <stop offset="0.2889" stopColor="#CFCECE" stopOpacity="0.2889" />
          <stop offset="0.5443" stopColor="#979595" stopOpacity="0.5443" />
          <stop offset="0.8537" stopColor="#4A4647" stopOpacity="0.8537" />
          <stop offset="1" stopColor="#231F20" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const ReceivedPointsIcon = () => {
  return (
    <svg width="10" height="12" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.35355 0.646446C4.15829 0.451184 3.84171 0.451184 3.64645 0.646446L0.464466 3.82843C0.269204 4.02369 0.269204 4.34027 0.464466 4.53553C0.659728 4.7308 0.976311 4.7308 1.17157 4.53553L4 1.70711L6.82843 4.53553C7.02369 4.7308 7.34027 4.7308 7.53553 4.53553C7.7308 4.34027 7.7308 4.02369 7.53553 3.82843L4.35355 0.646446ZM4.5 10L4.5 1L3.5 1L3.5 10L4.5 10Z"
        fill="#87C289"
      />
    </svg>
  );
};

const GivenPointsIcon = () => {
  return (
    <svg width="10" height="12" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.64645 9.35355C3.84171 9.54882 4.15829 9.54882 4.35355 9.35355L7.53553 6.17157C7.7308 5.97631 7.7308 5.65973 7.53553 5.46447C7.34027 5.2692 7.02369 5.2692 6.82843 5.46447L4 8.29289L1.17157 5.46447C0.976311 5.2692 0.659729 5.2692 0.464466 5.46447C0.269204 5.65973 0.269204 5.97631 0.464466 6.17157L3.64645 9.35355ZM3.5 2.18557e-08L3.5 9L4.5 9L4.5 -2.18557e-08L3.5 2.18557e-08Z"
        fill="#EB5757"
      />
    </svg>
  );
};

export { LogoIcon, ReceivedPointsIcon, GivenPointsIcon };
