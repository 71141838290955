import { styled } from 'src/styled/Theme';
const DropDownSearchContainer = styled.div`
  position: relative;
  .ant-select {
    width: 100%;
    &-selector {
      height: 40px;
      width: 100%;
      &:focus {
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08) !important;
        border-color: rgba(0, 0, 0, 0.8) !important;
      }
      &:hover {
        border-width: 1px;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08) !important;
        border-color: rgba(0, 0, 0, 0.4) !important;
      }
    }
    &-arrow {
      color: ${(props) => props.theme.colors.dark[3]};
      width: 10px;
      height: 5px;
    }
  }
  .ant-select-dropdown {
    top: 60px !important;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08) !important;
  }
`;
export default { DropDownSearchContainer };
