import { IannouncementsState, IrequestData } from 'src/interface/IAnnouncementsState';
export const actionTypes = {
  REQUEST_DATA_LIST_ANNOUNCEMENTS: 'REQUEST_DATA_LIST_ANNOUNCEMENTS',
  GET_DATA_LIST_ANNOUNCEMENTS: 'GET_DATA_LIST_ANNOUNCEMENTS',
  GET_DATA_LIST_ANNOUNCEMENTS_NEXT_PAGE: 'GET_DATA_LIST_ANNOUNCEMENTS_NEXT_PAGE',
  GET_DATA_LIST_ANNOUNCEMENTS_SUCCESS: 'GET_DATA_LIST_ANNOUNCEMENTS_SUCCESS',
  GET_DATA_LIST_ANNOUNCEMENTS_FALL: 'GET_DATA_LIST_ANNOUNCEMENTS_FALL'
};
export const requestListAnnouncements = (requestData: IrequestData) => ({
  type: actionTypes.REQUEST_DATA_LIST_ANNOUNCEMENTS,
  requestData
});

export const getDataListAnnouncement = (data: IannouncementsState) => ({
  type: actionTypes.GET_DATA_LIST_ANNOUNCEMENTS,
  data
});
export const getDataNextListAnnouncement = (data: IannouncementsState) => ({
  type: actionTypes.GET_DATA_LIST_ANNOUNCEMENTS_NEXT_PAGE,
  data
});
