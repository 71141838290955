import { actionTypes } from 'src/activities/NewsEventDetail/DetailAction';
import { IdetailState } from 'src/interface/IDetailState';

const initialState: IdetailState = {
  detail: {
    id: 0,
    authorId: 0,
    authorType: '',
    category: '',
    kind: 0,
    title: '',
    content: '',
    newsLocation: '',
    starttime: '',
    endtime: '',
    createdAt: '',
    updatedAt: '',
    image: '',
    tags: [],
    organizers: [],
    image_id: 0
  },
  review: {
    isRead: false,
    isLiked: false,
    isBookmarked: false,
    isFollowed: false
  },
  author: {
    id: 0,
    avatar_url: '',
    username: '',
    name: '',
    team: ''
  },
  isLoading: false,
  likeCount: 0,
  commentCount: 0,
  readCount: 0,
  totalPoint: 0,
  isLoadingComment: false,
  listComment: [],
  hasMoreComment: false,
  listLikePost: [],
  listReadPost: [],
  listAddPoint: [],
  limitListComment: 0,
  hasCommentBottom: false
};

export function detailReducer(state = initialState, action: any) {
  const { data } = action;
  switch (action.type) {
    case actionTypes.REQUEST_INFOR_EVENT: {
      return {
        ...state,
        isLoading: true
      };
    }
    case actionTypes.REQUEST_INFOR_DETAIL: {
      return {
        ...state,
        isLoading: true
      };
    }
    case actionTypes.GET_INFOR_DETAIL: {
      return {
        ...state,
        isLoading: false,
        detail: {
          id: data.id,
          authorId: data.author_id,
          authorType: data.author_type,
          kind: data.kind,
          title: data.title,
          content: data.content,
          newsLocation: data.location,
          image: data.image,
          starttime: data.starttime,
          endtime: data.endtime,
          createdAt: data.created_at,
          updatedAt: data.updated_at,
          tags: data.tags,
          organizers: data.organizers,
          image_id: data.image_id,
          metadata: data.metadata
        },
        likeCount: data.like_count,
        commentCount: data.comment_count,
        readCount: data.read_count,
        totalPoint: data.total_point,
        review: {
          isRead: data.review.is_read,
          isLiked: data.review.is_liked,
          isBookmarked: data.review.is_bookmarked,
          isFollowed: data.review.is_followed
        },
        author: {
          id: data.author.id,
          avatar_url: data.author.avatar_url,
          username: data.author.username,
          name: data.author.name,
          team: data.author.team
        }
      };
    }
    case actionTypes.REQUEST_LIST_COMMENT: {
      return {
        ...state,
        limitListComment: action.limit,
        isLoadingComment: true
      };
    }
    case actionTypes.GET_LIST_COMMENT: {
      return {
        ...state,
        isLoadingComment: false,
        listComment: data,
        hasMoreComment: action.moreComment
      };
    }
    case actionTypes.REACT_POSTS: {
      return {
        ...state,
        likeCount: state.likeCount + action.numberLiked,
        review: {
          ...state.review,
          isLiked: action.setLiked
        }
      };
    }
    case actionTypes.BOOKMARK_POSTS: {
      return {
        ...state,
        review: {
          ...state.review,
          isBookmarked: action.setBookmarkPost
        }
      };
    }
    case actionTypes.READ_POST_DONE: {
      if (action.data === state.detail.id) {
        return state;
      }

      return {
        ...state,
        readCount: state.readCount + 1,
        review: {
          ...state.review,
          isRead: true
        }
      };
    }
    case actionTypes.GET_LIST_USER_LIKE: {
      return {
        ...state,
        isLoading: false,
        listLikePost: data.users
      };
    }
    case actionTypes.GET_LIST_USER_READ: {
      return {
        ...state,
        isLoading: false,
        listReadPost: data.users
      };
    }
    case actionTypes.GET_LIST_TRANSACTION: {
      return {
        ...state,
        listAddPoint: data
      };
    }
    case actionTypes.UPDATE_LIST_COMMENT: {
      return {
        ...state,
        listComment: data
      };
    }
    case actionTypes.TAKE_WS_ITEM_COMMENT: {
      return {
        ...state,
        listComment: action.listComment,
        commentCount: state.commentCount + 1
      };
    }
    case actionTypes.HAS_WS_ITEM_COMMENT: {
      return {
        ...state,
        hasCommentBottom: true
      };
    }
    case actionTypes.CLICK_WS_COMMENT_BOTTOM: {
      return {
        ...state,
        hasCommentBottom: false
      };
    }
    default:
      return state;
  }
}
