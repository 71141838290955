import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { ForgotPasswordApi } from 'src/api/ForgotPasswordApi';
import { actionTypes } from 'src/resetPassword/resetPasswordAction';
import { setError, setLoading, setSuccess } from 'src/common/commonAction';

function* requestForgotPassword(action) {
  const { values } = action;
  try {
    yield put(setLoading());
    const { data }: AxiosResponse = yield call(ForgotPasswordApi.postResetPassword, values);
    if (data) {
      const { status } = data;

      yield put({
        type: actionTypes.RESET_PASSWORD_SUCCESS,
        status
      });
      yield put(setSuccess());
    } else {
      yield put({ type: actionTypes.RESET_PASSWORD_FAILURE });
      yield put(setError());
    }
  } catch (error) {
    yield put({ type: actionTypes.RESET_PASSWORD_FAILURE });
    yield put(setError());
  }
}

export function* resetPasswordSaga() {
  yield takeLatest(actionTypes.RESET_PASSWORD_REQUEST, requestForgotPassword);
}
