import { all, takeLatest, put, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { actionTypes } from './searchheaderAction';
import { HashTagApi } from 'src/api/HashTagApi';
function* requestDataListHashTag() {
  try {
    const data: AxiosResponse = yield call(HashTagApi.getListHashTag);
    if (data) {
      yield put({ type: actionTypes.GET_DATA_LIST_HASHTAG_SUCCESS, data });
    } else {
      yield put({ type: actionTypes.GET_DATA_LIST_HASHTAG_FALL });
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_DATA_LIST_HASHTAG_FALL });
  }
}

function* watchRequestListHashTag() {
  yield takeLatest(actionTypes.REQUEST_DATA_LIST_HASHTAG, requestDataListHashTag);
}

export function* searchheaderSaga() {
  yield all([watchRequestListHashTag()]);
}
