import { styled } from 'src/styled/Theme';

const PieChartContainer = styled.div`
  width: 90px;
  height: 90px;
  position: relative;
  margin: auto;
`;

const CenterLabel = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: ${(props) => props.theme.fontSizes[16]};
  color: ${(props) => props.theme.colors.dark[4]};
`;
export default { PieChartContainer, CenterLabel };
