import styled from 'styled-components';

interface Props {
  visibility?: boolean;
  isActive?: boolean;
  opacity?: number;
}

const ScrollMenuContainer = styled.div<Props>`
  position: absolute;
  top: 60%;
  right: 2%;
  background-color: rgba(0, 21, 41, 0.8);
  overflow-x: hidden;
  text-align: center;
  border-radius: 20px;
  width: 130px;
  opacity: ${(props) => (props.opacity ? props.opacity : '0')}%;
  display: ${(props) => (props.visibility ? 'initial' : 'none')};
`;

const ScrollMenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 0 10px;
  overflow: hidden;
`;
const ScrollMenuList = styled.ul`
  margin: 0;
  list-style: none;
  padding: 0;
`;

const ScrollMenuItem = styled.li<Props>`
  margin: 20px 0;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  transition: font-size 0.2s;
  height: 20px;

  &:hover {
    font-size: 14px;
    font-weight: 800;
  }

  a {
    color: rgba(255, 255, 255, 0.8);
    transition: color 0.2s;
  }

  ${(props) =>
    props.isActive
      ? 'font-size: 14px; font-weight: 800; a{color: rgba(255, 255, 255, 0.9);};'
      : ''};

  a:hover {
    color: white;
  }
`;

export { ScrollMenuContainer, ScrollMenuWrapper, ScrollMenuList, ScrollMenuItem };
