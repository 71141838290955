import { IitemComment } from 'src/interface/IDetailState';

export const actionTypes = {
  REQUEST_INFOR_DETAIL: 'REQUEST_INFOR_DETAIL',
  GET_INFOR_DETAIL: 'GET_INFOR_DETAIL',
  REQUEST_INFOR_EVENT: 'REQUEST_INFOR_EVENT',
  GET_INFOR_EVENT: 'GET_INFOR_EVENT',
  REQUEST_INITIAL_LIST_COMMENT: 'REQUEST_INITIAL_LIST_COMMENT',
  REQUEST_LIST_COMMENT: 'REQUEST_LIST_COMMENT',
  GET_LIST_COMMENT: 'GET_LIST_COMMENT',
  REACT_POSTS: 'REACT_POSTS',
  BOOKMARK_POSTS: 'BOOKMARK_POSTS',
  READ_POST_DONE: 'READ_POST_DONE',
  REQUEST_LIST_USER_READ: 'REQUEST_LIST_USER_READ',
  GET_LIST_USER_READ: 'GET_LIST_USER_READ',
  REQUEST_LIST_USER_LIKE: 'REQUEST_LIST_USER_LIKE',
  GET_LIST_USER_LIKE: 'GET_LIST_USER_LIKE',
  PUT_EDIT_COMMENT: 'PUT_EDIT_COMMENT',
  LIKE_COMMENT: 'LIKE_COMMENT',
  LIKE_COMMENT_SUCCESS: 'LIKE_COMMENT_SUCCESS',
  REQUEST_LIST_TRANSACTION: 'REQUEST_LIST_TRANSACTION',
  GET_LIST_TRANSACTION: 'GET_LIST_TRANSACTION',
  UPDATE_LIST_COMMENT: 'UPDATE_LIST_COMMENT',
  WS_ITEM_COMMENT: 'WS_ITEM_COMMENT',
  TAKE_WS_ITEM_COMMENT: 'TAKE_WS_ITEM_COMMENT',
  HAS_WS_ITEM_COMMENT: 'HAS_WS_ITEM_COMMENT',
  CLICK_WS_COMMENT_BOTTOM: 'CLICK_WS_COMMENT_BOTTOM'
};

export const requestInforNews = (
  typeDetail: string,
  params: string,
  hasNoti = false,
  pathname: string
) => ({
  type: actionTypes.REQUEST_INFOR_DETAIL,
  typeDetail,
  params,
  hasNoti,
  pathname
});

export const requestInforEvent = (data: number) => ({
  type: actionTypes.REQUEST_INFOR_EVENT,
  data
});

export const getInforNews = (data: any) => ({
  type: actionTypes.GET_INFOR_DETAIL,
  data
});

export const getInforEvent = (data: any) => ({
  type: actionTypes.GET_INFOR_EVENT,
  data
});

export const requestListComment = (
  data: number | string,
  limit: number,
  oldListComment: Array<IitemComment>
) => ({
  type: actionTypes.REQUEST_LIST_COMMENT,
  data,
  limit,
  oldListComment
});
export const requestInitialListComment = (data: number | string) => ({
  type: actionTypes.REQUEST_INITIAL_LIST_COMMENT,
  data
});
export const getListComment = (data: any, moreComment: boolean) => ({
  type: actionTypes.GET_LIST_COMMENT,
  data,
  moreComment
});

export const reactPosts = (numberLiked: number, setLiked: boolean, idPost: string) => ({
  type: actionTypes.REACT_POSTS,
  numberLiked,
  idPost,
  setLiked
});

export const bookmarkPosts = (
  setBookmarkPost: boolean,
  idPost: string,
  shouldRemoveItem?: boolean
) => ({
  type: actionTypes.BOOKMARK_POSTS,
  setBookmarkPost,
  idPost,
  shouldRemoveItem
});

export const readPosts = (data: string) => ({
  type: actionTypes.READ_POST_DONE,
  data
});

export const requestListUserLike = (data: string, typeInteractive: string) => ({
  type: actionTypes.REQUEST_LIST_USER_LIKE,
  data,
  typeInteractive
});

export const getListUserLike = (data: any) => ({
  type: actionTypes.GET_LIST_USER_LIKE,
  data
});

export const requestListUserRead = (data: string, typeInteractive: string) => ({
  type: actionTypes.REQUEST_LIST_USER_READ,
  data,
  typeInteractive
});

export const getListUserRead = (data: any) => ({
  type: actionTypes.GET_LIST_USER_READ,
  data
});

export const putEditComment = (
  idPosts: number,
  idCmt: number,
  data: any,
  typeCmt: string,
  idImg: number
) => ({
  type: actionTypes.PUT_EDIT_COMMENT,
  idPosts,
  idCmt,
  data,
  typeCmt,
  idImg
});

export const likeComment = (id: number, like: boolean) => ({
  type: actionTypes.LIKE_COMMENT,
  id,
  like
});

export const requestListTransaction = (idPost: string) => ({
  type: actionTypes.REQUEST_LIST_TRANSACTION,
  idPost
});

export const getListTransaction = (data: any) => ({
  type: actionTypes.GET_LIST_TRANSACTION,
  data
});

export const updateListComment = (data: any) => ({
  type: actionTypes.UPDATE_LIST_COMMENT,
  data
});

export const getItemCommentWS = (
  itemComment: IitemComment,
  listComment: Array<IitemComment>,
  isScrollBottom: boolean
) => ({
  type: actionTypes.WS_ITEM_COMMENT,
  itemComment,
  listComment,
  isScrollBottom
});

export const takeItemCommentWS = (listComment: Array<IitemComment>) => ({
  type: actionTypes.TAKE_WS_ITEM_COMMENT,
  listComment
});

export const hasCommentReceiveBottom = () => ({
  type: actionTypes.HAS_WS_ITEM_COMMENT
});

export const clickCommentReceiveBottom = () => ({
  type: actionTypes.CLICK_WS_COMMENT_BOTTOM
});
