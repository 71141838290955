import { all, takeLatest, put, call } from 'redux-saga/effects';
import { actionTypes } from './newsAction';
import { NewsApi } from 'src/api/NewsApi';
import { IdataRequest } from 'src/interface/IAnnouncementsState';

function* requestDataListEvent(action: any) {
  const { requestData } = action;
  try {
    const data: IdataRequest = yield call(NewsApi.getListNews, requestData);
    if (data) {
      if (data.page > 1) {
        yield put({ type: actionTypes.GET_DATA_LIST_NEWS_NEXT_PAGE, data });
      } else {
        yield put({ type: actionTypes.GET_DATA_LIST_NEWS_SUCCESS, data });
      }
    } else {
      yield put({ type: actionTypes.GET_DATA_LIST_NEWS_FALL });
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_DATA_LIST_NEWS_FALL });
  }
}
function* watchRequestProfile() {
  yield takeLatest(actionTypes.REQUEST_DATA_LIST_NEWS, requestDataListEvent);
}

export function* newsSaga() {
  yield all([watchRequestProfile()]);
}
