import { actionTypes } from './createAction';

const initialState = {
  data: {},
  isLoading: true,
  fileImg: null,
  isNew: false,
  isShowModal: false
};

export function createReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.IMPORT_IMG_UPLOAD: {
      return {
        ...state,
        fileImg: action.file,
        isLoading: true
      };
    }
    case actionTypes.SAVE_IMG_UPLOAD: {
      return {
        ...state,
        fileImg: action.file,
        isLoading: true
      };
    }
    case actionTypes.IMPORT_DATA_CREATE: {
      return {
        ...state
      };
    }
    case actionTypes.PREVIEW_DATA_CREATE: {
      return {
        ...state,
        data: action.requestData,
        isShowModal: true,
        isNew: action.isNew,
        base64: action.base64
      };
    }
    case actionTypes.PREVIEW_DATA_CLOSE: {
      return {
        ...state,
        isShowModal: false
      };
    }
    case actionTypes.SEND_DATA_CREATE_ANNOUNCEMENT: {
      return {
        ...state,
        isLoading: true
      };
    }
    case actionTypes.SEND_DATA_CREATE_ANNOUNCEMENT_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isLoading: false,
        basePoint: action.coefficientPoint
      };
    }
    case actionTypes.SEND_DATA_CREATE_ANNOUNCEMENT_FALL: {
      return {
        ...state,
        data: {},
        isLoading: true
      };
    }
    case actionTypes.SEND_DATA_CREATE_NEW: {
      return {
        ...state,
        isLoading: true
      };
    }
    case actionTypes.SEND_DATA_CREATE_NEW_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isLoading: false
      };
    }
    case actionTypes.SEND_DATA_CREATE_NEW_FALL: {
      return {
        ...state,
        data: {},
        isLoading: true
      };
    }
    case actionTypes.SEND_DATA_UPDATE_NEWS:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.SEND_DATA_UPDATE_NEWS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data
      };
    case actionTypes.SEND_DATA_UPDATE_NEWS_FAILED:
      return {
        ...state,
        isLoading: false,
        data: {}
      };
    case actionTypes.SEND_DATA_UPDATE_ANNOUNCEMENT:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.SEND_DATA_UPDATE_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data
      };
    case actionTypes.SEND_DATA_UPDATE_ANNOUNCEMENT_FAILED:
      return {
        ...state,
        isLoading: false,
        data: {}
      };
    default:
      return state;
  }
}
