import { actionTypes } from './rewardAction';
import { IrewardData, IrewardDetail } from 'src/interface/IRewardState';

const initialState = {
  listRewards: {},
  isLoadingListRewards: false,
  actionSuccess: true,
  rewardDetail: {},
  isLoading: true,
  isErr: false,
  listReward: [],
  nextIdx: -1,
  prevIdx: -1,
  isReDeem: 0, // 0 = don't action, 1 = success, -1 = fall
  indexDetail: 0,
  listReDeem: []
};

export function rewardsReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_LIST_REWARD_REQUEST: {
      return { ...state, isLoadingListRewards: true };
    }
    case actionTypes.GET_LIST_REWARD_SUCCESS: {
      const newData = {
        announcements: action.payload.data.rewards.map((item: IrewardData) => {
          return {
            title: item.name,
            created_at: item.created_at,
            updated_at: item.updated_at,
            image: item.image,
            id: item.id,
            tags: [
              { id: 1, name: `${item.price} POINT`, taggings_count: 0, kind: 0, color: '#0B7BFF' }
            ],
            quantity: item.quantity
          };
        }),
        count: action.payload.data.rewards.length,
        limit: action.payload.data.rewards.length + 1,
        page: 1
      };
      return {
        ...state,
        listRewards: newData,
        isLoadingListRewards: false,
        actionSuccess: true
      };
    }
    case actionTypes.REQUEST_DATA_REWARD_DETAIL: {
      return {
        ...state,
        isLoading: true,
        isErr: false
      };
    }
    case actionTypes.GET_DATA_REWARD_DETAIL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isErr: false,
        listReward: action.data
      };
    }
    case actionTypes.GET_DATA_REWARD_DETAIL_FAIL: {
      return {
        ...state,
        isLoading: false,
        isErr: true,
        listReward: []
      };
    }
    case actionTypes.GET_DATA_REWARD_DETAIL_BY_ID: {
      const listReward: IrewardDetail[] = state.listReward;
      const index = +action.index;
      const id = +action.id;
      const indexDetail =
        listReward[index] && listReward[index].id === id
          ? index
          : listReward.findIndex((valueDetail) => valueDetail.id === id);
      return {
        ...state,
        isLoading: false,
        isErr: indexDetail < 0,
        rewardDetail: indexDetail > -1 ? listReward[indexDetail] : {},
        nextIdx: listReward[indexDetail + 1] ? listReward[indexDetail + 1].id : -1,
        prevIdx: listReward[indexDetail - 1] ? listReward[indexDetail - 1].id : -1,
        indexDetail: indexDetail
      };
    }
    case actionTypes.POST_REDEEM_REWARD_DETAIL: {
      return {
        ...state,
        isReDeem: 0
      };
    }
    case actionTypes.POST_REDEEM_REWARD_DETAIL_SUCCESS: {
      return {
        ...state,
        isReDeem: 1,
        listReDeem: state.listReDeem.concat(action.payload)
      };
    }
    case actionTypes.POST_REDEEM_REWARD_DETAIL_FALL: {
      return {
        ...state,
        isReDeem: -1
      };
    }
    default:
      return state;
  }
}
