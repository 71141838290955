import { styled } from 'src/styled/Theme';

interface Props {
  isStatus?: boolean;
}

const EmojiMark = styled.button<Props>`
  width: ${({ isStatus }) => (isStatus ? '32px' : '24px')};
  height: ${({ isStatus }) => (isStatus ? '32px' : '24px')};
  border: none;
  color: #333333;
  font-size: 15px;
  margin-right: ${({ isStatus }) => (isStatus ? '9px' : '0')};
  border-radius: 50%;
  background: ${({ isStatus }) => (isStatus ? '#f5f5f5' : 'transparent')};
  outline: none;
  &:hover {
    background-color: ${(props) => props.theme.colors.light[5]};
  }
  &:before {
    content: '☺';
  }
`;

export default {
  EmojiMark
};
