import React, { useEffect } from 'react';
import { LeftBanner, TopDecoration, TopLogo } from './styled/Banners';
import Message from './components/Message';
import { useDispatch, useSelector } from 'react-redux';
import { IprofileState } from '@src/interface/IProfileState';
import { actionTypes } from '../events/eventAction';
import { IAppState } from '@src/interface/IAppState';
import { EventData, IeventState } from '@src/interface/IEventState';
import { imgs } from '@src/static';

const EventDecoration: React.FC<IprofileState> = (props) => {
  const dispatch = useDispatch();

  const eventData: IeventState = useSelector((state: IAppState) => state.eventState);
  useEffect(() => {
    dispatch({ type: actionTypes.GET_LIST_DATA_EVENT_REQUEST });
  }, [dispatch]);

  return (
    <>
      {eventData.listDataEvent.map((item: EventData) => {
        switch (item.slug) {
          case 'left': {
            return (
              <LeftBanner key={item.id} visible={true}>
                {item.url ? (
                  <a href={item.url}>
                    <img style={{ width: '100%', height: '100%' }} src={item.image_url} alt="" />
                  </a>
                ) : (
                  <img style={{ width: '100%', height: '100%' }} src={item.image_url} alt="" />
                )}
              </LeftBanner>
            );
          }
          case 'right': {
            return (
              <Message
                key={item.id}
                userName={props.data.name ? props.data.name : ''}
                wishes={item.title}
                bubbleMessageImg={imgs.BubbleMessage}
                characterCartoonImg={item.image_url}
                isTyping={true}
                link={item.url}
                messageImg={item.image_url2}
              />
            );
          }
          case 'top': {
            return (
              <TopDecoration key={item.id} visible={true} src={item.image_url}>
                <TopLogo visible={false}>
                  <img style={{ width: 'auto', height: '100px' }} src={item.image_url} alt="" />
                </TopLogo>
              </TopDecoration>
            );
          }
          default: {
            return null;
          }
        }
      })}
    </>
  );
};

export default EventDecoration;
