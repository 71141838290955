export const actionTypes = {
  REQUEST_TOTAL_POINTS_RECEIVE: 'REQUEST_TOTAL_POINTS_RECEIVE',
  GET_TOTAL_POINTS_RECEIVE: 'GET_TOTAL_POINTS_RECEIVE',
  REQUEST_TOTAL_POSTS: 'REQUEST_TOTAL_POSTS',
  GET_TOTAL_POSTS: 'GET_TOTAL_POSTS',
  REQUEST_TOP_GIVE_POINT: 'REQUEST_TOP_GIVE_POINT',
  GET_TOP_GIVE_POINT: 'GET_TOP_GIVE_POINT',
  REQUEST_TOP_RECEIVE_POINT: 'REQUEST_TOP_RECEIVE_POINT',
  GET_TOP_RECEIVE_POINT: 'GET_TOP_RECEIVE_POINT',
  FOLLOW_PROFILE: 'FOLLOW_PROFILE',
  FOLLOW_PROFILE_SUCCESS: 'FOLLOW_PROFILE_SUCCESS',
  FOLLOW_PROFILE_ERROR: 'FOLLOW_PROFILE_ERROR'
};

export const requestTotalPointsReceive = (startDate: string, endDate: string) => ({
  type: actionTypes.REQUEST_TOTAL_POINTS_RECEIVE,
  startDate,
  endDate
});
export const getTotalPointsReceive = (data: any) => ({
  type: actionTypes.GET_TOTAL_POINTS_RECEIVE,
  data
});

export const requestTotalPosts = (startDate: string, endDate: string) => ({
  type: actionTypes.REQUEST_TOTAL_POSTS,
  startDate,
  endDate
});
export const getTotalPost = (data: any) => ({
  type: actionTypes.GET_TOTAL_POSTS,
  data
});

export const requestTopGivePoint = (startDate: string, endDate: string) => ({
  type: actionTypes.REQUEST_TOP_GIVE_POINT,
  startDate,
  endDate
});
export const getTopGivePoint = (data: any) => ({
  type: actionTypes.GET_TOP_GIVE_POINT,
  data
});

export const requestTopReceivePoint = (startDate: string, endDate: string) => ({
  type: actionTypes.REQUEST_TOP_RECEIVE_POINT,
  startDate,
  endDate
});
export const getTopReceivePoint = (data: any) => ({
  type: actionTypes.GET_TOP_RECEIVE_POINT,
  data
});
export const followProfile = (id: number, follow: boolean) => ({
  type: actionTypes.FOLLOW_PROFILE,
  id,
  follow
});
