import { IdataStatus } from 'src/interface/IStatusState';
import { STATUS } from 'src/common/constants';
import { IitemComment } from 'src/interface/IDetailState';

export const actionTypes = {
  GET_LIST_STATUS_REQUEST: 'GET_LIST_STATUS_REQUEST',
  GET_LIST_STATUS_REQUEST_NEXT_PAGE: 'GET_LIST_STATUS_REQUEST_NEXT_PAGE',
  GET_LIST_STATUS_SUCESS: 'GET_LIST_STATUS_SUCESS',
  GET_LIST_STATUS_ERROR: 'GET_LIST_STATUS_ERROR',

  GET_LIST_MENTION_REQUEST: 'GET_LIST_MENTION_REQUEST',
  GET_LIST_MENTION_SUCCESS: 'GET_LIST_MENTION_SUCCESS',
  GET_LIST_MENTION_ERROR: 'GET_LIST_MENTION_ERROR',

  GET_LIST_ONLINE_REQUEST: 'GET_LIST_ONLINE_REQUEST',
  GET_LIST_ONLINE_SUCCESS: 'GET_LIST_ONLINE_SUCCESS',
  GET_LIST_ONLINE_ERROR: 'GET_LIST_ONLINE_ERROR',
  UPDATE_USER_ONLINE: 'UPDATE_USER_ONLINE',

  POST_CREATE_STATUS_REQUEST: 'POST_CREATE_STATUS_REQUEST',
  POST_CREATE_STATUS_SUCCESS: 'POST_CREATE_STATUS_SUCCESS',
  POST_CREATE_STATUS_ERROR: 'POST_CREATE_STATUS_ERROR',

  GET_LIST_FEELING_REQUEST: 'GET_LIST_FEELING_REQUEST',
  GET_LIST_FEELING_SUCCESS: 'GET_LIST_FEELING_SUCCESS',
  GET_LIST_FEELING_ERROR: 'GET_LIST_FEELING_ERROR',

  POST_LIST_COMMENT_STATUS_REQUEST: 'POST_LIST_COMMENT_STATUS_REQUEST',
  POST_LIST_COMMENT_STATUS_SUCCESS: 'POST_LIST_COMMENT_STATUS_SUCCESS',
  POST_LIST_COMMENT_STATUS_ERROR: 'POST_LIST_COMMENT_STATUS_ERROR',
  POST_LIST_COMMENT_DETAIL_SUCESS: 'POST_LIST_COMMENT_DETAIL_SUCESS',

  GET_LIST_TAG_REQUEST: 'GET_LIST_TAG_REQUEST',
  GET_LIST_TAG_SUCCESS: 'GET_LIST_TAG_SUCCESS',
  GET_LIST_TAG_ERROR: 'GET_LIST_TAG_ERROR',

  UPLOAD_IMAGE_REQUEST: 'UPLOAD_IMAGE_REQUEST',
  UPLOAD_IMAGE_SUCCESS: 'UPLOAD_IMAGE_SUCCESS',
  UPLOAD_IMAGE_ERR: 'UPLOAD_IMAGE_ERR',
  DELETE_IMAGE: 'DELETE_IMAGE',

  GET_LIST_COMMENT: 'GET_LIST_COMMENT',
  GET_LIST_COMMENT_SUCESS: 'GET_LIST_COMMENT_SUCESS',
  GET_LIST_COMMENT_EROR: 'GET_LIST_COMMENT_EROR',

  DELETE_STATUS_REQUEST: 'DELETE_STATUS_REQUEST',
  DELETE_STATUS_SUCCESS: 'DELETE_STATUS_SUCCESS',

  LIKE_COMMENT_REQUEST: 'LIKE_COMMENT_REQUEST',
  LIKE_COMMENT_SUCCESS: 'LIKE_COMMENT_SUCCESS',
  LIKE_COMMENT_ERROR: 'LIKE_COMMENT_ERROR',

  LIKE_POST_STATUS_REQUEST: 'LIKE_POST_STATUS_REQUEST',
  LIKE_POST_STATUS_SUCCESS: 'LIKE_POST_STATUS_SUCCESS',
  LIKE_POST_STATUS_ERROR: 'LIKE_POST_STATUS_ERROR',

  GET_STATUS_DETAIL: 'GET_STATUS_DETAIL',
  GET_STATUS_DETAIL_SUCCESS: 'GET_STATUS_DETAIL_SUCCESS',
  GET_STATUS_DETAIL_ERROR: 'GET_STATUS_DETAIL_ERROR',

  EDIT_NEW_COMMENT_STATUS: 'EDIT_NEW_COMMENT_STATUS',
  EDIT_STATUS_REQUEST: 'EDIT_STATUS_REQUEST',
  EDIT_STATUS_SUCCESS: 'EDIT_STATUS_SUCCESS',
  EDIT_STATUS_ERROR: 'EDIT_STATUS_ERROR',

  GET_STATUS_BY_ID: 'GET_STATUS_BY_ID',
  GET_STATUS_BY_ID_SUCCESS: 'GET_STATUS_BY_ID_SUCCESS',
  GET_STATUS_BY_ID_ERROR: 'GET_STATUS_BY_ID_ERROR',

  FOLLOW_STATUS: 'FOLLOW_STATUS',
  FOLLOW_STATUS_SUCCESS: 'FOLLOW_STATUS_SUCCESS',
  FOLLOW_STATUS_ERROR: 'FOLLOW_STATUS_ERROR',

  GET_NEW_STATUS_WS: 'GET_NEW_STATUS_WS',

  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',

  OPEN_PREVIEW_MODAL: 'OPEN_PREVIEW_MODAL',
  CLOSE_PREVIEW_MODAL: 'CLOSE_PREVIEW_MODAL',

  UPLOAD_VIDEO_REQUEST: 'UPLOAD_VIDEO_REQUEST',
  UPLOAD_VIDEO_SUCCESS: 'UPLOAD_VIDEO_SUCCESS',
  UPLOAD_VIDEO_ERROR: 'UPLOAD_VIDEO_ERROR',
  CANCEL_VIDEO_UPLOAD: 'CANCEL_VIDEO_UPLOAD',
  CREATE_VIDEO_STATUS_REQUEST: 'CREATE_VIDEO_STATUS_REQUEST',
  CREATE_VIDEO_FABRIC_REQUEST: 'CREATE_VIDEO_FABRIC_REQUEST',

  UPLOAD_PROGRESS_CHANGE: 'UPLOAD_PROGRESS_CHANGE',
  RESET_UPLOAD_STATUS: 'RESET_UPLOAD_STATUS'
};

export interface PostStatusData {
  content: string;
  mention: any;
  hashtag: any;
  point: number;
  image_id: number;
  giphy: any;
  file_id: any;
  feeling_id: number;
  video_file: any[];
  edit?: boolean;
  id?: number;
  fabric?: string;
}

export const setNewEditCommentStatusList = (newEditComment: IdataStatus[]) => ({
  type: actionTypes.EDIT_NEW_COMMENT_STATUS,
  newEditComment
});

export const requestListStatus = (
  page: number,
  limit: number,
  keyword?: any,
  sort?: string,
  isRead?: boolean
) => ({
  type: actionTypes.GET_LIST_STATUS_REQUEST,
  page,
  limit,
  keyword,
  sort,
  isRead
});

export const requestListMention = () => ({
  type: actionTypes.GET_LIST_MENTION_REQUEST
});

export const requestListOnline = () => ({
  type: actionTypes.GET_LIST_ONLINE_REQUEST
});

export const updateUserOnline = (userID: number, isOnline: boolean) => ({
  type: actionTypes.UPDATE_USER_ONLINE,
  userID,
  isOnline
});

export const postStatus = (data: PostStatusData) => {
  const {
    content,
    mention,
    hashtag,
    point,
    giphy,
    file_id,
    image_id,
    feeling_id,
    video_file,
    fabric
  } = data;

  return {
    type: actionTypes.POST_CREATE_STATUS_REQUEST,
    content,
    mention,
    hashtag,
    point,
    giphy,
    file_id,
    image_id,
    feeling_id,
    video_file,
    fabric
  };
};

export const requestListFeeling = () => ({
  type: actionTypes.GET_LIST_FEELING_REQUEST
});

export const requestCreateComment = (
  id_content: number,
  content: string,
  mention: any,
  hashtag: any,
  point: number,
  giphy: any,
  fileImg: any,
  file_ids: any,
  typeStatus?: STATUS
) => ({
  type: actionTypes.POST_LIST_COMMENT_STATUS_REQUEST,
  id_content,
  content,
  mention,
  giphy,
  fileImg,
  file_ids,
  hashtag,
  point,
  typeStatus
});

export const requestGetListStatus = () => ({
  type: actionTypes.GET_LIST_TAG_REQUEST
});

export const requestUploadImage = (image: Record<string, any>) => ({
  type: actionTypes.UPLOAD_IMAGE_REQUEST,
  image
});

export const deleteImage = () => ({
  type: actionTypes.DELETE_IMAGE
});

export const getList = (id: number) => ({
  type: actionTypes.GET_LIST_COMMENT,
  id
});

export const requestDeleteStatus = (id: number) => ({
  type: actionTypes.DELETE_STATUS_REQUEST,
  id
});

export const getStatusDetail = (id: number) => ({
  type: actionTypes.GET_STATUS_DETAIL,
  id
});

export const getStatusDetailSucess = (data: IdataStatus) => ({
  type: actionTypes.GET_STATUS_DETAIL_SUCCESS,
  data
});

export const requestEditStatus = (
  id: number,
  content: string,
  mention: any,
  hashtag: any,
  point: number,
  image_ids: any,
  giphy: any,
  feeling_id: number,
  idImagesExist: number[],
  videosEdits: number[],
  fabric?: string
) => ({
  type: actionTypes.EDIT_STATUS_REQUEST,
  id,
  content,
  mention,
  hashtag,
  point,
  image_ids,
  giphy,
  feeling_id,
  idImagesExist,
  videosEdits,
  fabric
});

export const requestGetStatusbyId = (id: number) => ({
  type: actionTypes.GET_STATUS_BY_ID,
  id
});

export const openModalAction = (id: number) => ({
  type: actionTypes.OPEN_MODAL,
  id
});

export const closeModalAction = () => ({
  type: actionTypes.CLOSE_MODAL
});

export const openPreviewModalAction = (id: number) => ({
  type: actionTypes.OPEN_PREVIEW_MODAL,
  id
});

export const closePreviewModalAction = () => ({
  type: actionTypes.CLOSE_PREVIEW_MODAL
});

export const followStatus = (id: number, follow: boolean) => ({
  type: actionTypes.FOLLOW_STATUS,
  id,
  follow
});

export const requestUploadVideo = () => ({
  type: actionTypes.UPLOAD_VIDEO_REQUEST
});

export const uploadVideoError = () => ({
  type: actionTypes.UPLOAD_VIDEO_ERROR
});

export const uploadVideoSuccess = (fileURL?: string) => ({
  type: actionTypes.UPLOAD_VIDEO_SUCCESS,
  fileURL
});

export const uploadProgressChange = (percent: number) => ({
  type: actionTypes.UPLOAD_PROGRESS_CHANGE,
  percent
});

export const resetUploadStatus = () => ({
  type: actionTypes.RESET_UPLOAD_STATUS
});

export const cancelVideoUpload = () => ({
  type: actionTypes.CANCEL_VIDEO_UPLOAD
});

export const requestUploadVideoFabric = (video_file: any[]) => ({
  type: actionTypes.CREATE_VIDEO_FABRIC_REQUEST,
  video_file
});

export const createVideoStatus = (data: PostStatusData) => {
  const {
    content,
    mention,
    hashtag,
    point,
    giphy,
    file_id,
    image_id,
    feeling_id,
    video_file,
    edit,
    id
  } = data;

  return {
    type: actionTypes.CREATE_VIDEO_STATUS_REQUEST,
    content,
    mention,
    hashtag,
    point,
    giphy,
    file_id,
    image_id,
    feeling_id,
    video_file,
    edit,
    id
  };
};
