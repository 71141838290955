export const actionTypes = {
  REQUEST_LIST_PROJECT: 'REQUEST_LIST_PROJECT',
  GET_LIST_PROJECT: 'GET_LIST_PROJECT',
  REQUEST_DATA_HEATMAP: 'REQUEST_DATA_HEATMAP',
  GET_DATA_HEATMAP: 'GET_DATA_HEATMAP',
  GET_DATE_CURRENT: 'GET_DATE_CURRENT'
};

export const requestListProject = () => ({
  type: actionTypes.REQUEST_LIST_PROJECT
});

export const getListProject = (data: any) => ({
  type: actionTypes.GET_LIST_PROJECT,
  data
});

export const requestDataHeatmap = (data: any) => ({
  type: actionTypes.REQUEST_DATA_HEATMAP,
  data
});

export const getDataHeatMap = (data: any) => ({
  type: actionTypes.GET_DATA_HEATMAP,
  data
});

export const getDateCurrent = (data: any) => ({
  type: actionTypes.GET_DATE_CURRENT,
  data
});
