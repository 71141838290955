import { IvoteSubmition, IdataPoll } from 'src/interface/IPollState';

export const actionTypes = {
  GET_CURRENT_POLLS: 'GET_CURRENT_POLLS',
  GET_CURRENT_POLLS_SUCCESS: 'GET_CURRENT_POLLS_SUCCESS',
  GET_CURRENT_POLLS_ERROR: 'GET_CURRENT_POLLS_ERROR',

  POST_POLL: 'POST_POLL',
  POST_POLL_SUCCESS: 'POST_POLL_SUCCESS',
  POST_POLL_ERROR: 'POST_POLL_ERROR',

  GET_POLL_DETAIL: 'GET_POLL_DETAIL',
  GET_POLL_DETAIL_SUCCESS: 'GET_POLL_DETAIL_SUCCESS',
  GET_POLL_DETAIL_ERRROR: 'GET_POLL_DETAIL_ERRROR',

  POST_SUMMIT_POLL: 'POST_SUMMIT_POLL',
  POST_SUMMIT_POLL_SUCCESS: 'POST_SUMMIT_POLL_SUCCESS',
  POST_SUMMIT_POLL_ERROR: 'POST_SUMMIT_POLL_ERROR',

  TOGGLE_SHOW_VOTER_POPUP: 'TOGGLE_SHOW_VOTER_POPUP'
};

/// CREATE ACTION

export const actionGetCurrentPolls = (search?: string) => ({
  type: actionTypes.GET_CURRENT_POLLS,
  search
});

export const actionPostPoll = (payload: IdataPoll) => ({
  type: actionTypes.POST_POLL,
  payload
});

export const actionGetPollDetail = (id: number) => ({
  type: actionTypes.GET_POLL_DETAIL,
  payload: { id }
});

export const actionPostSubmitPoll = (payload: { id: number; answer: IvoteSubmition }) => ({
  type: actionTypes.POST_SUMMIT_POLL,
  payload
});

export const actionToggleShowVotersPopup = (payload: any) => ({
  type: actionTypes.TOGGLE_SHOW_VOTER_POPUP,
  payload
});
