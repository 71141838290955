import { actionTypes } from './OtherProfileAction';
import { IotherProfileState } from '../../interface/IOtherProfileState';

const initialState: IotherProfileState = {
  data: {
    id: 0,
    name: '',
    email: '',
    team: '',
    username: '',
    is_leader: false,
    joined_date: '',
    avatar_url: '',
    cover_url: '',
    birthday: '',
    gender: '',
    is_new_user: false,
    personal_email: '',
    need_update_info: false,
    project_count: 0,
    is_followed: false,
    follower_count: 1,
    following_count: 0,
    full_name: '',
    remaining_points: 0,
    given_points: 0,
    used_points: 0,
    received_points: 0
  },
  dataPoint: [],
  dataPost: [],
  dataGivePoint: {
    users: [],
    total_point: 0
  },
  dataReceivePoint: {
    users: [],
    total_point: 0
  },
  isLoading: false,
  loadingCoverImg: false,
  loadingAvatarImg: false,
  loadingUserInformation: false,
  loadDataPoint: false,
  loadDataPost: false,
  loadDataGivePoint: false,
  loadDataReceivePoint: false,
  isError: false
};

export function otherProfileReducer(state = initialState, action: any) {
  const { data } = action;
  switch (action.type) {
    case actionTypes.REQUEST_OTHER_PROFILE: {
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    }
    case actionTypes.GET_OTHER_PROFILE: {
      return {
        ...state,
        data: {
          ...data
        },
        isLoading: false,
        isError: false
      };
    }
    case actionTypes.GET_OTHER_PROFILE_FAIL: {
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    }
    case actionTypes.GET_OTHER_TOTAL_POINTS_RECEIVE: {
      return {
        ...state,
        dataPoint: data,
        loadDataPoint: false
      };
    }
    case actionTypes.REQUEST_OTHER_TOTAL_POINTS_RECEIVE: {
      return {
        ...state,
        loadDataPoint: true
      };
    }
    case actionTypes.GET_OTHER_TOTAL_POSTS: {
      return {
        ...state,
        dataPost: data,
        loadDataPost: false
      };
    }
    case actionTypes.REQUEST_OTHER_TOTAL_POSTS: {
      return {
        ...state,
        loadDataPost: true
      };
    }
    case actionTypes.GET_OTHER_TOP_GIVE_POINT: {
      return {
        ...state,
        dataGivePoint: data,
        loadDataGivePoint: false
      };
    }
    case actionTypes.REQUEST_OTHER_TOP_GIVE_POINT: {
      return {
        ...state,
        loadDataGivePoint: true
      };
    }
    case actionTypes.GET_OTHER_TOP_RECEIVE_POINT: {
      return {
        ...state,
        dataReceivePoint: data,
        loadDataReceivePoint: false
      };
    }
    case actionTypes.REQUEST_OTHER_TOP_RECEIVE_POINT: {
      return {
        ...state,
        loadDataReceivePoint: true
      };
    }
    default:
      return state;
  }
}
