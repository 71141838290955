import { all, takeLatest } from 'redux-saga/effects';
import { SET_FORCE_LOGOUT } from '@src/components/LogoutModal/forceLogoutAction';

export function* forceLogoutSaga() {
  yield all([watchSetForceLogout()]);
}

export function* watchSetForceLogout() {
  yield takeLatest(SET_FORCE_LOGOUT, forceLogoutSaga);
}
