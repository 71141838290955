import { icons } from '@src/static';
import Button from 'antd/lib/button';
import styled, { css } from 'styled-components';

interface Props {
  w?: number;
  h?: number;
  isActive?: boolean;
  src?: string;
  disable?: boolean;
  bg?: string;
  br?: number;
  size?: number;
  show?: string;
  hover?: boolean;
  noneNumber?: boolean;
  ml?: number;
  padding?: string;
  mt?: number;
  fz?: number;
  fw?: number;
  color?: string;
  lineHeight?: number;
}
interface IchartProps {
  size?: number;
  rotate?: string;
  hidden?: boolean;
  src?: string;
  srcHover?: boolean;
  icon?: boolean;
}
interface ImentionImgProps {
  hover?: boolean;
  nonMr?: boolean;
  src: string;
}

interface RemoveFavoriteProjectProps {
  width?: number;
  height?: number;
  radius?: number;
  position?: string;
  right?: number;
  show?: boolean;
  bg?: string;
}

interface PostHeatmapButtonProps {
  disablePost?: boolean;
}

const DefaultBtn = styled.button<Props>`
  min-height: 30px;
  min-width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border-radius: ${({ br }) => (br ? br : 3)}px;
  cursor: pointer;
  user-select: none;
  width: ${({ w }) => (w ? `${w / 16}rem` : '100%')};
  height: ${({ h }) => (h ? `${h / 16}rem` : '1.875rem')};
  font-size: ${(props) => props.theme.fontSizes[16]};
  transition: 0.5s;
  border: none;
  &:hover {
    background-color: ${(props) => props.theme.colors.dark[4]};
  }
  :focus {
    outline: 0;
  }
`;

const DefaultButton = styled.div<Props>`
  width: ${(props) => (props.width ? `${props.width}px` : 'max-content')};
  margin-top: ${(props) => (props.mt ? `${props.mt}px` : '0')};
  padding: ${(props) => (props.padding ? props.padding : '0')};
  background-color: ${(props) => (props.disable ? '#c3c3c3' : props.bg)};
  color: ${(props) => (props.color ? props.color : '#fff')};
  border-radius: ${({ br }) => (br ? br : 0)}px;
  line-height: ${(props) => (props.lineHeight ? `${props.lineHeight}px` : '0')};
  font-size: ${(props) => (props.fz ? `${props.fz}px` : '0')};
  font-weight: ${(props) => (props.fz ? props.fw : 400)};
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: ${(props) => (props.disable ? 'none' : 'default')};
  cursor: pointer;
`;

const LightBtn = styled(DefaultBtn)`
  display: flex !important;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors.light[5]};
  color: ${(props) => (props.isActive ? props.theme.colors.light[1] : props.theme.colors.dark[4])};
  width: ${(props) => (props.w ? `${props.w}px` : '')};
  height: ${(props) => (props.h ? `${props.h}px` : '')};
  min-width: ${(props) => (props.w ? `${props.w}px` : '90px')};
  min-height: ${(props) => (props.h ? `${props.h}px` : '30px')};
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '16px')};
  background-color: ${(props) =>
    props.isActive ? props.theme.colors.dark[2] : props.theme.colors.light[1]};
  &:hover {
    background-color: ${(props) =>
      props.isActive ? props.theme.colors.dark[3] : props.theme.colors.light[5]};
  }

  ${({ disable }) =>
    disable &&
    css`
      border: 0;
      cursor: not-allowed;
      color: ${(props) => props.theme.colors.dark[6]};
      background-color: ${(props) => props.theme.colors.bg};
      &:hover {
        background-color: ${(props) => props.theme.colors.bg};
      }
    `};
`;
const DarkBtn = styled(DefaultBtn)`
  color: ${(props) => props.theme.colors.light[1]};
  background-color: ${(props) => props.theme.colors.dark[2]};
  &:hover {
    background-color: ${(props) => props.theme.colors.dark[3]};
  }
  ${({ disable }) =>
    disable &&
    css`
      border: 0;
      color: ${(props) => props.theme.colors.light[2]};
      background-color: ${(props) => props.theme.colors.dark[6]};
      &:hover {
        background-color: ${(props) => props.theme.colors.dark[6]};
      }
      cursor: initial;
    `};
`;
const CreateBtn = styled(DefaultBtn)`
  padding-left: 40px;
  position: relative;
  color: ${(props) => props.theme.colors.light[1]};
  background-color: ${(props) => props.theme.colors.dark[2]};
  margin-left: ${(props) => (props.ml ? props.ml : '0')}px;
  &:hover {
    background-color: ${(props) => props.theme.colors.dark[3]};
  }
  &:before {
    content: '';
    background-image: url('${icons.IconPlus}');
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    width: 1rem;
    height: 1rem;
    top: 50%;
    left: 20px;
    margin-top: -0.5rem;
  }
`;
const MentionBtn = styled.button<Props>`
  cursor: pointer;
  min-width: 24px;
  min-height: 24px;
  user-select: none;
  display: flex;
  border: none;
  align-items: center;
  background-color: transparent;
  background-image: url('${({ src }) => src}');
  background-position: left center;
  background-size: 24px;
  background-repeat: no-repeat;
  padding-left: ${({ noneNumber }) => (noneNumber ? `0px` : `34px`)};
  font-size: ${(props) => props.theme.fontSizes[18]};
  color: ${(props) => props.theme.colors.dark[5]};
  font-weight: ${(props) => props.theme.fontWeight.regular};
  transition: 0.5s ease;
  &:focus {
    outline: 0;
  }
  :hover {
    background-color: ${({ hover }) => (hover ? `#F5F5F5` : `transparent`)};
    border-radius: 3px;
  }
`;
const MentionImgBtn = styled.button<ImentionImgProps>`
  cursor: pointer;
  min-width: 24px;
  min-height: 24px;
  user-select: none;
  border: none;
  background-color: transparent;
  background-image: url('${({ src }) => src}');
  background-position: left center;
  background-size: 24px;
  background-repeat: no-repeat;
  transition: 0.5s ease;
  margin-right: ${({ nonMr }) => (nonMr ? '0' : `10px`)};
  &:focus {
    outline: 0;
  }
  :hover {
    background-color: ${({ hover }) => (hover ? `#F5F5F5` : `transparent`)};
    border-radius: 3px;
  }
`;
export const ButtonSimple = styled.button<IchartProps>`
  display: ${({ hidden }) => (hidden ? `none` : ``)};
  width: ${({ size }) => (size ? `${size}px` : `30px`)};
  height: ${({ size }) => (size ? `${size}px` : `30px`)};
  background-image: url('${(props) => props.src}');
  transform: ${({ rotate }) => (rotate ? `rotate(${rotate}deg);` : `none`)};
  background-repeat: no-repeat;
  background-position: center;
  outline: none;
  background-size: cover;
  background-color: #ffffff;
  border-radius: 3px;
  cursor: pointer;
  border: none;
  transition: 0.5s ease;
  :focus {
    outline: 0;
  }
  :hover {
    background-color: ${({ icon }) => (icon ? `` : `#F5F5F5`)};
    background-image: url('${({ srcHover }) => (srcHover ? srcHover : (props) => props.src)}');
  }
`;

const ButtonIcon = styled.button<Props>`
  position: relative;
  display: ${({ show }) => (show ? `none` : ``)};
  width: ${({ size }) => (size ? `${size}px` : `30px`)};
  height: ${({ size }) => (size ? `${size}px` : `30px`)};
  background-image: url('${(props) => props.src}');
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: center;
  outline: none;
  background-size: cover;
  background-color: ${({ bg }) => bg};
  border-radius: ${({ br }) => `${br}px`};
  cursor: pointer;
  border: none;
  transition: 0.5s ease;
  :focus {
    outline: 0;
  }
  margin-right: 9px;
  :hover {
    background-color: #f5f5f5;
  }
`;
export const LoadMoreBtn = styled.button`
  background-color: #333333;
  border-radius: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 12px;
  line-height: 30px;
  color: #ffffff;
  width: fit-content;
  padding: 0px 12px;
  border: none;
  animation: expandWidth 1s;
  @keyframes expandWidth {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
  :focus {
    outline: 0;
  }
  :hover {
    cursor: pointer;
  }
`;
const MentionStatusBtn = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  user-select: none;
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 9px;
  position: relative;
  background-color: ${(props) => props.theme.colors.light[3]};
  &:hover {
    background-color: ${(props) => props.theme.colors.light[5]};
  }
  &:focus {
    outline: 0;
  }
`;
const DefaultStepButton = styled.button`
  cursor: pointer;
  width: 192px;
  height: 72px;
  user-select: none;
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 41px 15px;
  color: ${(props) => props.theme.colors.dark[1]};
  &:hover {
    transition: 0.5s;
    background-color: ${(props) => props.theme.colors.dark[1]};
    color: ${(props) => props.theme.colors.light[1]};
  }
  &:focus {
    outline: 0;
  }
`;
const NextBtn = styled(DefaultStepButton)`
  padding-right: 80px;
  background-image: url(${icons.IconNextBlack});
  background-position: 70% center;
  &:hover {
    background-image: url(${icons.IconNextWhite});
  }
`;
const PreviousBtn = styled(DefaultStepButton)`
  padding-left: 80px;
  background-image: url(${icons.IconPreviousBlack});
  background-position: 20% center;
  &:hover {
    background-image: url(${icons.IconPreviousWhite});
  }
`;
const ActionBtn = styled.span<Props>`
  border: 1px solid ${(props) => props.theme.colors.light[5]};
  padding: 3px 20px;
  border-radius: 4px;
  font-size: ${(props) => props.theme.fontSizes[14]};
  color: ${(props) => (props.isActive ? props.theme.colors.dark[3] : props.theme.colors.light[2])};
  background-color: ${(props) => (props.isActive ? props.theme.colors.light[2] : `transparent`)};
  cursor: pointer;
`;

const BtnZoom = styled.button`
  border: none;
  width: 24px;
  height: 24px;
  background: none;
  &:hover {
    color: #337ab7;
  }
  cursor: pointer;
  color: ${(props) => props.theme.colors.light[1]};
  outline: none;
`;

const ButtonOutline = styled(Button)`
  width: 100%;
  height: 40px;
  margin: 5px 0;
  border-radius: 8px;
  box-sizing: border-box;
  background: transparent;
  border: 1px solid #333333;
`;
const ButtonOutlineStartVote = styled(Button)`
  width: 100%;
  height: 40px;
  margin: 5px 0;
  border-radius: 8px;
  border: none;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.colors.normal[6]};
  &:hover {
    background-color: ${(props) => props.theme.colors.normal[6]};
  }
  &:active {
    background: ${(props) => props.theme.colors.normal[6]};
  }
  &:focus {
    background: ${(props) => props.theme.colors.normal[6]};
  }
  color: ${(props) => props.theme.colors.normal[3]};
  font-size: ${(props) => props.theme.fontSizes[16]};
`;

const HyperLinkButton = styled.button`
  width: 50px;
  border: none;
  display: block;
  color: #166de0;
  cursor: pointer;
  text-align: right;
  background-color: transparent;
`;

const PostHeatmapButton = styled.div<PostHeatmapButtonProps>`
  position: inherit;
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  padding: 8px 8px 7px 10px;
  background-color: ${(props) => (props.disablePost ? '#efefef' : '#f5f5f5')};
  border-width: 0px 1px 1px 1px;
  border-style: dashed;
  border-color: #e3e3e3;
  border-radius: 0 0 4px 4px;
  width: auto;
  font-size: 12px;
  line-height: 14px;
  color: #444444;
  cursor: ${(props) => (props.disablePost ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disablePost ? '0.5' : '1')};
  p {
    margin: 0;
  }
`;

const RemoveFavoriteProject = styled.div<RemoveFavoriteProjectProps>`
  width: ${(props) => (props.width ? `${props.width}px` : '14px')};
  height: ${(props) => (props.height ? `${props.height}px` : '14px')};
  background-color: ${(props) => (props.bg ? `${props.bg}` : '#f2f2f2')};
  border-radius: ${(props) => (props.radius ? `${props.radius}px` : '2px')};
  position: ${(props) => (props.position ? `${props.position}` : 'relative')};
  right: ${(props) => (props.right ? `${props.right}px` : 'unset')};
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  cursor: pointer;
  :before {
    display: ${(props) => (props.position ? 'block' : 'none')};
    content: '';
    position: absolute;
    z-index: 2;
    right: 100%;
    top: 0;
    width: 24px;
    height: 100%;
    background-image: linear-gradient(270deg, #fff, transparent);
    pointer-events: none;
    cursor: default;
  }
`;

const RemoveFavoriteProjectItem = styled.div`
  width: 16px;
  height: 16px;
  background-color: #e5e5e5;
  border-radius: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const CloseFavoriteProjectsModal = styled.button<Props>`
  padding: 7px;
  width: ${({ w }) => (w ? `${w / 16}rem` : 'fit-content')};
  height: ${({ h }) => (h ? `${h / 16}rem` : 'fit-content')};
  border: none;
  position: absolute;
  top: 14.5px;
  right: 14px;
  border-radius: 50%;
  background: #f2f2f2;
  cursor: pointer;
  :hover {
    background-color: #e5e5e5;
  }
`;

export default {
  DefaultBtn,
  DefaultButton,
  LightBtn,
  DarkBtn,
  CreateBtn,
  ButtonSimple,
  MentionBtn,
  ButtonIcon,
  MentionImgBtn,
  MentionStatusBtn,
  NextBtn,
  PreviousBtn,
  ActionBtn,
  BtnZoom,
  ButtonOutline,
  HyperLinkButton,
  ButtonOutlineStartVote,
  PostHeatmapButton,
  RemoveFavoriteProject,
  RemoveFavoriteProjectItem,
  CloseFavoriteProjectsModal
};
