import styled from 'styled-components';

interface Props {
  src: string;
}

const ThumbnailNew = styled.div<Props>`
  height: 111px;
  width: 149px;
  overflow: hidden;
  border-radius: 10px;
  background-image: url('${({ src }) => src}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 14px;
  padding-right: 8px;
  display: flex;
  justify-content: flex-end;
`;

export default { ThumbnailNew };
