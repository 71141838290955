import { AxiosResponse } from 'axios';
import { appAxios } from '@src/api/appAxios';
import handleError from './handleError';
import { ITemplate } from '@src/interface/ITemplate';

export const templateHeatMapAPI = {
  getListTemplates: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get(`/v1/user_templates`);
      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      throw error.response;
    }
  },
  createTemplate: async (params: ITemplate) => {
    try {
      const { data }: AxiosResponse = await appAxios.post(
        `/v1/user_templates`,
        JSON.stringify(params)
      );
      // const { data }: AxiosResponse = await apiMock.post(`/create-template`, params);

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      throw error.response;
    }
  },
  updateTemplate: async (params: { id: number; name: string; content: string }) => {
    try {
      const { data }: AxiosResponse = await appAxios.put(`/v1/user_templates/${params.id}`, {
        name: params.name,
        content: params.content
      });
      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      throw error.response;
    }
  },
  deleteTemplate: async (id: number) => {
    try {
      const { data }: AxiosResponse = await appAxios.delete(`/v1/user_templates/${id}`);
      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      throw error.response;
    }
  }
};
