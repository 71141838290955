import styled from 'styled-components';

const FilterStatus = styled.div`
  * {
    transition: 0.3s ease all;
  }

  .dropdown-icon {
    transform: rotateZ(90deg);
  }

  .filter-content {
    height: 210px;
    padding-top: 32px;
    overflow: hidden;

    > div {
      padding: 6px 0;
    }
  }

  .ant-radio-group {
    width: 100%;

    .ant-radio-wrapper {
      display: block;
      padding: 5px 0;

      .ant-radio {
        float: right;
      }
    }

    .ant-radio-inner::after {
      background-color: #060606;
    }

    .ant-radio-checked .ant-radio-inner {
      border-color: #060606;
    }

    .ant-radio-checked::hover {
      border-color: #060606;
    }

    .ant-radio-inner::hover {
      border-color: #060606 !important;
      border: none !important;
    }
  }

  &.collapse {
    .dropdown-icon {
      transform: rotateZ(0deg);
    }

    .filter-content {
      height: 0;
      padding-top: 0;
    }
  }
`;

export default FilterStatus;
