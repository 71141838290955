/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useState, useEffect } from 'react';

export default function LazyLoadImage(
  imgUrl: string,
  placeholderUrl: string | any,
  lazyTarget: any,
  intersectionObserverOptions = {}
) {
  const [imgSrc, setImgSrc] = useState(placeholderUrl);
  const [setImg, IsSetImage] = useState<string>('not-loaded');

  // load image
  useEffect(() => {
    // if browser supports IntersectionObserver and lazyTarget is given
    if ('IntersectionObserver' in window && lazyTarget && lazyTarget.current instanceof Element) {
      // reload image when prop - imgUrl changed
      if (imgUrl !== imgSrc) {
        const lazyImageObserver = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // change state
              setImgSrc(imgUrl);
              IsSetImage('loaded');
              // don't need to observe anymore
              lazyImageObserver.unobserve(entry.target);
            }
          });
        }, intersectionObserverOptions);
        // start to observe element
        lazyImageObserver.observe(lazyTarget.current);
      }
    } else {
      // baseline: load image after componentDidMount
      setImgSrc(imgUrl);
    }
    // eslint-disable-next-line
  }, [imgUrl, imgSrc])

  return {
    imgSrc: imgSrc,
    css: setImg
  };
}
