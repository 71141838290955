import { Ifeedback, Imessage } from 'src/interface/IChatGptState';

export const actionTypes = {
  GET_CONVERSATION_REQUEST: '@@GET_CONVERSATION_REQUEST',
  GET_CONVERSATION_SUCCESS: '@@GET_CONVERSATION_SUCCESS',
  GET_CONVERSATION_ERROR: '@@GET_CONVERSATION_ERROR',

  DELETE_CONVERSATION_REQUEST: '@@DELETE_CONVERSATION_REQUEST',
  DELETE_CONVERSATION_SUCCESS: '@@DELETE_CONVERSATION_SUCCESS',
  DELETE_CONVERSATION_ERROR: '@@DELETE_CONVERSATION_ERROR',

  EDIT_CONVERSATION_REQUEST: '@@EDIT_CONVERSATION_REQUEST',
  EDIT_CONVERSATION_SUCCESS: '@@EDIT_CONVERSATION_SUCCESS',
  EDIT_CONVERSATION_ERROR: '@@EDIT_CONVERSATION_ERROR',

  UPDATE_CONVERSATION_REQUEST: '@@UPDATE_CONVERSATION_REQUEST',
  UPDATE_CONVERSATION_SUCCESS: '@@UPDATE_CONVERSATION_SUCCESS',

  GET_MESSAGE_GPT_REQUEST: '@@GET_MESSAGE_GPT_REQUEST',
  GET_MESSAGE_GPT_SUCCESS: '@@GET_MESSAGE_GPT_SUCCESS',
  GET_MESSAGE_GPT_ERROR: '@@GET_MESSAGE_GPT_ERROR',
  LIKE_MESSAGE_GPT_REQUEST: '@@LIKE_MESSAGE_GPT_REQUEST',
  UNLIKE_MESSAGE_GPT_REQUEST: '@@UNLIKE_MESSAGE_GPT_REQUEST',
  FEEDBACK_MESSAGE_GPT_REQUEST: '@@FEEDBACK_MESSAGE_GPT_REQUEST',
  UPDATE_FEEDBACK_MESSAGE_GPT_REQUEST: '@@UPDATE_FEEDBACK_MESSAGE_GPT_REQUEST',
  UPDATE_MESSAGE_GPT_REQUEST: '@@UPDATE_MESSAGE_GPT_REQUEST',

  RESET_MESSAGES: '@@RESET_MESSAGES',

  GET_MODELS_REQUEST: '@@GET_MODELS_REQUEST',
  GET_MODELS_SUCCESS: '@@GET_MODELS_SUCCESS',
  GET_MODELS_ERROR: '@@GET_MODELS_ERROR'
};

export const getConversation = () => ({
  type: actionTypes.GET_CONVERSATION_REQUEST
});

export const deleteConversation = (id: string, resolve: () => void) => ({
  type: actionTypes.DELETE_CONVERSATION_REQUEST,
  payload: { id, resolve }
});

export const editConversation = (params: { id: string; title: string }) => ({
  type: actionTypes.EDIT_CONVERSATION_REQUEST,
  params
});

export const updateStateConversation = (params: { id: string; title?: string }) => ({
  type: actionTypes.UPDATE_CONVERSATION_REQUEST,
  params
});

export const getMessageGPT = (id: string) => ({
  type: actionTypes.GET_MESSAGE_GPT_REQUEST,
  id
});

export const resetChatMessages = () => ({
  type: actionTypes.RESET_MESSAGES
});

export const likeMessageGPT = (params: Ifeedback) => ({
  type: actionTypes.LIKE_MESSAGE_GPT_REQUEST,
  params
});

export const unlikeMessageGPT = (params: Ifeedback) => ({
  type: actionTypes.UNLIKE_MESSAGE_GPT_REQUEST,
  params
});

export const feedbackMessageGPT = (params: {
  msg_id: string;
  feedback: string;
  tags?: string[];
}) => ({
  type: actionTypes.FEEDBACK_MESSAGE_GPT_REQUEST,
  params
});

export const updateMessageGPT = (messages: Imessage[]) => ({
  type: actionTypes.UPDATE_MESSAGE_GPT_REQUEST,
  messages
});

export const getModels = () => ({
  type: actionTypes.GET_MODELS_REQUEST
});
