import { actionTypes } from 'src/components/Chart/heatmapAction';
import { IheatmapState } from 'src/interface/IHeatmapState';

const initialState: IheatmapState = {
  data: [],
  dataResource: [],
  isLoading: false,
  timeStart: '',
  timeEnd: ''
};

export function chartReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_LIST_PROJECT: {
      return {
        ...state,
        data: action.data
      };
    }
    case actionTypes.REQUEST_DATA_HEATMAP: {
      return {
        ...state,
        isLoading: true
      };
    }
    case actionTypes.GET_DATA_HEATMAP: {
      return {
        ...state,
        dataResource: action.data,
        isLoading: false
      };
    }
    case actionTypes.GET_DATE_CURRENT: {
      return {
        ...state,
        timeStart: action.data.from_date,
        timeEnd: action.data.to_date
      };
    }
    default:
      return state;
  }
}
