import { IdataProfile } from 'src/interface/IProfileState';
import { IPasswordChanged } from '../../interface/ILoginState';

export const actionTypes = {
  REQUEST_DATA_PROFILE: 'REQUEST_DATA_PROFILE',
  GET_DATA_PROFILE_SUCCESS: 'GET_DATA_PROFILE_SUCCESS',
  GET_DATA_PROFILE_FALL: 'GET_DATA_PROFILE_FALL',
  REQUEST_CHANGE_IMG_AVATAR: 'REQUEST_CHANGE_IMG_AVATAR',
  REQUEST_CHANGE_IMG_COVER: 'REQUEST_CHANGE_IMG_COVER',
  GET_RESULT_IMG_COVER: 'GET_RESULT_IMG_COVER',
  GET_RESULT_IMG_AVATAR: 'GET_RESULT_IMG_AVATAR',
  GET_RESULT_INFOR_USER: 'GET_RESULT_INFOR_USER',
  REQUEST_TO_CHANGE_PASSWORD: 'REQUEST_TO_CHANGE_PASSWORD'
};

export const requestDataProfile = () => ({
  type: actionTypes.REQUEST_DATA_PROFILE
});

export const getDataProfileSuccess = (data: IdataProfile) => ({
  type: actionTypes.GET_DATA_PROFILE_SUCCESS,
  data
});
export const getDataProfileFall = () => ({
  type: actionTypes.GET_DATA_PROFILE_FALL
});
export const requestChangeImgAvatar = (imgAvatar: any) => ({
  type: actionTypes.REQUEST_CHANGE_IMG_AVATAR,
  imgAvatar
});
export const requestChangeImgCover = (imgCover: any) => ({
  type: actionTypes.REQUEST_CHANGE_IMG_COVER,
  imgCover
});
export const changeImgCoverSuccess = (data: any) => ({
  type: actionTypes.GET_RESULT_IMG_COVER,
  data
});
export const changeImgAvatarSuccess = (data: any) => ({
  type: actionTypes.GET_RESULT_IMG_AVATAR,
  data
});
export const resultChangeInformationUser = (data: any) => ({
  type: actionTypes.GET_RESULT_INFOR_USER,
  data
});
export const requestToChangePassword = (data: IPasswordChanged) => ({
  type: actionTypes.REQUEST_TO_CHANGE_PASSWORD,
  data
});
