export const actionTypes = {
  EDIT_DATA_HEATMAP: 'EDIT_DATA_HEATMAP',
  SET_DATE_LOCAL: 'SET_DATE_LOCAL'
};

export const editDataHeatmap = (data: any) => ({
  type: actionTypes.EDIT_DATA_HEATMAP,
  dateEdit: data.dateEdit,
  itemsEdit: data.itemsEdit
});

export const setDateLocalEdit = (dateLocal: string) => ({
  type: actionTypes.SET_DATE_LOCAL,
  dateLocal: dateLocal
});
