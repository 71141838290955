import { appAxios } from '@src/api/appAxios';
import { IformForgotPassword } from 'src/interface/IForgotPasswordState';
import { IformResetPassword } from 'src/interface/IResetPasswordState';
import handleError from './handleError';

export const ForgotPasswordApi = {
  postForgotPassword: async (formData: IformForgotPassword) => {
    try {
      const data = await appAxios.post(`v1/user/forgot-password`, formData);
      return data ? data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      // throw error;
    }
  },
  postResetPassword: async (formData: IformResetPassword) => {
    try {
      const data = await appAxios.post(`v1/user/reset-password`, formData);
      return data ? data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      // throw error;
    }
  }
};
