import { all, takeLatest, put, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { actionTypes, changeImgCoverSuccess, changeImgAvatarSuccess } from './headerAction';
import { ProfileApi } from 'src/api/ProfileApi';

import { setError, setSuccess, setLoading } from 'src/common/commonAction';

function* requestDataProfile() {
  try {
    const data: AxiosResponse = yield call(ProfileApi.getMyProfile);
    if (data) {
      yield put({ type: actionTypes.GET_DATA_PROFILE_SUCCESS, data });
    } else {
      yield put({ type: actionTypes.GET_DATA_PROFILE_FALL });
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_DATA_PROFILE_FALL });
  }
}

function* requestChangeImgCover(action: any) {
  const { imgCover } = action;
  try {
    const data: AxiosResponse = yield call(ProfileApi.editImgCover, imgCover, 'cover');
    if (data) {
      yield put(changeImgCoverSuccess(data));
    }
  } catch (error) {
    yield put(setError());
  }
}

function* requestChangeImgAvatar(action: any) {
  const { imgAvatar } = action;
  try {
    const data: AxiosResponse = yield call(ProfileApi.editImgCover, imgAvatar, 'avatar');
    if (data) {
      yield put(changeImgAvatarSuccess(data));
    }
  } catch (error) {
    yield put(setError());
  }
}

function* requestToChangePassword(action: any) {
  const { data } = action;
  yield put(setLoading());
  const response: AxiosResponse = yield call(ProfileApi.changePassword, data);
  try {
    if (response) {
      yield put(setSuccess());
    } else {
      yield put(setError());
    }
  } catch (error) {
    yield put(setError());
    console.log(error);
  }
}

function* watchRequestProfile() {
  yield takeLatest(actionTypes.REQUEST_DATA_PROFILE, requestDataProfile);
}

function* watchRequestChangeImgCover() {
  yield takeLatest(actionTypes.REQUEST_CHANGE_IMG_COVER, requestChangeImgCover);
}

function* watchRequestChangeImgAvatar() {
  yield takeLatest(actionTypes.REQUEST_CHANGE_IMG_AVATAR, requestChangeImgAvatar);
}

function* watchRequestToChangePassword() {
  yield takeLatest(actionTypes.REQUEST_TO_CHANGE_PASSWORD, requestToChangePassword);
}

export function* headerSaga() {
  yield all([
    watchRequestProfile(),
    watchRequestChangeImgCover(),
    watchRequestChangeImgAvatar(),
    watchRequestToChangePassword()
  ]);
}
