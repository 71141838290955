import { AxiosResponse } from 'axios';
import { appAxios } from '@src/api/appAxios';
import { IdataPoll } from '@src/interface/IPollState';
import handleError from './handleError';

export const PollApi = {
  model: 'poll',
  version: 'v2',
  postPoll: async (action: { type: string; payload: IdataPoll }): Promise<any> => {
    const { payload } = action;
    try {
      const { data }: AxiosResponse = await appAxios.post(
        `${PollApi.model}/${PollApi.version}/polls`,
        {
          ...payload
        }
      );
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      // const err: AxiosError = error;
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      throw error.response;
    }
  },
  getPollDetail: async (id: string): Promise<any> => {
    try {
      const { data }: AxiosResponse = await appAxios.get(
        `${PollApi.model}/${PollApi.version}/polls/${id}`
      );
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      // const err: AxiosError = error;
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      throw error.response;
    }
  },
  getCurrentPolls: async (search?: string): Promise<any> => {
    try {
      const urlWithParams = new URL(
        `${PollApi.model}/${PollApi.version}/polls`,
        process.env.NEXT_PUBLIC_BASE_URL
      );

      if (search) {
        urlWithParams.searchParams.append('search', search.toString());
      }

      const { data }: AxiosResponse = await appAxios.get(urlWithParams.href);
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      // const err: AxiosError = error;
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      throw error.response;
    }
  },
  postSubmitPoll: async (
    id: string | number,
    answer: {
      option_ids: number[];
      other_content?: string;
    }
  ): Promise<any> => {
    try {
      const { data }: AxiosResponse = await appAxios.post(
        `${PollApi.model}/${PollApi.version}/polls/${id}/submit`,
        answer
      );
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      // const err: AxiosError = error;
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      throw error.response;
    }
  }
};
