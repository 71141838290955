import { AxiosError, AxiosResponse } from 'axios';
import API_ROUTES from '@src/common/apiRoutes';
import getConfig from 'next/config';
import { Iconversation, RATING } from '@src/interface/IChatGptState';
import { appAxios } from './appAxios';
import { CHATGPT_BASE_URL } from '@src/common/constants';

const askGptUrl = CHATGPT_BASE_URL;
console.log(`${askGptUrl}/${API_ROUTES.CONVERSATION}`);

export const GptAPI = {
  createConversation: async (modelId: string) => {
    console.log(`${askGptUrl}/${API_ROUTES.CONVERSATION}?model_id=${modelId}`);
    try {
      const { data }: AxiosResponse = await appAxios.post(
        `${askGptUrl}/${API_ROUTES.CONVERSATION}?model_id=${modelId}`
      );
      return data as Iconversation;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  },
  getListConversation: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get(`${askGptUrl}/${API_ROUTES.CONVERSATION}`);
      return data;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  },
  deleteConversation: async (id: string) => {
    try {
      const response: AxiosResponse = await appAxios.delete(
        `${askGptUrl}/${API_ROUTES.CONVERSATION_DETAIL(id)}`
      );
      return response;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  },
  editConversation: async (params: { id: string; title: string }) => {
    try {
      const response: AxiosResponse = await appAxios.patch(
        `${askGptUrl}/${API_ROUTES.CONVERSATION_DETAIL(params.id)}`,
        { title: params.title }
      );
      return response;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  },
  getListMessage: async (conversation_id: string) => {
    try {
      const { data }: AxiosResponse = await appAxios.post(`${askGptUrl}/${API_ROUTES.GPT_MESAGE}`, {
        conversation_id: conversation_id
      });
      return data;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  },
  likeMsgGPT: async (msg_id: string) => {
    try {
      const { data }: AxiosResponse = await appAxios.post(
        `${askGptUrl}/${API_ROUTES.GPT_FEEDBACK(msg_id)}`,
        {
          rating: RATING.POSITIVE
        }
      );
      return data;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  },
  unlikeMsgGPT: async (msg_id: string) => {
    try {
      const { data }: AxiosResponse = await appAxios.post(
        `${askGptUrl}/${API_ROUTES.GPT_FEEDBACK(msg_id)}`,
        {
          rating: RATING.NEGATIVE
        }
      );
      return data;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  },
  feedbackMsgGPT: async (params: { msg_id: string; feedback: string; tags?: string[] }) => {
    try {
      const { data }: AxiosResponse = await appAxios.put(
        `${askGptUrl}/${API_ROUTES.GPT_FEEDBACK(params.msg_id)}`,
        {
          feedback: params.feedback,
          tags: params?.tags
        }
      );
      return data;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  },
  checkLimitConversations: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get(
        `${askGptUrl}/${API_ROUTES.LIMIT_CONVERSATIONS}`
      );
      return data;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  },
  getListModels: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get(`${askGptUrl}/${API_ROUTES.MODELS}`);
      return data;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  }
};
