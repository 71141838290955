import { actionTypes } from './commentStatusAction';
import { IitemComment } from 'src/interface/IDetailState';
import { actionTypes as actionTypesCommentDetail } from 'src/activities/status/statusAction';

const initialState = {
  dataComment: {},
  isFall: false,
  isLoading: false,
  isEdit: false,
  isLoadingCreateComment: false
};
export function commentStatusReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_LIST_COMMENT_STATUS: {
      return {
        ...state,
        isLoading: true,
        isEdit: false
      };
    }
    case actionTypes.GET_LIST_COMMENT_STATUS_FALL: {
      return {
        ...state,
        isFall: true,
        isLoading: false,
        isEdit: false
      };
    }
    case actionTypes.GET_LIST_INITIAL_COMMENT_STATUS_SUCCESS: {
      const newComments: { [key: string]: any } = { ...state.dataComment };
      newComments[action.payload.id] = action.payload;
      return {
        ...state,
        dataComment: newComments,
        isFall: false,
        isLoading: false,
        isEdit: false
      };
    }
    case actionTypes.GET_LIST_COMMENT_STATUS_SUCCESS: {
      const newComments = { ...state.dataComment };
      if (!newComments[action.payload.id]) {
        newComments[action.payload.id] = {
          comments: [],
          page: 1,
          limit: 10,
          count: 0
        };
      }
      newComments[action.payload.id] = {
        ...newComments[action.payload.id],
        comments: [...action.payload.comments, ...newComments[action.payload.id].comments],
        page: action.payload.page,
        limit: action.payload.limit,
        count: action.payload.count
      };
      return {
        ...state,
        dataComment: newComments,
        isFall: false,
        isLoading: false,
        isEdit: false
      };
    }
    case actionTypesCommentDetail.POST_LIST_COMMENT_DETAIL_SUCESS: {
      const newComments: { [key: string]: any } = Object.assign({}, state.dataComment);
      const comments = newComments[action.payload.commentable_id]
        ? newComments[action.payload.commentable_id].comments
        : [];
      const existComment = comments.find(
        (comment: IitemComment) => comment.id === action.payload.id
      );
      let newDetailComment: any[] = [];
      if (!existComment || existComment === undefined) {
        newDetailComment = [...comments, action.payload];
      }
      if (newComments[action.payload.commentable_id]) {
        newComments[action.payload.commentable_id].comments = newDetailComment;
        newComments[action.payload.commentable_id].count++;
      }
      return {
        ...state,
        dataComment: newComments,
        isLoading: false,
        isEdit: false,
        isLoadingCreateComment: false,
        isFall: false
      };
    }
    case actionTypes.EDIT_COMMENT_STATUS: {
      const newComments: { [key: string]: any } = state.dataComment;

      newComments[action.id].comments = action.comments;
      return {
        ...state,
        dataComment: newComments,
        isEdit: true
      };
    }

    case actionTypes.CURRENT_EDIT_COMMENT: {
      return {
        ...state,
        idCurrentEdit: action.id
      };
    }
    case actionTypes.POST_NEW_COMMENT_STATUS_DETAIL: {
      const newComments: { [key: string]: any } = state.dataComment;
      const comments = newComments[action.id] ? newComments[action.id].comments : [];
      const existComment = comments.find(
        (comment: IitemComment) => comment.id === action.payload.id
      );
      let newDetailComment: any[] = [];
      if (!existComment || existComment === undefined) {
        newDetailComment = [...comments, action.payload];
      }
      newComments[action.id].comments = newDetailComment;
      return {
        ...state,
        dataComment: newComments,
        isLoading: false,
        isEdit: false
      };
    }
    case actionTypesCommentDetail.POST_LIST_COMMENT_STATUS_REQUEST: {
      return { ...state, isLoadingCreateComment: true, isFall: false };
    }
    case actionTypesCommentDetail.POST_LIST_COMMENT_STATUS_SUCCESS: {
      return { ...state, isLoadingCreateComment: false, isFall: false };
    }
    case actionTypesCommentDetail.POST_LIST_COMMENT_STATUS_ERROR: {
      return { ...state, isLoadingCreateComment: false, isFall: true };
    }
    default:
      return state;
  }
}
