import { AxiosResponse } from 'axios';
import { appAxios } from '@src/api/appAxios';
import dayjs from 'dayjs';
import { IPasswordChanged } from '../interface/ILoginState';
import handleError from './handleError';

export const ProfileApi = {
  getMyProfile: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get(`v1/users/me`);
      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
    }
  },
  followProfile: async (id: number, follow: boolean) => {
    try {
      const { data }: AxiosResponse = follow
        ? await appAxios.post(`posts/v2/users/${id}/follow`)
        : await appAxios.delete(`posts/v2/users/${id}/follow`);
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
    }
  },
  editImgCover: async (img: any, type: string) => {
    const formData = new FormData();
    formData.append(`user[${type}]`, img);
    try {
      const { data }: AxiosResponse = await appAxios.put(`v1/users/update_profile`, formData);
      return data ? data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
    }
  },
  getPointReceive: async (startDate: string, endDate: string) => {
    const params = {
      date_start: dayjs(startDate, 'YYYY-MM-DD').format(),
      date_end: dayjs(endDate, 'YYYY-MM-DD').format()
    };
    try {
      const { data }: AxiosResponse = await appAxios.get(`posts/v2/statistics/receive-points`, {
        params
      });
      return data ? data.data.period : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
    }
  },
  getTotalPost: async (startDate: string, endDate: string) => {
    const params = {
      date_start: dayjs(startDate, 'YYYY-MM-DD').format(),
      date_end: dayjs(endDate, 'YYYY-MM-DD').format()
    };
    try {
      const { data }: AxiosResponse = await appAxios.get(`posts/v2/statistics/news`, {
        params
      });
      return data ? data.data.period : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
    }
  },
  getTopGivePoint: async (startDate: string, endDate: string) => {
    const params = {
      date_start: dayjs(startDate, 'YYYY-MM-DD').format(),
      date_end: dayjs(endDate, 'YYYY-MM-DD').format()
    };
    try {
      const { data }: AxiosResponse = await appAxios.get(`posts/v2/top/givers-by-user`, {
        params
      });
      return data ? data.data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
    }
  },
  getTopReceivePoint: async (startDate: string, endDate: string) => {
    const params = {
      date_start: dayjs(startDate, 'YYYY-MM-DD').format(),
      date_end: dayjs(endDate, 'YYYY-MM-DD').format()
    };
    try {
      const { data }: AxiosResponse = await appAxios.get(`posts/v2/top/receivers-by-user`, {
        params
      });
      return data ? data.data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
    }
  },
  changePassword: async (fromData: IPasswordChanged) => {
    try {
      const data = await appAxios.put(`v1/users/change_password`, fromData);
      return data ? data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
    }
  },
  getOtherProfile: async (id: string) => {
    try {
      const { data }: AxiosResponse = await appAxios.get(`v1/users/${id}`);
      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
    }
  },
  getOtherPointReceive: async (startDate: string, endDate: string, userID: string) => {
    const params = {
      date_start: dayjs(startDate, 'YYYY-MM-DD').format(),
      date_end: dayjs(endDate, 'YYYY-MM-DD').format(),
      user_id: userID
    };
    try {
      const { data }: AxiosResponse = await appAxios.get(`posts/v2/statistics/receive-points`, {
        params
      });
      return data ? data.data.period : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
    }
  },
  getOtherTotalPost: async (startDate: string, endDate: string, userID: string) => {
    const params = {
      date_start: dayjs(startDate, 'YYYY-MM-DD').format(),
      date_end: dayjs(endDate, 'YYYY-MM-DD').format(),
      user_id: userID
    };
    try {
      const { data }: AxiosResponse = await appAxios.get(`posts/v2/statistics/news`, {
        params
      });
      return data ? data.data.period : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
    }
  },
  getOtherTopGivePoint: async (startDate: string, endDate: string, userID: string) => {
    const params = {
      date_start: dayjs(startDate, 'YYYY-MM-DD').format(),
      date_end: dayjs(endDate, 'YYYY-MM-DD').format(),
      user_id: userID
    };
    try {
      const { data }: AxiosResponse = await appAxios.get(`posts/v2/top/givers-by-user`, {
        params
      });
      return data ? data.data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
    }
  },
  getOtherTopReceivePoint: async (startDate: string, endDate: string, userID: string) => {
    const params = {
      date_start: dayjs(startDate, 'YYYY-MM-DD').format(),
      date_end: dayjs(endDate, 'YYYY-MM-DD').format(),
      user_id: userID
    };
    try {
      const { data }: AxiosResponse = await appAxios.get(`posts/v2/top/receivers-by-user`, {
        params
      });
      return data ? data.data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
    }
  }
};
