import styled from 'styled-components';

interface Props {
  src: string | null;
}

interface TypingProps {
  hidden: boolean | false;
}

const MessageContainer = styled.div`
  width: 150px;
  height: auto;
  position: fixed;
  right: -100px;
  bottom: 40px;
  z-index: 8888;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  &:hover {
    right: 0;
    transition: 0.2s;
    -webkit-transition: 0.2s;
    .message {
      visibility: visible;
      transition: transform 1.5s;
    }
    .typing {
      display: none;
    }
  }
`;
const CartoonCharacter = styled.div<Props>`
  width: auto;
  height: 150px;
  background-size: cover;
  background-image: url('${(props: Props) => props.src}');
`;

const CongratulationMessage = styled.div<Props>`
  width: auto;
  height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('${(props: Props) => props.src}');
  text-align: center;
  cursor: pointer;
  padding: 10px 8px 0 5px;
  display: block;
  visibility: hidden;
  vertical-align: middle;
  user-select: none;
  -webkit-user-select: none;
`;

const MessageContent = styled.a`
  color: black;
  font-family: ArcadeClassic;
  font-size: 14px;
  &:hover {
    color: #606060;
  }
  .empty {
    margin-top: 12px;
  }
`;

const Typing = styled.img<TypingProps>`
  display: ${(props: TypingProps) => (props.hidden ? 'none' : 'inline-block')};
  height: 40px;
  width: auto;
  transform: scaleX(-1);
  margin-bottom: -5px;
  user-select: none;
  -webkit-user-select: none;
`;

const BubbleMessage = styled.img`
  height: 48px;
  width: 130px;
  background-size: cover;
  transform: rotate(-3deg);
  margin-left: -2px;
`;

export {
  MessageContainer,
  CongratulationMessage,
  MessageContent,
  CartoonCharacter,
  Typing,
  BubbleMessage
};
