import { put, takeLatest, all } from 'redux-saga/effects';
// import { setHeaderAppAxios } from 'src/api/appAxios';
import { actionTypes } from 'src/components/signin/signinAction';
import { setLoading, setSuccess, setError, setNoLoading } from 'src/common/commonAction';

function* actionAuthen(action: any) {
  const { values } = action;
  try {
    yield put(setLoading());
    const data = values;
    if (data) {
      const { access_token, user_id } = data;
      // localStorage.setItem('token', access_token.toString());
      localStorage.setItem('user_id', user_id);
      const recent = localStorage.getItem('recent') || [];
      // setHeaderAppAxios(access_token);
      if (recent.length < 1) {
        localStorage && localStorage.setItem('recent', '[]');
      }
      yield put({
        type: actionTypes.LOGIN_SUCCESS,
        access_token
      });
      yield put(setSuccess());
    } else {
      yield put({ type: actionTypes.LOGIN_FAIL });
      yield put(setError());
    }
  } catch (error) {
    yield put({ type: actionTypes.LOGIN_FAIL });
    yield put(setNoLoading());
  }
}

function* loginWatcher() {
  yield takeLatest(actionTypes.POST_AUTH, actionAuthen);
}

export function* signinSaga() {
  yield all([loginWatcher()]);
}
