import { actionTypes } from '@src/components/Heatmap/project/favoriteProjectsAction';

const initialState = {
  data: [],
  isLoadingFavoriteProjects: false,
  isLoadingUpdateFavoriteProjects: false
};

export function favoriteProjectsReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.REQUEST_LIST_FAVORITE_PROJECTS: {
      return {
        ...state,
        isLoadingFavoriteProjects: true
      };
    }
    case actionTypes.REQUEST_LIST_FAVORITE_PROJECTS_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isLoadingFavoriteProjects: false
      };
    }
    case actionTypes.REQUEST_LIST_FAVORITE_PROJECTS_FAILED: {
      return {
        ...state,
        data: [],
        isLoadingFavoriteProjects: false
      };
    }
    case actionTypes.REQUEST_UPDATE_LIST_FAVORITE_PROJECTS: {
      return {
        ...state,
        isLoadingUpdateFavoriteProjects: true
      };
    }
    case actionTypes.REQUEST_UPDATE_LIST_FAVORITE_PROJECTS_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isLoadingUpdateFavoriteProjects: false
      };
    }
    case actionTypes.REQUEST_UPDATE_LIST_FAVORITE_PROJECTS_FAILED: {
      return {
        ...state,
        isLoadingUpdateFavoriteProjects: false
      };
    }
    default:
      return state;
  }
}
