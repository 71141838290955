import { actionTypes } from './activitiesAction';

const initialState = {
  givenData: {
    data: [],
    isLoading: false
  },
  receiversData: {
    data: [],
    isLoading: false
  },
  hashtagData: {
    data: [],
    isLoading: false
  },
  notiTabData: {
    announcement: 0,
    news: 0
  },
  users: {
    items: [],
    isLoaded: false
  }
};

export function activitiesReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.REQUEST_DATA_POINT_GIVEN: {
      return {
        ...state,
        givenData: {
          ...state.givenData,
          isLoading: true
        }
      };
    }
    case actionTypes.GET_DATA_POINT_GIVEN_SUCCESS: {
      return {
        ...state,
        givenData: {
          ...state.givenData,
          data: action.data,
          isLoading: false
        }
      };
    }
    case actionTypes.GET_DATA_POINT_GIVEN_FALL: {
      return {
        ...state,
        givenData: {
          ...state.givenData,
          data: [],
          isLoading: false
        }
      };
    }
    case actionTypes.REQUEST_DATA_POINT_RECEIVER: {
      return {
        ...state,
        receiversData: {
          ...state.receiversData,
          isLoading: true
        }
      };
    }
    case actionTypes.GET_DATA_POINT_RECEIVER_SUCCESS: {
      return {
        ...state,
        receiversData: {
          ...state.receiversData,
          data: action.data,
          isLoading: false
        }
      };
    }
    case actionTypes.GET_DATA_POINT_RECEIVER_FALL: {
      return {
        ...state,
        receiversData: {
          ...state.receiversData,
          data: [],
          isLoading: false
        }
      };
    }
    case actionTypes.REQUEST_DATA_POINT_HASHTAG: {
      return {
        ...state,
        hashtagData: {
          ...state.hashtagData,
          isLoading: true
        }
      };
    }
    case actionTypes.GET_DATA_POINT_HASHTAG_SUCCESS: {
      return {
        ...state,
        hashtagData: {
          ...state.hashtagData,
          data: action.data,
          isLoading: false
        }
      };
    }
    case actionTypes.GET_DATA_POINT_HASHTAG_FALL: {
      return {
        ...state,
        hashtagData: {
          ...state.hashtagData,
          data: [],
          isLoading: false
        }
      };
    }
    case actionTypes.REQUEST_COUNT_NOTI_TAB: {
      return {
        ...state
      };
    }
    case actionTypes.GET_COUNT_NOTI_TAB_SUCCESS: {
      return {
        ...state,
        notiTabData: action.count
      };
    }
    case actionTypes.GET_COUNT_NOTI_TAB_FALL: {
      return {
        ...state,
        notiTabData: {
          announcement: 0,
          news: 0
        }
      };
    }
    case actionTypes.GET_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          isLoaded: true
        }
      };
    case actionTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          ...action.payload,
          isLoaded: true
        }
      };
    default:
      return state;
  }
}
