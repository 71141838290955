import { IformResetPassword } from '@src/interface/IResetPasswordState';

export const actionTypes = {
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
  CLEAR_STATUS: 'CLEAR_STATUS'
};

export const postFormResetPassword = (formData: IformResetPassword): any => ({
  type: actionTypes.RESET_PASSWORD_REQUEST,
  formData
});
