export const actionTypes = {
  UPDATE_DATA_HEATMAP: 'UPDATE_DATA_HEATMAP',
  ACTIVE_TEMPLATE: 'ACTIVE_TEMPLATE'
};

export const updateDataHeatmap = (data: any) => ({
  type: actionTypes.UPDATE_DATA_HEATMAP,
  date: data.date,
  items: data.items
});

export const activeTemplateAction = (activeTemplate: any) => ({
  type: actionTypes.ACTIVE_TEMPLATE,
  activeTemplate
});
