import styled from 'styled-components';

interface Props {
  error?: boolean;
}

const ContextIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  border: 1px solid #ccd7d6;
  svg,
  path {
    fill: '#1C495E';
  }
`;

const ConversationWrapper = styled.div`
  background-color: #fff;
  height: calc(100vh - 104px);
  padding: 20px;
  border-radius: 13px;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const Conversation = styled.div`
  width: 100%;
  height: 45px;
  padding: 12px;
  display: grid;
  grid-template-columns: 16px 1fr 16px;
  gap: 6px;
  align-items: center;
  border-radius: 4px;
  background-color: #f2f2f2;
  color: #1a1a1a;
  .chatsCircle {
    fill: #000;
  }
  :hover {
    background-color: #1f2d3aad;
    color: #fff;
    .chatsCircle {
      fill: #fff;
    }
    svg,
    path {
      stroke: #fff;
    }
  }

  &.active {
    background-color: #1a1a1a;
    color: #fff;
    .chatsCircle {
      fill: #fff;
    }
    path {
      stroke: #fff;
    }
    ${ContextIcon} {
      svg,
      path {
        fill: #fff;
      }
    }
  }
`;

const ConversationTitle = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  .ant-input {
    padding: 0 0.2rem;
    border: none;
    border-radius: 0;
    color: white;
    background: transparent;
  }
`;

const ConversationText = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  margin-left: 8px;
`;

const SourceItem = styled.a`
  width: 160px;
  padding: 9px 20px;
  line-height: 21px;
  border-radius: 6px;
  background-color: #1a1a1a0a;
  border: 1px solid #1a1a1a0a;
  cursor: pointer;
  color: #1a1a1a;
  font-size: 14px;
  :hover {
    background-color: #eaeaea80;
    color: #1a1a1a;
  }
`;

const Presentation = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0 4px;
  margin: 4px 0 16px 0;
`;
const PresentationContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;
  padding: 0 4px;
  margin: 16px 0 16px 0;
`;
const UserMessage = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 12px;
  margin-bottom: 28px;
  align-items: center;
`;

const BotMessage = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const FilterSpace = styled.div`
  white-space: unset;
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;
  color: #000;

  ol:first-child {
    margin-block-start: 10px;
  }
  ol {
    padding-inline-start: 20px;
    margin-bottom: 0;
    margin-block-end: 10px;
    margin-block-start: 0;
  }
  ul {
    margin-block-start: 0;
    margin-block-end: 20px;
  }
  sup {
    position: relative;
    top: 0;
    font-size: 16px;
  }
`;

const SourceWrapper = styled.div``;

const SourceTitle = styled.div`
  color: #1f2d3a;
`;

const SourceScroll = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const EmptyStateBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
`;

const LimitMessageBox = styled.div`
  width: fit-content;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #fdbf00;
  background-color: #fef8e3;
  padding: 14px 20px;
`;

const EmptyMessageBox = styled.div`
  width: 70%;
  max-width: 364px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  margin: 16px auto;
`;

const InputGroup = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  gap: 28px;
  padding: 12px 12px 12px 28px;
  border: solid 1px #1a1a1a;
  border-radius: 8px;
  box-shadow: 0px 2px 6px 0px #0000003d !important;
`;

const TextInput = styled.input<Props>`
  width: 100%;
  padding: 10px 0;
  border: none;
  height: auto;
  color: #1a1a1a;
  outline: none;
  resize: none;
  overflow-x: auto;
  background: none;
  &::placeholder {
    color: ${(props) => (props.error ? 'red' : '#BBBBBB')};
    opacity: ${(props) => (props.error ? '0.6' : '1')};
  }
`;

const Remind = styled.div`
  text-align: center;
  color: #a8a8a8;
  font-size: 12px;
  margin-top: 14px;
`;

const MessageBubble = styled.div`
  min-height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 8px;
  font-size: 14px;
  padding: 10px 24px 10px 22px;
  background: #1a1a1a14;
  color: #1a1a1a;
  width: 100%;
  cursor: pointer;
  :hover {
    background-color: #1f2d3aad;
    color: #fff;
  }
`;

const InlineCitation = styled.a`
  position: relative;
  text-decoration: underline;
  border-radius: 0.25rem;
  font-size: 16px;
  line-height: 1rem;
`;

const FeedbackWrapper = styled.div`
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  .like {
    stroke: #757575;
  }
  .unlike {
    fill: #757575;
  }
  :hover .like {
    stroke: #000000;
  }
  :hover .unlike {
    fill: #000000;
  }
  &.activate {
    .like {
      stroke: #000000;
    }
    .unlike {
      fill: #000000;
    }
  }
`;

const ModelTitle = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 32px;
  align-items: center;
  p {
    line-height: 18px;
    height: fit-content;
    color: #1a1a1a;
    font-size: 18px;
    font-weight: 400;
    margin: 0;
  }
`;

const ModelContainer = styled.div`
  margin-top: 16px;
  padding: 8px;
  border: 1px solid #1a1a1a1f;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ModelItem = styled.div`
  background-color: #1A1A1A14;
  color: #1a1a1a
  width: 100%;
  padding: 10px 24px 10px 22px;
  border-radius: 6px;
  display: flex;
  gap: 10px;
  cursor: pointer;
  align-items: center;
  font-size: 14px;
  :hover {
    background-color: #1f2d3aad;
    color: #fff;
  }
  &.active {
    background-color: #1a1a1a;
    color: #fff;
  }
`;

const HelpText = styled.p`
  margin: 51px 0 0;
  font-size: 32px;
  line-height: 32px;
  font-weight: 700;
  color: #0000002e;
`;

const MessageContainer = styled.div`
  display: flex;
  gap: 12px;
`;

const UserQuestion = styled.p`
  line-height: 24px;
  margin: 0;
  color: #1a1a1a;
`;

const ModelActiveContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  padding-left: 10px;
  background-color: #1a1a1a14;
  padding: 10px 24px 10px 22px;
  border-radius: 6px;
  width: fit-content;
  img: {
    margin-top: 0 !important;
  }
  p {
    margin-bottom: 0;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    color: #333;
  }
`;

export default {
  Conversation,
  ConversationWrapper,
  ConversationTitle,
  SourceItem,
  ContextIcon,
  Presentation,
  PresentationContainer,
  UserMessage,
  BotMessage,
  SourceWrapper,
  SourceTitle,
  SourceScroll,
  EmptyStateBox,
  LimitMessageBox,
  EmptyMessageBox,
  InputGroup,
  TextInput,
  Remind,
  MessageBubble,
  InlineCitation,
  FilterSpace,
  FeedbackWrapper,
  ModelTitle,
  ModelContainer,
  ModelItem,
  HelpText,
  MessageContainer,
  UserQuestion,
  ModelActiveContainer,
  ConversationText
};
