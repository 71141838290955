import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import getConfig from 'next/config';
import router from 'next/router';
import https from 'https';
import { IKeycloakSession } from '@src/interface/IKeyCloak';
import { signOut, getSession } from 'next-auth/react';
import APP_ROUTES from '@src/common/appRoutes';
import { CHATGPT_BASE_URL } from '@src/common/constants';

const { publicRuntimeConfig = {} } = getConfig() || {};
const { NEXT_PUBLIC_BASE_URL, NODE_ENV } = publicRuntimeConfig;

const baseURL = NEXT_PUBLIC_BASE_URL;

let agent;
if (typeof window === 'undefined' && NODE_ENV !== 'production') {
  agent = new https.Agent({
    rejectUnauthorized: false
  });
}

const baseHeaders = (config: AxiosRequestConfig) => ({
  'Content-Type': 'application/json',
  ...config.headers
});

let refreshPromise: Promise<any> | null = null;
const clearPromise = () => (refreshPromise = null);

const instanceAppAxios = axios.create({
  baseURL,
  httpsAgent: agent // This agent prevent ERR_TLS_CERT_ALTNAME_INVALID issue when in SSR development enviroment
});

instanceAppAxios.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const session = await getSession();
  const token = (session as unknown as IKeycloakSession | null)?.access_token || '';

  return {
    ...config,
    headers: {
      ...baseHeaders(config),
      ...(token ? { Authorization: `${token}` } : {})
    }
  };
});

instanceAppAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const config = error.config;
    const { status } = error.response;

    // handle case 401: refresh token
    if (status === 401 && !config._retry) {
      config._retry = true;
      if (!refreshPromise) {
        refreshPromise = getSession();
      }

      try {
        const session = await refreshPromise;
        config.headers.Authorization = `Bearer ${session?.access_token}`;
      } catch (e) {
        console.log('Refresh fail', e);
      } finally {
        clearPromise();
      }

      return instanceAppAxios(config);
    }

    throw error;
  }
);

async function createAxiosClient(baseURL: string) {
  let agent;

  if (typeof window === 'undefined' && NODE_ENV !== 'production') {
    agent = new https.Agent({
      rejectUnauthorized: false
    });
  }

  const axiosClient = axios.create({
    baseURL,
    httpsAgent: agent // This agent prevent ERR_TLS_CERT_ALTNAME_INVALID issue when in SSR development environment,
  });

  const session = await getSession();
  const token = (session as unknown as IKeycloakSession | null)?.access_token || '';

  axiosClient.interceptors.request.use(
    async (config) => {
      if (config.headers) {
        config.headers.Authorization = token;
      }

      return config;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axiosClient.interceptors.response.use(
    (response) => {
      return response;
    },

    async (error: AxiosError) => {
      if (error.response?.status.toString() === '401' && typeof window !== 'undefined') {
        const session = await getSession();
        if (session) {
          const notiFlag = localStorage.getItem('isShowNotiLog');
          const newsLocal = localStorage.getItem('DRAFT_NEWS');
          const announcementLocal = localStorage.getItem('DRAFT_ANNOUNCEMENT');
          const heatMap = localStorage.getItem('HEATMAP');
          const statusLocal = localStorage.getItem('DRAFT_STATUS');
          const pollLocal = localStorage.getItem('pollDraft');
          localStorage.clear();
          if (notiFlag) localStorage.setItem('isShowNotiLog', notiFlag);
          if (newsLocal) localStorage.setItem('DRAFT_NEWS', newsLocal);
          if (announcementLocal) localStorage.setItem('DRAFT_ANNOUNCEMENT', announcementLocal);
          if (heatMap) localStorage.setItem('HEATMAP', heatMap);
          if (statusLocal) localStorage.setItem('DRAFT_STATUS', statusLocal);
          if (pollLocal) localStorage.setItem('pollDraft', pollLocal);
          signOut({ callbackUrl: APP_ROUTES.SIGN_IN });
        } else {
          router.push(APP_ROUTES.SIGN_IN);
        }
      }

      return Promise.reject(error);
    }
  );
  return axiosClient;
}

const appAxiosAsk = createAxiosClient(CHATGPT_BASE_URL);

export { instanceAppAxios as appAxios, appAxiosAsk };
