import { AxiosResponse } from 'axios';
import { appAxios } from '@src/api/appAxios';
import handleError from './handleError';

export const InteractiveApi = {
  getListUserLike: async (id: number) => {
    try {
      const { data }: AxiosResponse = await appAxios.get(`posts/v2/posts/${id}/like/list`);
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      // const err: AxiosError = error;
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      throw error.response;
    }
  },
  getListHashTagRecomment: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get('posts/v2/tags/suggestion');
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      // const err: AxiosError = error;
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      throw error.response;
    }
  }
};
