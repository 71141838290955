import { AxiosResponse } from 'axios';
import { appAxios } from '@src/api/appAxios';
import handleError from './handleError';
export const StatusApi = {
  getListStatus: async (
    page: number,
    limit: number,
    keyword?: string,
    sort?: string,
    isRead?: boolean
  ) => {
    try {
      const urlWithParams = new URL('/posts/v2.1/statuses/', process.env.NEXT_PUBLIC_BASE_URL);
      if (keyword) {
        urlWithParams.searchParams.append('keyword', keyword.toString());
      }
      if (sort) {
        urlWithParams.searchParams.append('order_by', sort);
      }

      if (isRead === true) {
        urlWithParams.searchParams.append('is_read', 'true');
      }
      if (isRead === false) {
        urlWithParams.searchParams.append('is_read', 'false');
      }

      urlWithParams.searchParams.append('page', page.toString());
      urlWithParams.searchParams.append('limit', limit.toString());
      const { data }: AxiosResponse = await appAxios.get(urlWithParams.href);

      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      // throw error;
    }
  },
  getListMention: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get(`posts/v2/users/mentions?limit=1000`);
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      // throw error;
    }
  },
  getUsersStatus: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get(`/v1/users/status`);
      if (data.errors) {
        throw new Error(data.errors);
      }
      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      // throw error;
    }
  },
  postComment: async (params: Record<string, any>) => {
    try {
      const { data }: AxiosResponse = await appAxios.post('posts/v2/statuses', params);
      return data && data.code === 200 ? data.data : data;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      return error.response;
    }
  },
  getListFeeling: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get('posts/v2/feelings');
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      //throw err;
    }
  },
  postCreateComment: async (params: Record<string, any>) => {
    try {
      const { data }: AxiosResponse = await appAxios.post('posts/v2/comments', params);
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      //throw err;
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      return { isError: true, message: error?.response?.data?.message };
    }
  },
  getListTag: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get('posts/v2/tags/status?limit=1000');
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      //throw err;
    }
  },
  uploadImage: async (image: any) => {
    try {
      const { data }: AxiosResponse = await appAxios.post('posts/v2/files', image, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      return { isError: true, message: error?.response?.data?.message };
    }
  },
  updateComment: async (id: number, params: Record<string, any>) => {
    try {
      const { data }: AxiosResponse = await appAxios.put(`posts/v2/comments/${id}`, params);
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      //throw err;
    }
  },
  getListComment: async (id: number, page: number) => {
    try {
      const { data }: AxiosResponse = await appAxios.get(
        `posts/v2/comments?commentable_id=${id}&commentable_type=Post&page=${page}&limit=10&sort_by=created_at&order_by=desc`
      );
      if (data && data.data) {
        const result = {
          comments: data.data.comments.reverse(),
          count: data.data.count,
          limit: data.data.limit,
          page: data.data.page
        };
        return result;
      }
      return false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      //throw err;
    }
  },
  deleteStatus: async (id: number) => {
    try {
      const { data }: AxiosResponse = await appAxios.delete(`posts/v2/comments/${id}`);
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      //throw err;
    }
  },
  createComment: async (params: Record<string, any>) => {
    try {
      const { data }: AxiosResponse = await appAxios.post('posts/v2/comments', params);
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      //throw err;
    }
  },
  likeComment: async (id: number) => {
    try {
      const { data }: AxiosResponse = await appAxios.post(`posts/v2/posts/${id}/like`);
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      //throw err;
    }
  },
  getStatusDetail: async (id: number) => {
    try {
      const { data }: AxiosResponse = await appAxios.get(`posts/v2/statuses/${id}`);
      return data && data.code === 200 ? data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      //throw err;
    }
  },
  getStatusById: async (id: number) => {
    try {
      const { data }: AxiosResponse = await appAxios.get(`posts/v2/statuses/${id}`);
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      //throw err;
    }
  },
  editStatus: async (param: any, id: number) => {
    try {
      const { data }: AxiosResponse = await appAxios.put(`posts/v2/statuses/${id}`, param);
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      //throw err;
    }
  },
  followStatus: async (id: number, follow: boolean) => {
    try {
      const { data }: AxiosResponse = follow
        ? await appAxios.post(`posts/v2/posts/${id}/follow`)
        : await appAxios.delete(`posts/v2/posts/${id}/follow`);
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      if (error.response && error.response.data) {
        const { status } = error.response?.data;
        if (handleError(status)) return;
      }
      console.log(error);
      //throw err;
    }
  }
};
