import { IdataStatus } from '@src/interface/IStatusState';
import { IAppState } from 'src/interface/IAppState';

export const firstCommentImportStatus = (appState: IAppState): IdataStatus[] =>
  appState.statusState.data;

export const isOnline =
  (userID: number) =>
  (appState: IAppState): boolean => {
    if (appState.headerState.data.id === userID) {
      return true;
    }

    const user = appState.statusState.mention.find((m) => m.id === userID);
    return user?.is_online === true;
  };
