import { actionTypes } from '@src/dashboard/dashboardAction';

const initialState = {
  isLoading: false,
  actionSuccess: true,
  data: []
};

export function dashboardReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_NEWEST_POSTS_REQUEST: {
      return { ...state, isLoading: true };
    }
    case actionTypes.GET_NEWEST_POSTS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data.posts,
        isLoading: false,
        actionSuccess: true
      };
    }
    default:
      return state;
  }
}
