import { IitemComment } from '@src/interface/IDetailState';

export interface IstatusState {
  data: IdataStatus[];
  isLoading: boolean;
  page: number;
  limit: number;
  count: number;
  mention: IdataMention[];
  feeling: Ifeeling[];
  tag: Itag[];
  img: Image;
  messageErrorStatus: string;
  loading: boolean;
  listComment: IdataListComment[];
  detail: IdataStatus;
  isLoadingListStatus: boolean;
  statusById: IdataStatusById;
  getStatusIsLoading: boolean;
  loadingBtn: boolean;
  openModal: boolean;
  idModal: number;
  idPreviewModal: number;
  imageType: string;
  uploadVideoStatus: IUploadVideoStatus;
  saveMyStatus: any;
  isEdit: boolean;
  isCreateFail: boolean;
  users: any;
}

export enum UploadFileType {
  VIDEO,
  FILE,
  IMAGE
}

export interface IUploadVideoStatus {
  uploading: boolean;
  uploadError: boolean;
  success: boolean;
  fileType: UploadFileType | undefined;
  progress: number; // percent
  fileURL?: string;
}

export interface IdataListComment {
  id: number;
  commentable_id: number;
  commentable_type: string;
  content: string;
  commenter_id: number;
  commenter_type: string;
  like_count: number;
  is_edited: boolean;
  is_like: boolean;
  created_at: string;
  updated_at: string;
  author: {
    avatar_url: string;
    given_points: number;
    id: number;
    name: string;
    received_points: number;
    team: string;
    username: string;
  };
}

export interface Image {
  id: number;
  user_id: number;
  key: string;
  kind: number;
  url: string;
  created_at: string;
  updated_at: string;
}

export interface Itag {
  id: number;
  name: string;
  taggings_count: number;
  kind: number;
}

export interface Ifeeling {
  id: number;
  name: string;
  icon: any;
}

export interface IdataMention {
  id: number;
  display: string;
  user: string;
  name: string;
  is_online?: boolean;
}
export interface Iauthor {
  id: number;
  avatar_url: string;
  username: string;
}

export interface IdataStatus {
  newComments: IitemComment[];
  author_id: number;
  id: number;
  feeling_id: number;
  author_type: string;
  kind: number;
  content: string;
  like_count: number;
  comment_count: number;
  first_comment?: IitemComment;
  feeling?: IdataFeeling;
  review: IdataReview;
  user: IdataUser;
  images: Images[];
  file: string;
  giphy: string;
  created_at: string;
  updated_at: string;
  image_id?: number;
  file_id?: number;
  giphy_id?: number;
  videos?: IVideoStatus[];
  is_anonymous?: boolean;
  is_multiple_choice?: boolean;
  has_other?: boolean;
  fabric?: string;
}

export interface Images {
  content_type: string;
  created_at: string;
  id: number;
  key: string;
  kind: string;
  updated_at: string;
  url: string;
  user_id: number;
}

export interface IdataFeeling {
  id: number;
  name: string;
  icon: any;
}

export interface IdataReview {
  is_read: boolean;
  is_liked: boolean;
  is_bookmarked: false;
  is_clicked: boolean;
  is_followed: boolean;
}

export interface IdataUser {
  avatar_url: string;
  username: string;
  name: string;
  team: string;
}
export interface IstatusResponsive {
  statuses: IdataStatus[];
  count: number;
  page: number;
  limit: number;
}

export interface IdataStatusById {
  author_id: number;
  author_type: string;
  comment_count: number;
  content: string;
  created_at: string;
  feeling: IdataFeeling | null;
  feeling_id: number;
  file: string;
  first_comment: any;
  giphy: string;
  id: number;
  images: IdataImagesEditStatus[];
  kind: number;
  like_count: number;
  review: any;
  total_point: number;
  updated_at: string;
  user: IdataUser;
  videos?: IVideoStatus;
  fabric?: string;
}

export interface IVideoStatus {
  id: number;
  url: string;
  thumbnail: IVideoThumbnail;
  [key: string]: any;
}

export interface IVideoThumbnail {
  id: number;
  url: string;
  [key: string]: any;
}

export interface IdataImagesEditStatus {
  content_type: string;
  created_at: string;
  id: number;
  key: string;
  updated_at: string;
  url: string;
  user_id: number;
}

export interface IDataStatusLocal {
  id: string;
  feeling: IdataFeeling;
  content: any;
  gif: string;
}
