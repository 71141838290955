import Message from 'antd/lib/message';
import { AxiosResponse } from 'axios';
import router from 'next/router';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { GptAPI } from 'src/api/gptAPI';
import APP_ROUTES from 'src/common/appRoutes';
import { actionTypes } from './chatGptAction';

function* requestGetConversationList() {
  try {
    const data: AxiosResponse = yield call(GptAPI.getListConversation);
    yield put({ type: actionTypes.GET_CONVERSATION_SUCCESS, payload: data });
  } catch (e) {
    console.log(e);
  }
}

function* requestGetModelsList() {
  try {
    const data: AxiosResponse = yield call(GptAPI.getListModels);
    yield put({ type: actionTypes.GET_MODELS_SUCCESS, payload: data });
  } catch (e) {
    console.log(e);
  }
}

function* requestEditConversation(action: any) {
  const { params } = action;
  try {
    const data: AxiosResponse = yield call(GptAPI.editConversation, params);
    if (data.status === 200) {
      yield put({ type: actionTypes.UPDATE_CONVERSATION_SUCCESS, payload: data.data });
    } else {
      Message.error('Failed');
    }
  } catch (e) {
    console.log(e);
  }
}

function* requestUpdateStateConversation(action: any) {
  const { params } = action;
  yield put({ type: actionTypes.UPDATE_CONVERSATION_SUCCESS, payload: params });
}

function* requestDeleteConversation(action: any) {
  const { payload } = action as { payload: { id: string; resolve: () => void } };
  try {
    const data: AxiosResponse<string> = yield call(GptAPI.deleteConversation, payload.id);
    if (data.data === 'success') {
      yield put({
        type: actionTypes.DELETE_CONVERSATION_SUCCESS,
        id: payload.id
      });
      payload.resolve();
    } else {
      Message.error('Failed');
    }
  } catch (e) {
    console.log(e);
  }
}

function* requestGetMessageList(action: any) {
  const { id } = action;
  const conversation_id = id;
  try {
    const data: AxiosResponse = yield call(GptAPI.getListMessage, conversation_id);
    yield put({ type: actionTypes.GET_MESSAGE_GPT_SUCCESS, payload: data });
  } catch (e) {
    router.push(APP_ROUTES.QA);
  }
}

function* requestLikeMessageGPT(action: any) {
  const { id } = action.params;
  try {
    yield call(GptAPI.likeMsgGPT, id);
    yield put({ type: actionTypes.UPDATE_FEEDBACK_MESSAGE_GPT_REQUEST, payload: action.params });
  } catch (e) {
    console.log(e);
  }
}

function* requestUnlikeMessageGPT(action: any) {
  const { id } = action.params;
  try {
    yield call(GptAPI.unlikeMsgGPT, id);
    yield put({ type: actionTypes.UPDATE_FEEDBACK_MESSAGE_GPT_REQUEST, payload: action.params });
  } catch (e) {
    console.log(e);
  }
}

function* requestFeedbackMessageGPT(action: any) {
  const { params } = action;
  try {
    yield call(GptAPI.feedbackMsgGPT, params);
  } catch (e) {
    console.log(e);
  }
}

function* watchRequestGetConversationList() {
  yield takeEvery(actionTypes.GET_CONVERSATION_REQUEST, requestGetConversationList);
}

function* watchRequestGetModelsList() {
  yield takeEvery(actionTypes.GET_MODELS_REQUEST, requestGetModelsList);
}

function* watchRequestEditConversation() {
  yield takeEvery(actionTypes.EDIT_CONVERSATION_REQUEST, requestEditConversation);
}

function* watchRequestUpdateStateConversation() {
  yield takeEvery(actionTypes.UPDATE_CONVERSATION_REQUEST, requestUpdateStateConversation);
}

function* watchRequestDeleteConversation() {
  yield takeEvery(actionTypes.DELETE_CONVERSATION_REQUEST, requestDeleteConversation);
}

function* watchRequestGetMessageList() {
  yield takeEvery(actionTypes.GET_MESSAGE_GPT_REQUEST, requestGetMessageList);
}

function* watchRequestLikeMessageGPT() {
  yield takeEvery(actionTypes.LIKE_MESSAGE_GPT_REQUEST, requestLikeMessageGPT);
}

function* watchRequestUnlikeMessageGPT() {
  yield takeEvery(actionTypes.UNLIKE_MESSAGE_GPT_REQUEST, requestUnlikeMessageGPT);
}

function* watchRequestFeedbackMessageGPT() {
  yield takeEvery(actionTypes.FEEDBACK_MESSAGE_GPT_REQUEST, requestFeedbackMessageGPT);
}

export function* chatGptSaga() {
  yield all([
    watchRequestGetConversationList(),
    watchRequestGetModelsList(),
    watchRequestEditConversation(),
    watchRequestUpdateStateConversation(),
    watchRequestDeleteConversation(),
    watchRequestGetMessageList(),
    watchRequestLikeMessageGPT(),
    watchRequestUnlikeMessageGPT(),
    watchRequestFeedbackMessageGPT()
  ]);
}
