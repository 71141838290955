export const actionTypes = {
  REQUEST_DATA_POINT_GIVEN: 'REQUEST_DATA_POINT_GIVEN',
  REQUEST_DATA_POINT_RECEIVER: 'REQUEST_DATA_POINT_RECEIVER',
  REQUEST_DATA_POINT_HASHTAG: 'REQUEST_DATA_POINT_HASHTAG',
  REQUEST_COUNT_NOTI_TAB: 'REQUEST_COUNT_NOTI_TAB',
  GET_COUNT_NOTI_TAB_SUCCESS: 'GET_COUNT_NOTI_TAB_SUCCESS',
  GET_COUNT_NOTI_TAB_FALL: 'GET_COUNT_NOTI_TAB_FALL',
  GET_DATA_POINT_GIVEN_SUCCESS: 'GET_DATA_POINT_GIVEN_SUCCESS',
  GET_DATA_POINT_GIVEN_FALL: 'GET_DATA_POINT_GIVEN_FALL',
  GET_DATA_POINT_RECEIVER_SUCCESS: 'GET_DATA_POINT_RECEIVER_SUCCESS',
  GET_DATA_POINT_RECEIVER_FALL: 'GET_DATA_POINT_RECEIVER_FALL',
  GET_DATA_POINT_HASHTAG_SUCCESS: 'GET_DATA_POINT_HASHTAG_SUCCESS',
  GET_DATA_POINT_HASHTAG_FALL: 'GET_DATA_POINT_HASHTAG_FALL',

  GET_USERS: 'GET_USERS',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS'
};

export const requestPointEvent = () => ({
  type: actionTypes.REQUEST_DATA_POINT_GIVEN
});
export const requestCountNotiTab = () => ({
  type: actionTypes.REQUEST_COUNT_NOTI_TAB
});
export const getDataPointReceiver = () => ({
  type: actionTypes.REQUEST_DATA_POINT_RECEIVER
});
export const actionGetUser = () => {
  return { type: actionTypes.GET_USERS };
};
