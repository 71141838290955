export const actionTypes = {
  GET_NOTIFICATION: 'GET_NOTIFICATION',
  GET_NOTIFICATION_PENDING: 'GET_NOTIFICATION_PENDING',
  GET_NOTIFICATION_SUCCESS: 'GET_NOTIFICATION_SUCCESS',
  READ_NOTIFICATION: 'READ_NOTIFICATION',
  READ_NOTIFICATION_SUCCESS: 'READ_NOTIFICATION_SUCCESS',
  SET_TOKEN_FIREBASE: 'SET_TOKEN_FIREBASE',
  READ_ALL_NOTIFICATION: 'READ_ALL_NOTIFICATION',
  READ_ALL_NOTIFICATION_SUCCESS: 'READ_ALL_NOTIFICATION_SUCCESS'
};

export const setTokenFirebase = (params) => ({
  type: actionTypes.SET_TOKEN_FIREBASE,
  params
});

export const getNotificationList = (params, resetNoti = false) => ({
  type: actionTypes.GET_NOTIFICATION,
  params,
  resetNoti
});

export const getNotificationListPending = () => ({
  type: actionTypes.GET_NOTIFICATION_PENDING
});

export const getNotificationListSuccess = (
  data: any,
  params: any,
  hasLoadmore: boolean,
  unread_num: number,
  count: number
) => ({
  type: actionTypes.GET_NOTIFICATION_SUCCESS,
  data,
  params,
  hasLoadmore,
  unread_num,
  count
});

export const makeReadNotification = (id: string) => ({
  type: actionTypes.READ_NOTIFICATION,
  id
});

export const makeReadNotificationSucess = (id: string, unread_num: number) => ({
  type: actionTypes.READ_NOTIFICATION_SUCCESS,
  id,
  unread_num
});

export const makeReadAllNotification = () => ({
  type: actionTypes.READ_ALL_NOTIFICATION
});

export const makeReadAllNotificationSuccess = () => ({
  type: actionTypes.READ_ALL_NOTIFICATION_SUCCESS
});
