import { actionTypes } from './giphyAction';
import { all, takeLatest, put } from 'redux-saga/effects';

function* requestDataGiphyToState(action: any) {
  const { urlGif, id, isComment, urlGifEdit } = action;
  if (isComment) {
    yield put({ type: actionTypes.SAVE_GIFPHY_COMMENT, urlGif, id });
  } else if (urlGifEdit) {
    yield put({ type: actionTypes.SAVE_GIPHY_EDIT, urlGifEdit, id });
  } else {
    yield put({ type: actionTypes.SAVE_GIPHY, urlGif, id });
  }
}
function* watchClickGiphy() {
  yield takeLatest(actionTypes.CLICK_GIPHY, requestDataGiphyToState);
}
export function* giphySaga() {
  yield all([watchClickGiphy()]);
}
