import { all, call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  actionTypes,
  getOtherProfile,
  getOtherTopGivePoint,
  getOtherTopReceivePoint,
  getOtherTotalPointsReceive,
  getOtherTotalPost
} from './OtherProfileAction';
import { ProfileApi } from '@src/api/ProfileApi';
import { setError } from '@src/common/commonAction';

function* requestOtherProfile(action: any) {
  const { id } = action;
  try {
    try {
      const data: AxiosResponse = yield call(ProfileApi.getOtherProfile, id);
      if (data) {
        yield put(getOtherProfile(data));
      }
      if (data.status === 404 || data.status === 500) {
        yield put({
          type: actionTypes.GET_OTHER_PROFILE_FAIL
        });
      }
    } catch (error) {
      yield put(setError());
      yield put({
        type: actionTypes.GET_OTHER_PROFILE_FAIL
      });
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: actionTypes.GET_OTHER_PROFILE_FAIL
    });
  }
}
function* requestOtherPointsReceiveSaga(action: any) {
  const { startDate, endDate, userId } = action;
  try {
    try {
      const data: AxiosResponse = yield call(
        ProfileApi.getOtherPointReceive,
        startDate,
        endDate,
        userId
      );
      if (data) {
        yield put(getOtherTotalPointsReceive(data));
      }
    } catch (error) {
      yield put(setError());
    }
  } catch (error) {
    console.log(error);
  }
}

function* requestOtherTotalPostSaga(action: any) {
  const { startDate, endDate, userId } = action;
  try {
    try {
      const data: AxiosResponse = yield call(
        ProfileApi.getOtherTotalPost,
        startDate,
        endDate,
        userId
      );
      if (data) {
        yield put(getOtherTotalPost(data));
      }
    } catch (error) {
      yield put(setError());
    }
  } catch (error) {
    console.log(error);
  }
}

function* requestOtherTopGivePoint(action: any) {
  const { startDate, endDate, userId } = action;
  try {
    try {
      const data: AxiosResponse = yield call(
        ProfileApi.getOtherTopGivePoint,
        startDate,
        endDate,
        userId
      );
      if (data) {
        yield put(getOtherTopGivePoint(data));
      }
    } catch (error) {
      yield put(setError());
    }
  } catch (error) {
    console.log(error);
  }
}

function* requestOtherTopReceivePoint(action: any) {
  const { startDate, endDate, userId } = action;
  try {
    try {
      const data: AxiosResponse = yield call(
        ProfileApi.getOtherTopReceivePoint,
        startDate,
        endDate,
        userId
      );
      if (data) {
        yield put(getOtherTopReceivePoint(data));
      }
    } catch (error) {
      yield put(setError());
    }
  } catch (error) {
    console.log(error);
  }
}

function* watchRequestOtherTotalPosts() {
  yield takeLatest(actionTypes.REQUEST_OTHER_TOTAL_POSTS, requestOtherTotalPostSaga);
}

function* watchRequestOtherTotalPoints() {
  yield takeLatest(actionTypes.REQUEST_OTHER_TOTAL_POINTS_RECEIVE, requestOtherPointsReceiveSaga);
}

function* watchRequestOtherTopGivePoint() {
  yield takeLatest(actionTypes.REQUEST_OTHER_TOP_GIVE_POINT, requestOtherTopGivePoint);
}

function* watchRequestOtherTopReceivePoint() {
  yield takeLatest(actionTypes.REQUEST_OTHER_TOP_RECEIVE_POINT, requestOtherTopReceivePoint);
}

function* watchRequestOtherProfile() {
  yield takeLatest(actionTypes.REQUEST_OTHER_PROFILE, requestOtherProfile);
}

export function* otherProfileSaga() {
  yield all([
    watchRequestOtherProfile(),
    watchRequestOtherTotalPosts(),
    watchRequestOtherTotalPoints(),
    watchRequestOtherTopGivePoint(),
    watchRequestOtherTopReceivePoint()
  ]);
}
