import { all, call, put, takeLatest } from 'redux-saga/effects';
import { setError } from '@src/common/commonAction';
import { actionTypes, getHistorySuccess } from '@src/components/Heatmap/history/historyAction';
import { chartApi } from '@src/api/chartAPIService';

function* getHistoryHeatmapSaga(data: any) {
  try {
    const dataHeatmap = yield call(chartApi.getDataHeatmap, {
      from_date: data.data.fromDate.toISOString().replace('.000Z', '+07:00'),
      to_date: data.data.toDate.toISOString().replace('.000Z', '+07:00')
    });
    yield put(getHistorySuccess(dataHeatmap));
  } catch (error) {
    yield put(setError(error.data.message));
  }
}

function* getHistoryHeatmapSagaWatcher() {
  yield takeLatest(actionTypes.HISTORY_HEATMAP, getHistoryHeatmapSaga);
}

export default function* historySaga() {
  yield all([getHistoryHeatmapSagaWatcher()]);
}
