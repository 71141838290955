import styled from 'styled-components';

interface Props {
  width?: number;

  onClick?(): void;
}

const WrapBoxSearch = styled.div<Props>`
  height: auto;
  background-color: white;
  padding: 20px 15px;
  width: auto;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  width: ${(props) => (props.width ? props.width + 'px' : 'auto')};

  .title-suggestion {
    margin: 5px;
    font-size: 12px;
    font-weight: 500;
    color: #b8b8b8;
    line-height: 18px;
  }

  .wrap-box-flex {
    display: flex;
    flex-wrap: wrap;
  }

  .wrap-box-inline {
    display: inline;
  }

  .m-b-20 {
    margin-bottom: 20px;
  }
`;

const SuggestionItem = styled.div<Props>`
  height: 30px;
  background-color: #f5f5f5;
  margin: 5px;
  padding: 4px 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 4px;
  transition: 0.5s ease all;

  &:hover {
    transform: background-color;
    background-color: grey;
    border: 1px solid transparent;
  }
`;

const HistoryItem = styled.div<Props>`
  height: 30px;
  margin: 5px;
  padding: 0px 0px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;

  .item-title {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .item-remove {
    color: #0b7bff;
    cursor: pointer;
  }
`;

export default { WrapBoxSearch, SuggestionItem, HistoryItem };
