import { all, takeLatest, put, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { actionTypes } from '@src/components/SlideHashTag/recommentHashtagAction';
import { InteractiveApi } from '@src/api/InteractiveApi';

function* requestListHashTagRecomment() {
  try {
    const data: AxiosResponse = yield call(InteractiveApi.getListHashTagRecomment);
    yield put({
      type: actionTypes.GET_HASHTAG_RECOMMENT_SUCESS,
      payload: data
    });
  } catch (e) {
    console.log(e);
  }
}

function* watchRequestHashTagRecomment() {
  yield takeLatest(actionTypes.GET_HASHTAG_RECOMMENT_REQUEST, requestListHashTagRecomment);
}

export function* hashtagRecommentSaga() {
  yield all([watchRequestHashTagRecomment()]);
}
