import React, { useEffect, useState } from 'react';
import { getSession, signIn, useSession } from 'next-auth/react';
import { CommonState } from '@src/interface/ICommonState';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '@src/interface/IAppState';
import { actionTypes as actionTypesSignIn } from '@src/components/signin/signinAction';
import { setSuccess } from 'src/common/commonAction';
import { actionTypes } from 'src/components/signin/signinAction';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const withAuthenticate = (WrappedComponent: any) => {
  const AuthenticatedRoute: React.FC<any> = (props) => {
    const [loadSession, setLoadSession] = useState(true);
    const [loading, setLoading] = useState(true);
    const commonState: CommonState = useSelector((state: IAppState) => state.commonState);

    const { isLoading } = commonState;
    const { data: session } = useSession();
    const { status } = useSession({
      required: true,
      onUnauthenticated() {
        return;
      }
    });
    const dispatch = useDispatch();

    useEffect(() => {
      if (session || session === null) {
        setLoadSession(false);
      }
      if (session && session.access_token && !session.error) {
        dispatch({ type: actionTypesSignIn.POST_AUTH, values: session });
      } else {
        dispatch({ type: actionTypes.LOGIN_FAIL });
      }
    }, [session]);

    useEffect(() => {
      // authenticated, that session existed
      if (status === 'authenticated') {
        setLoading(false);
      }
    }, [status]);

    const checkSession = async () => {
      const ss = await getSession();
      if (!ss && !!window) {
        const getPathName = window.location.pathname;
        localStorage.setItem('pathName', getPathName);
        const baseURL = window.location.origin;
        await signIn(
          'keycloak',
          {},
          {
            prompt: 'none',
            redirect_uri: encodeURI(
              `${baseURL}/signin?pathname=${window.location.pathname}${window.location.search}`
            )
          }
        );
      }
    };

    useEffect(() => {
      void checkSession();
    }, []);

    if (isLoading || loadSession || loading) {
      return null;
    }

    return <WrappedComponent {...props} />;
  };
  return AuthenticatedRoute;
};
export default withAuthenticate;
